import React from 'react';
import { useLocation } from 'react-router-dom';
import MaintenanceCalculationForm from '../../../../components/forms/MaintenanceCalculationForm/MaintenanceCalculationForm';
import { Container, Row, Col } from 'react-bootstrap';

const EditCalculation = () => {
    const location = useLocation();
    const { vehicleName, mileageData } = location.state || {};

    if (!vehicleName || !mileageData) {
        return <div>No data available to edit. Please go back and select a vehicle.</div>;
    }

    return (
        <Container fluid>
            <div className="edit-maintenance-calculator-page mt-5 mb-5">
                <h3>Edit Calculation for {vehicleName.charAt(0).toUpperCase() + vehicleName.slice(1)}</h3>
            </div>

            <Row>
                <Col md={4}>
                <h5>Edit Maintenance Calculator Details</h5>
                <p>Please edit maintenance cal pricing based on vehicles here.</p>
                </Col>
                <Col md={8}>
                    <MaintenanceCalculationForm
                        initialData={{ vehicleName, mileageData }} // Pass the pre-filled data
                        isEdit={true}
                    />
                </Col>
            </Row>


        </Container>

    );
};

export default EditCalculation;

