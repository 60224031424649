import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Form, Button } from 'react-bootstrap';
import './CityForm.css';

// Validation schema using Yup
const validationSchema = Yup.object({
    cityNameEn: Yup.string()
        .matches(/^[A-Za-z\s]{3,}$/, 'City Name (En) must contain only English letters and spaces, with at least 3 characters')
        .required('City Name (En) is required'),
    cityNameAr: Yup.string()
        .matches(/^[\u0600-\u06FF\s]{3,}$/, 'City Name (Ar) must contain only Arabic letters and spaces, with at least 3 characters')
        .required('City Name (Ar) is required'),
});

const CityForm = ({ initialData = {}, isEdit = false }) => {
    const formik = useFormik({
        initialValues: {
            cityNameEn: initialData.cityNameEn || '',
            cityNameAr: initialData.cityNameAr || '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            resetForm();
            setTimeout(() => {
                toast.success(isEdit ? 'City updated successfully!' : 'City added successfully!', {
                    position: "top-right",
                });
            }, 100);  // Adding a slight delay to allow form reset first
        },
    });

    useEffect(() => {
        if (isEdit) {
            formik.setValues(initialData); // Pre-fill form for editing if in edit mode
        }
    }, [initialData, isEdit]);

    return (
        <div className="city-form">
            <ToastContainer />
            <Form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col md={6}>
                        {/* City Name (En) */}
                        <Form.Group className="mb-3" controlId="cityNameEn">
                            <Form.Label>City Name (En)</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter city name in English"
                                {...formik.getFieldProps('cityNameEn')}
                                isInvalid={formik.touched.cityNameEn && formik.errors.cityNameEn}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.cityNameEn}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        {/* City Name (Ar) */}
                        <Form.Group className="mb-3" controlId="cityNameAr">
                            <Form.Label>City Name (Ar)</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter city name in Arabic"
                                {...formik.getFieldProps('cityNameAr')}
                                isInvalid={formik.touched.cityNameAr && formik.errors.cityNameAr}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.cityNameAr}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={12} className="text-end">
                        {/* Submit Button */}
                        <Button type="submit" variant="primary" disabled={formik.isSubmitting}>
                            {isEdit ? 'Update City' : 'Add City'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default CityForm;
