import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';

const VehicleManagementTable = () => {
    // Dummy data for now, later it will be fetched from an API
    const vehicles = [
        {
            id: 1,
            name: 'Dzire',
            variants: 'GL, GLX'
        },
        {
            id: 2,
            name: 'Baleno',
            variants: 'GL, GL Plus,  GLX'
        },
        {
            id: 3,
            name: 'Fronx',
            variants: 'GL, GL Plus,  GLX'
        }
    ];

    return (
        <Table responsive striped bordered hover className="text-center">
            <thead>
                <tr>
                    <th>Sr. No.</th>
                    <th>Vehicle Name</th>
                    <th>Vehicle Variants</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {vehicles.map((vehicle, index) => (
                    <tr key={vehicle.id}>
                        <td>{index + 1}</td>
                        <td>{vehicle.name}</td>
                        <td>{vehicle.variants}</td>
                        <td>
                        <Link to={`/vehicle-management/edit/${vehicle.id}`}>
                        <Button variant="warning" size="sm">Edit</Button>
                        </Link>
                            <Button variant="danger ms-2" size="sm">Delete</Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default VehicleManagementTable;
