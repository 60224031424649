import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const dummyData = {
    dzire: {
        "10,000km": "497.95",
        "20,000km": "497.95",
        "30,000km": "654.35",
        "40,000km": "1,334.00",
        "50,000km": "963.70",
        "60,000km": "654.35",
        "70,000km": "497.95",
        "80,000km": "1,685.90",
        "90,000km": "1,120.10",
        "100,000km": "497.95",
    },
    baleno: {
        "10,000km": "497.95",
        "20,000km": "497.95",
        "30,000km": "654.35",
        "40,000km": "1,361.00",
        "50,000km": "497.95",
        "60,000km": "654.35",
        "70,000km": "497.95",
        "80,000km": "1,652.55",
        "90,000km": "1,169.55",
        "100,000km": "497.95",
    }
};

const MaintenanceCalculatorTable = () => {
    const navigate = useNavigate(); // Hook for navigation

    const handleEdit = (vehicleName, mileageData) => {
        navigate('/cms/maintenance-calculator/edit', { state: { vehicleName, mileageData } });
    };

    return (
        <Table responsive striped bordered hover className='text-center'>
            <thead>
                <tr>
                    <th>Sr. No.</th>
                    <th>Vehicle Name</th>
                    <th>Mileage</th>
                    <th>Price</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(dummyData).map((vehicleName, index) => {
                    const mileageData = dummyData[vehicleName];
                    const rowSpanCount = Object.keys(mileageData).length;

                    return (
                        Object.entries(mileageData).map(([mileage, price], mileageIndex) => (
                            <tr key={`${vehicleName}-${mileage}`}>
                                {mileageIndex === 0 && (
                                    <>
                                        <td rowSpan={rowSpanCount}>{index + 1}</td>
                                        <td rowSpan={rowSpanCount}>{vehicleName.charAt(0).toUpperCase() + vehicleName.slice(1)}</td>
                                    </>
                                )}
                                <td>{mileage}</td>
                                <td>{price}</td>
                                {mileageIndex === 0 && (
                                    <td rowSpan={rowSpanCount}>
                                        <Button variant="warning" onClick={() => handleEdit(vehicleName, mileageData)} className="me-2" size="sm">
                                            Edit
                                        </Button>
                                        <Button variant="danger" size="sm">Delete</Button>
                                    </td>
                                )}
                            </tr>
                        ))
                    );
                })}
            </tbody>
        </Table>
    );
};

export default MaintenanceCalculatorTable;

