import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const LocationManagementTable = () => {
  const navigate = useNavigate(); // Use useNavigate from react-router-dom

  const locations = [
    {
      srNo: 1,
      category: 'Showroom',
      city: 'Riyadh',
      locations: [
        {
          locationNameEn: 'Watani Express',
          locationNameAr: 'وطن اكسبريس',
          address: '123 Riyadh Street',
          telephones: ['0112345678', '0119876543'], // Multiple telephone numbers
          workingHours: {
            sunToThu: '8:00 AM - 9:00 PM',
            saturday: '10:00 AM - 5:00 PM',
            friday: 'Closed',
          },
        },
        {
          locationNameEn: 'Riyadh Showroom 2',
          locationNameAr: 'معرض الرياض 2',
          address: '456 Riyadh Street',
          telephones: ['0118765432'], // Single telephone number
          workingHours: {
            sunToThu: '8:00 AM - 9:00 PM',
            saturday: '10:00 AM - 5:00 PM',
            friday: 'Closed',
          },
        },
      ],
    },
    {
      srNo: 2,
      category: 'Service Center',
      city: 'Jeddah',
      locations: [
        {
          locationNameEn: 'Al Kobar Service Center',
          locationNameAr: 'مركز خدمة الخبر',
          address: '789 Jeddah Street',
          telephones: ['0123456789', '0129876543'], // Multiple telephone numbers
          workingHours: {
            satToThu: '8:00 AM - 8:00 PM',
            breakTime: '1:00 PM - 2:00 PM',
            friday: 'Closed',
          },
        },
      ],
    },
  ];

  // Function to handle Edit button click
  const handleEdit = (id) => {
    navigate(`/cms/location-management/edit/${id}`); // Redirect to EditLocation page with ID
  };

  return (
    <Table bordered striped responsive>
      <thead>
        <tr>
          <th>Sr. No</th>
          <th>Category</th>
          <th>City Name (En)</th>
          <th>City Name (Ar)</th>
          <th>Location Name (En)</th>
          <th>Location Name (Ar)</th>
          <th>Address</th>
          <th>Telephone</th>
          <th>Working Hours</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {locations.map((location, locationIndex) => (
          <React.Fragment key={location.srNo}>
            <tr>
              <td rowSpan={location.locations.length}>{location.srNo}</td>
              <td rowSpan={location.locations.length}>{location.category}</td>
              <td rowSpan={location.locations.length}>{location.city}</td>
              <td rowSpan={location.locations.length}>{location.city === 'Riyadh' ? 'الرياض' : 'جدة'}</td>
              <td>{location.locations[0].locationNameEn}</td>
              <td>{location.locations[0].locationNameAr}</td>
              <td>{location.locations[0].address}</td>
              <td>
                {location.locations[0].telephones.map((tel, index) => (
                  <div key={index}>{tel}</div>
                ))}
              </td>
              <td>
                {location.category === 'Showroom' ? (
                  <>
                    Sun-Thu: {location.locations[0].workingHours.sunToThu} <br />
                    Sat: {location.locations[0].workingHours.saturday} <br />
                    Fri: {location.locations[0].workingHours.friday}
                  </>
                ) : (
                  <>
                    Sat-Thu: {location.locations[0].workingHours.satToThu} <br />
                    Break: {location.locations[0].workingHours.breakTime} <br />
                    Fri: {location.locations[0].workingHours.friday}
                  </>
                )}
              </td>
              <td>
                <Button variant="warning" size="sm" onClick={() => handleEdit(location.srNo)}>Edit</Button> {/* Pass the ID to handleEdit */}
                <Button variant="danger" size="sm">Delete</Button>
              </td>
            </tr>
            {location.locations.slice(1).map((loc, index) => (
              <tr key={index}>
                <td>{loc.locationNameEn}</td>
                <td>{loc.locationNameAr}</td>
                <td>{loc.address}</td>
                <td>
                  {loc.telephones.map((tel, idx) => (
                    <div key={idx}>{tel}</div>
                  ))}
                </td>
                <td>
                  {location.category === 'Showroom' ? (
                    <>
                      Sun-Thu: {loc.workingHours.sunToThu} <br />
                      Sat: {loc.workingHours.saturday} <br />
                      Fri: {loc.workingHours.friday}
                    </>
                  ) : (
                    <>
                      Sat-Thu: {loc.workingHours.satToThu} <br />
                      Break: {loc.workingHours.breakTime} <br />
                      Fri: {loc.workingHours.friday}
                    </>
                  )}
                </td>
                <td>
                  <Button variant="warning" size="sm" onClick={() => handleEdit(location.srNo)}>Edit</Button> {/* Pass the ID to handleEdit */}
                  <Button variant="danger" size="sm">Delete</Button>
                </td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
};

export default LocationManagementTable;
