import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import VehicleColorForm from '../../../../components/forms/VehicleColorForm/VehicleColorForm';
import { Container, Col, Row } from 'react-bootstrap';


const EditColor = () => {
    const { id } = useParams(); // Get the ID from the URL
    const [colorData, setColorData] = useState(null);

    // Simulate fetching color data by ID
    useEffect(() => {
        // Dummy data to simulate fetching from an API
        const colors = [
            { id: 1, colorNameEn: 'Red', colorNameAr: 'أحمر', colorType: 'Single Tone', colorHexCode: '#FF0000' },
            { id: 2, colorNameEn: 'Blue', colorNameAr: 'أزرق', colorType: 'Dual Tone', primaryColorHexCode: '#0000FF', secondaryColorHexCode: '#00FFFF' },
            { id: 3, colorNameEn: 'Green', colorNameAr: 'أخضر', colorType: 'Single Tone', colorHexCode: '#008000' }
        ];

        // Simulate fetching color data by ID
        const colorToEdit = colors.find((color) => color.id === parseInt(id));
        if (colorToEdit) {
            setColorData(colorToEdit);
        }
    }, [id]);

    if (!colorData) {
        return <div>Loading...</div>; // Show a loading state while data is being fetched
    }

    return (
        <Container fluid>
            <div className="edit-color-page mt-5 mb-5">
                <h3>Edit Vehicle Color</h3>
            </div>
            <Row>
                <Col md={4}>
                    <h5>Edit Color Details</h5>
                    <p>Please update the vehicle color details here.</p>
                </Col>
                <Col md={8}>
                    <VehicleColorForm initialValues={colorData} isEditMode />
                </Col>
            </Row>

        </Container>


    );
};

export default EditColor;
