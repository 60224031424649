import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import axios from 'axios';

const ApprovalTrackingChart = () => {
    const [approvalStatusData, setApprovalStatusData] = useState([]);

    useEffect(() => {
        const fetchApprovalData = async () => {
            try {
                const response = await axios.get('https://beta.api.nhcholding.com/api/approval-tracking', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });

                if (response.data && response.data.status) {
                    const trackingData = response.data.data;

                    // Calculate counts
                    const approved = trackingData.filter((item) => item.current_status === 'approved').length;
                    const pending = trackingData.filter((item) => item.current_status === 'pending').length;
                    const rejected = trackingData.filter((item) => item.current_status === 'rejected').length;
                    const managerApproved = trackingData.filter((item) => item.current_status === 'manager_approved').length;
                    const needsRevision = trackingData.filter((item) => item.current_status === 'rejected_for_edit').length;

                    // Prepare data for the Approval Status Pie Chart
                    setApprovalStatusData([
                        { name: 'Approved', value: approved, color: '#28a745' },
                        { name: 'Pending', value: pending, color: '#ffc107' },
                        { name: 'Rejected', value: rejected, color: '#dc3545' },
                        { name: 'Manager Approved', value: managerApproved, color: '#007bff' },
                        { name: 'Needs Revision', value: needsRevision, color: '#17a2b8' },
                    ]);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching approval tracking data:', error);
            }
        };

        fetchApprovalData();
    }, []);

    return (
        <div>
            <h5>Approval Status Breakdown</h5>
            <PieChart width={400} height={400}>
                <Pie
                    data={approvalStatusData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={120}
                    label
                >
                    {approvalStatusData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
        </div>
    );
};

export default ApprovalTrackingChart;
