import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BannerForm from '../../../../components/forms/BannerForm/BannerForm';

const AddNewBanner = () => {
    const handleFormSubmit = (values) => {
        console.log('Banner form submitted with values:', values);
    };

    return (
        <Container fluid>
            <div className='mt-5 mb-5'>
                <h3>Add New Banner</h3>
            </div>
            <Row>
                <Col md={4}>
                    <h5>Banner Details</h5>
                    <p>Please add complete Banner details here</p>
                </Col>
                <Col md={8}>
                    <BannerForm onSubmit={handleFormSubmit} />
                </Col>
            </Row>

        </Container>

    );
};

export default AddNewBanner;
