import React from 'react';
import { useParams } from 'react-router-dom';
import UserRolesForm from '../../../../components/forms/UserRolesForm/UserRolesForm';
import { Container, Row, Col } from 'react-bootstrap';

const EditUserRole = () => {
    const { id } = useParams(); // Get user ID from the URL

    return (
        <Container fluid>
            <div className='edit-user-role-page mt-5 mb-5'>
                <h3>Edit User</h3>
            </div>

            <Row>
                <Col md={4}>
                    <h5>Edit User Details</h5>
                    <p>Edit user details here.</p>
                </Col>

                <Col md={8}>
                    <UserRolesForm isEditMode={true} userId={id} />
                </Col>
            </Row>
        </Container>
    );
};

export default EditUserRole;
