// import React, { useState } from 'react';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import BrandLogo from '../../../assets/images/logo.png';
// import './LoginForm.css';

// const LoginForm = ({ onLogin }) => {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [loading, setLoading] = useState(false);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setLoading(true);
    
//         try {
//             const response = await axios.post(
//                 'https://beta.api.nhcholding.com/api/login',
//                 { email, password },
//                 { headers: { 'Content-Type': 'application/json' } }
//             );
    
//             if (response.data.token && response.status === 200) {
//                 toast.success('Login Successful!');
    
//                 // Save the token to localStorage
//                 localStorage.setItem('token', response.data.token);
    
//                 // Extract and save user roles
//                 const userRoles = response.data.user.roles.map(role => role.name); // Get role names
//                 localStorage.setItem('userRoles', JSON.stringify(userRoles)); // Save roles as JSON string
    
//                 // Log roles to verify
//                 console.log('User Roles:', userRoles);  
    
//                 // Pass the token to parent component
//                 onLogin(response.data.token);
//             } else {
//                 toast.error('Unexpected error. Please try again.');
//             }
//         } catch (error) {
//             if (error.response && error.response.data.errors) {
//                 const errorMessage = error.response.data.errors.email?.[0] || 'Invalid Email or Password';
//                 toast.error(errorMessage);
//             } else {
//                 toast.error('Login failed. Please try again.');
//             }
//         } finally {
//             setLoading(false);
//         }
//     };
    

//     return (
//         <div className="login-form-container d-flex flex-column align-items-center justify-content-center vh-100 p-3">
//             <ToastContainer />
//             <div className="login-logo mb-4">
//                 <img src={BrandLogo} alt="Brand Logo" />
//             </div>
//             <form onSubmit={handleSubmit} className="login-form w-100" style={{ maxWidth: '400px' }}>
//                 <div className="form-group mb-3">
//                     <label htmlFor="email" className="form-label">Email Address</label>
//                     <input
//                         type="email"
//                         id="email"
//                         className="form-control"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         required
//                         disabled={loading}
//                     />
//                 </div>
//                 <div className="form-group mb-3">
//                     <label htmlFor="password" className="form-label">Password</label>
//                     <input
//                         type="password"
//                         id="password"
//                         className="form-control"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         required
//                         disabled={loading}
//                     />
//                 </div>
//                 <button type="submit" className="btn btn-primary w-100" disabled={loading}>
//                     {loading ? 'Signing In...' : 'Sign In'}
//                 </button>
//             </form>
//             <div className="forgot-password mt-3">
//                 <a href="/forgot-password" className="text-decoration-none">Forgot Password?</a>
//             </div>
//         </div>
//     );
// };

// export default LoginForm;


import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BrandLogo from '../../../assets/images/logo.png';
import './LoginForm.css';

const LoginForm = ({ onLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(
                'https://beta.api.nhcholding.com/api/login',
                { email, password },
                { headers: { 'Content-Type': 'application/json' } }
            );

            if (response.data.token && response.status === 200) {
                toast.success('Login Successful!');

                // Save the token to localStorage
                localStorage.setItem('token', response.data.token);

                // Extract and save user roles
                const userRoles = response.data.user.roles.map((role) => role.name); // Get role names
                localStorage.setItem('userRoles', JSON.stringify(userRoles)); // Save roles as JSON string

                // Extract and save user name
                const userName = response.data.user.name; // Assuming user name is in `response.data.user.name`
                localStorage.setItem('userName', userName); // Save name

                // Log to verify
                console.log('User Roles:', userRoles);
                console.log('User Name:', userName);

                // Pass the token to parent component
                onLogin(response.data.token);
            } else {
                toast.error('Unexpected error. Please try again.');
            }
        } catch (error) {
            if (error.response && error.response.data.errors) {
                const errorMessage = error.response.data.errors.email?.[0] || 'Invalid Email or Password';
                toast.error(errorMessage);
            } else {
                toast.error('Login failed. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-form-container d-flex flex-column align-items-center justify-content-center vh-100 p-3">
            <ToastContainer />
            <div className="login-logo mb-4">
                <img src={BrandLogo} alt="Brand Logo" />
            </div>
            <form onSubmit={handleSubmit} className="login-form w-100" style={{ maxWidth: '400px' }}>
                <div className="form-group mb-3">
                    <label htmlFor="email" className="form-label">Email Address</label>
                    <input
                        type="email"
                        id="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        disabled={loading}
                    />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input
                        type="password"
                        id="password"
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        disabled={loading}
                    />
                </div>
                <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                    {loading ? 'Signing In...' : 'Sign In'}
                </button>
            </form>
            <div className="forgot-password mt-3">
                <a href="/forgot-password" className="text-decoration-none">Forgot Password?</a>
            </div>
        </div>
    );
};

export default LoginForm;
