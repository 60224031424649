import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Form as BootstrapForm, Button } from 'react-bootstrap';
import './VehicleColorForm.css';

// Validation schema
const validationSchema = Yup.object({
    colorType: Yup.string().required('Color Type is required'),
    colorNameEn: Yup.string()
        .required('Color Name (En) is required')
        .matches(/^[A-Za-z\s]{3,}$/, 'Color Name (En) must contain only English letters and be at least 3 characters'),
    colorNameAr: Yup.string()
        .required('Color Name (Ar) is required')
        .matches(/^[\u0600-\u06FF\s]{3,}$/, 'Color Name (Ar) must contain only Arabic letters and be at least 3 characters'),
    colorHexCode: Yup.string()
        .test('colorHexCode', 'Color Hex Code is required for Single Tone', function (value) {
            return this.parent.colorType !== 'Single Tone' || !!value;
        })
        .matches(/^#([A-Fa-f0-9]{6})$/, 'Color Hex Code must be a valid hex code (e.g., #FFFFFF)'),
    primaryColorHexCode: Yup.string()
        .test('primaryColorHexCode', 'Primary Color Hex Code is required for Dual Tone', function (value) {
            return this.parent.colorType !== 'Dual Tone' || !!value;
        })
        .matches(/^#([A-Fa-f0-9]{6})$/, 'Primary Color Hex Code must be a valid hex code (e.g., #FFFFFF)'),
    secondaryColorHexCode: Yup.string()
        .test('secondaryColorHexCode', 'Secondary Color Hex Code is required for Dual Tone', function (value) {
            return this.parent.colorType !== 'Dual Tone' || !!value;
        })
        .matches(/^#([A-Fa-f0-9]{6})$/, 'Secondary Color Hex Code must be a valid hex code (e.g., #FFFFFF)'),
});

const VehicleColorForm = ({ initialValues, isEditMode }) => {
    const handleSubmit = (values, { resetForm }) => {
        toast.success(isEditMode ? 'Color updated successfully!' : 'Color added successfully!', {
            position: 'top-right'
        });
        resetForm();
    };

    return (
        <div className="vehicle-color-form">
            <ToastContainer />
            <Formik
                initialValues={initialValues || {
                    colorType: '',
                    colorNameEn: '',
                    colorNameAr: '',
                    colorHexCode: '',
                    primaryColorHexCode: '',
                    secondaryColorHexCode: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <Row>
                            <Col md={12}>
                                {/* Color Type Selection */}
                                <BootstrapForm.Group>
                                    <BootstrapForm.Label>Color Type</BootstrapForm.Label>
                                    <Field
                                        as="select"
                                        name="colorType"
                                        className="form-control"
                                        onChange={(e) => setFieldValue('colorType', e.target.value)}
                                    >
                                        <option value="">Select Color Type</option>
                                        <option value="Single Tone">Single Tone</option>
                                        <option value="Dual Tone">Dual Tone</option>
                                    </Field>
                                    <ErrorMessage name="colorType" component="div" className="text-danger" />
                                </BootstrapForm.Group>
                            </Col>

                            {/* Single Tone Fields */}
                            {values.colorType === 'Single Tone' && (
                                <>
                                    <Col md={6}>
                                        <BootstrapForm.Group>
                                            <BootstrapForm.Label>Color Name (En)</BootstrapForm.Label>
                                            <Field
                                                type="text"
                                                name="colorNameEn"
                                                className="form-control"
                                            />
                                            <ErrorMessage name="colorNameEn" component="div" className="text-danger" />
                                        </BootstrapForm.Group>
                                    </Col>

                                    <Col md={6}>
                                        <BootstrapForm.Group>
                                            <BootstrapForm.Label>Color Name (Ar)</BootstrapForm.Label>
                                            <Field
                                                type="text"
                                                name="colorNameAr"
                                                className="form-control"
                                            />
                                            <ErrorMessage name="colorNameAr" component="div" className="text-danger" />
                                        </BootstrapForm.Group>
                                    </Col>

                                    <Col md={12}>
                                        <BootstrapForm.Group>
                                            <BootstrapForm.Label>Color Hex Code</BootstrapForm.Label>
                                            <Field
                                                type="text"
                                                name="colorHexCode"
                                                className="form-control"
                                            />
                                            <ErrorMessage name="colorHexCode" component="div" className="text-danger" />
                                        </BootstrapForm.Group>
                                    </Col>
                                </>
                            )}

                            {/* Dual Tone Fields */}
                            {values.colorType === 'Dual Tone' && (
                                <>
                                    <Col md={6}>
                                        <BootstrapForm.Group>
                                            <BootstrapForm.Label>Color Name (En)</BootstrapForm.Label>
                                            <Field
                                                type="text"
                                                name="colorNameEn"
                                                className="form-control"
                                            />
                                            <ErrorMessage name="colorNameEn" component="div" className="text-danger" />
                                        </BootstrapForm.Group>
                                    </Col>

                                    <Col md={6}>
                                        <BootstrapForm.Group>
                                            <BootstrapForm.Label>Color Name (Ar)</BootstrapForm.Label>
                                            <Field
                                                type="text"
                                                name="colorNameAr"
                                                className="form-control"
                                            />
                                            <ErrorMessage name="colorNameAr" component="div" className="text-danger" />
                                        </BootstrapForm.Group>
                                    </Col>

                                    <Col md={6}>
                                        <BootstrapForm.Group>
                                            <BootstrapForm.Label>Primary Color Hex Code</BootstrapForm.Label>
                                            <Field
                                                type="text"
                                                name="primaryColorHexCode"
                                                className="form-control"
                                            />
                                            <ErrorMessage name="primaryColorHexCode" component="div" className="text-danger" />
                                        </BootstrapForm.Group>
                                    </Col>

                                    <Col md={6}>
                                        <BootstrapForm.Group>
                                            <BootstrapForm.Label>Secondary Color Hex Code</BootstrapForm.Label>
                                            <Field
                                                type="text"
                                                name="secondaryColorHexCode"
                                                className="form-control"
                                            />
                                            <ErrorMessage name="secondaryColorHexCode" component="div" className="text-danger" />
                                        </BootstrapForm.Group>
                                    </Col>
                                </>
                            )}

                            <Col md={12} className="text-end">
                                <Button type="submit" variant="primary" className="mt-2">
                                    {isEditMode ? 'Save Changes' : 'Submit'}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default VehicleColorForm;

