import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LocationForm from '../../../../components/forms/LocationForm/LocationForm';
import { Col, Container, Row } from 'react-bootstrap';

const EditLocation = () => {
    const { id } = useParams(); // Fetch the ID or key to identify the location
    const navigate = useNavigate();

    // Dummy data for now (this will later be fetched from API)
    const locationsData = {
        1: {
            city: 'Riyadh',
            locationNameEn: 'Watani Express',
            locationNameAr: 'وطن اكسبريس',
            telephoneNumbers: ['0112345678', '0119876543'],
            workingHours: {
                sundayToThursday: [{ from: '08:00', to: '18:00' }],
                saturday: [{ from: '10:00', to: '14:00' }],
                friday: [{ from: '', to: '', closed: true }],
            },
            address: '123 Riyadh Street',
            googleURL: 'https://maps.google.com/example',
            mapIframe: '<iframe src="https://maps.google.com/example"></iframe>',
            category: 'Showroom',
        },
        2: {
            city: 'Jeddah',
            locationNameEn: 'Al Kobar Service Center',
            locationNameAr: 'مركز خدمة الخبر',
            telephoneNumbers: ['0123456789', '0129876543'],
            workingHours: {
                saturdayToThursday: [{ from: '09:00', to: '18:00' }],
                break: [{ from: '13:00', to: '14:00' }],
                friday: [{ from: '', to: '', closed: true }],
            },
            address: '789 Jeddah Street',
            googleURL: 'https://maps.google.com/example2',
            mapIframe: '<iframe src="https://maps.google.com/example2"></iframe>',
            category: 'Service Center',
        },
    };

    // Fetch the selected location's data based on the ID
    const selectedLocation = locationsData[id];

    // Function to handle form submission (Save Changes)
    const handleSaveChanges = (updatedValues) => {
        console.log('Updated Values:', updatedValues);
        // This is where you would handle saving the updated data, i.e., through an API call.
        alert('Location details updated successfully');
        navigate('/location-management'); // Redirect back to the location management list after saving
    };

    return (
        <Container fluid>


             <div className="edit-location-page mt-5 mb-5">
            <h3>Edit Location</h3>
        </div>

          
                <Row>
                    <Col md={4}>
                    <h5>Edit the details of Location</h5>
                    <p>Please edit all the required location details here.</p>
                    </Col>

                    <Col md={8}>
                    {selectedLocation ? (
                    <LocationForm
                        category={selectedLocation.category}
                        handleCategoryChange={() => { }} // Dummy for now, could update the category selection
                        isEditMode={true}
                        initialValues={selectedLocation} // Pass the selected location's data as initial values
                        onSubmit={handleSaveChanges} // Save changes on form submit
                    />
                ) : (
                    <p>Location not found</p>
                )}
                    </Col>
                </Row>
        </Container>

    );
};

export default EditLocation;
