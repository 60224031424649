import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ApprovalHierarchyTable from '../../../components/tables/ApprovalHierarchyTable/ApprovalHierarchyTable';
import { useNavigate } from 'react-router-dom';

const UserApprovals = () => {
    const navigate = useNavigate();

    const handleAddHierarchy = () => {
        navigate('/user-approvals/set-hierarchy');
    };

    return (
        <Container fluid>
            <div className='user-approval-page mt-5 mb-5'>
                <div className="d-flex justify-content-between align-items-center mt-5 mb-5">
                    <h3>User Approvals</h3>
                    <Button variant="primary" onClick={handleAddHierarchy}>Set Approval Hierarchy</Button>
                </div>
                <ApprovalHierarchyTable />
            </div>

        </Container>
    );
};

export default UserApprovals;
