import React, { useEffect, useState } from 'react';
import { Formik, FieldArray, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SetApprovalHierarchyForm = ({ isEditMode, hierarchyId }) => {
    const [initialValues, setInitialValues] = useState({
        module: '',
        approvers: [{ name: '', role: '', level: '' }],
    });

    // Fetch data if it's edit mode
    useEffect(() => {
        if (isEditMode) {
            const fetchedData = {
                module: 'Offer Management', // Dummy data
                approvers: [
                    { name: 'John Doe', role: 'Manager', level: 1 },
                    { name: 'Jane Smith', role: 'Executive', level: 2 },
                ],
            };
            setInitialValues(fetchedData);
        }
    }, [isEditMode, hierarchyId]);

    // Validation schema
    const validationSchema = Yup.object().shape({
        module: Yup.string().required('Module is required'),
        approvers: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('Name is required'),
                role: Yup.string().required('Role is required'),
                level: Yup.number().required('Level is required').min(1, 'Level must be at least 1'),
            })
        ),
    });

    // Form submission handler
    const handleSubmit = (values) => {
        if (isEditMode) {
            // Show success message for edit
            toast.success('Hierarchy updated successfully!', {
                position: 'top-right',
                autoClose: 3000,
            });
        } else {
            // Show success message for add
            toast.success('Hierarchy added successfully!', {
                position: 'top-right',
                autoClose: 3000,
            });
        }
    };

    return (
        <>
            <ToastContainer />
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, errors, touched }) => (
                    <FormikForm>
                        <Form.Group md={6} as={Col}>
                            <Form.Label>Module</Form.Label>
                            <Form.Control
                                as="select"
                                name="module"
                                value={values.module}
                                onChange={handleChange}
                                isInvalid={touched.module && !!errors.module}
                            >
                                <option value="">Select Module</option>
                                <option value="Offer Management">Offer Management</option>
                                <option value="CMS Management">CMS Management</option>
                                <option value="Spare Parts Management">Spare Parts Management</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{errors.module}</Form.Control.Feedback>
                        </Form.Group>

                        <FieldArray
                            name="approvers"
                            render={(arrayHelpers) => (
                                <>
                                    {values.approvers.map((approver, index) => (
                                        <Row key={index} className="align-items-center">
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name={`approvers.${index}.name`}
                                                        value={approver.name}
                                                        onChange={handleChange}
                                                        isInvalid={touched.approvers?.[index]?.name && !!errors.approvers?.[index]?.name}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{errors.approvers?.[index]?.name}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <Form.Label>Role</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name={`approvers.${index}.role`}
                                                        value={approver.role}
                                                        onChange={handleChange}
                                                        isInvalid={touched.approvers?.[index]?.role && !!errors.approvers?.[index]?.role}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{errors.approvers?.[index]?.role}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group>
                                                    <Form.Label>Level</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={`approvers.${index}.level`}
                                                        value={approver.level}
                                                        onChange={handleChange}
                                                        isInvalid={touched.approvers?.[index]?.level && !!errors.approvers?.[index]?.level}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{errors.approvers?.[index]?.level}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md={1}>
                                                <Button
                                                    variant="danger"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    Remove
                                                </Button>
                                            </Col>
                                        </Row>
                                    ))}

                                    <Button
                                        variant="secondary"
                                        onClick={() => arrayHelpers.push({ name: '', role: '', level: '' })}
                                    >
                                        Add Approver
                                    </Button>
                                </>
                            )}
                        />

                        <div className='text-end'>
                            <Button type="submit" variant="primary" className="mt-3">
                                {isEditMode ? 'Update Changes' : 'Submit'}
                            </Button>
                        </div>

                    </FormikForm>
                )}
            </Formik>
        </>
    );
};

export default SetApprovalHierarchyForm;
