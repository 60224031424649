import React from 'react';
import CityForm from '../../../../components/forms/CityForm/CityForm';
import { Container, Row, Col } from 'react-bootstrap';

function AddNewCity() {
    return (
        <Container fluid>
        <div className="add-new-color-page mt-5 mb-5">
                        <h3>Add New City</h3>
                    </div>
            <Row>
                <Col md={4}>
                <h5>City Details Here</h5>
                <p>Fill the City details here</p>
                </Col>
                <Col md={8}>
                    <CityForm />
                </Col>
            </Row>
        </Container>
    );
}

export default AddNewCity;
