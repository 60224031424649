// import React, { useEffect, useState } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import { Container, Row, Col, Button, Dropdown, DropdownButton } from 'react-bootstrap';
// import axios from 'axios';
// import './OfferPreview.css';

// const OfferPreview = () => {
//     const { id } = useParams();
//     const navigate = useNavigate();
//     const [offer, setOffer] = useState(null);
//     const [language, setLanguage] = useState('en'); // Default to English
//     const [timeLeft, setTimeLeft] = useState(null);

//     useEffect(() => {
//         const fetchOffer = async () => {
//             try {
//                 const response = await axios.get(`https://beta.api.nhcholding.com/api/offers/${id}`, {
//                     headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
//                 });
//                 setOffer(response.data);
//             } catch (error) {
//                 console.error('Error fetching offer:', error);
//             }
//         };

//         fetchOffer();
//     }, [id]);

//     useEffect(() => {
//         if (offer?.offer_end_date) {
//             const calculateTimeLeft = () => {
//                 const endDate = new Date(offer.offer_end_date);
//                 const now = new Date();
//                 const difference = endDate - now;

//                 if (difference > 0) {
//                     const days = Math.floor(difference / (1000 * 60 * 60 * 24));
//                     const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
//                     const minutes = Math.floor((difference / 1000 / 60) % 60);
//                     const seconds = Math.floor((difference / 1000) % 60);
//                     setTimeLeft(`${days} Days ${hours} Hours, ${minutes} Minutes, ${seconds} Seconds`);
//                 } else {
//                     setTimeLeft('Offer expired');
//                 }
//             };

//             const timer = setInterval(calculateTimeLeft, 1000);

//             return () => clearInterval(timer);
//         }
//     }, [offer]);

//     const handleLanguageChange = (lang) => {
//         setLanguage(lang);
//     };

//     if (!offer) return <div>Loading offer details...</div>;

//     return (
//         <>
//             <Container fluid>
//                 <Row className="align-items-center mt-3  mb-3">
//                     <Col>
//                         <DropdownButton
//                             title={`Check Offer In: ${language === 'en' ? 'English' : 'Arabic'}`}
//                             onSelect={handleLanguageChange}
//                         >
//                             <Dropdown.Item eventKey="en">English</Dropdown.Item>
//                             <Dropdown.Item eventKey="ar">Arabic</Dropdown.Item>
//                         </DropdownButton>
//                     </Col>
//                     <Col className="text-end">
//                         <Button variant="secondary" onClick={() => navigate(-1)}>
//                             Back
//                         </Button>
//                     </Col>
//                 </Row>
//             </Container>
//             <div className='offer-preview-banner'>
//                 <img
//                     src={`https://beta.api.nhcholding.com/storage/${offer.offer_banner}`}
//                     alt={offer[`offer_name_${language}`]}
//                 />
//             </div>
//             <div className='offer-preview-bg'>
//                 <Container className={`offer-preview-spacing offer-preview-page ${language === 'ar' ? 'rtl' : ''}`}>
//                     <Row className='align-items-center'>
//                         <Col lg={3}>
//                             <div className='offer-preview-content'>
//                                 <h2>{offer[`offer_name_${language}`]}</h2>
//                                 <p>{offer[`offer_description_${language}`]}</p>
//                                 <h3>
//                                     Offer valid till{' '}
//                                     {new Date(offer.offer_end_date).toLocaleDateString(language === 'ar' ? 'ar-SA' : 'en-US', {
//                                         day: 'numeric',
//                                         month: 'long'
//                                     })}
//                                 </h3>
//                                 <p className="countdown-timer">{timeLeft}</p>
//                                 <p>*Terms & conditions apply.</p>
//                             </div>
//                         </Col>

//                         <Col lg={9}>
//                             <div className='preview-offer-form-wrap'>
//                                 <h2>Offer Form Box</h2>
//                                 <p>Offer Form will be rendered here on Live</p>
//                             </div>
//                         </Col>
//                     </Row>
//                 </Container>
//             </div>

//         </>
//     );
// };

// export default OfferPreview;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import axios from 'axios';
import './OfferPreview.css';

const OfferPreview = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [offer, setOffer] = useState(null);
    const [language, setLanguage] = useState('en'); // Default to English
    const [timeLeft, setTimeLeft] = useState(null);

    useEffect(() => {
        const fetchOffer = async () => {
            try {
                const response = await axios.get(`https://beta.api.nhcholding.com/api/offers/${id}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                setOffer(response.data);
            } catch (error) {
                console.error('Error fetching offer:', error);
            }
        };

        fetchOffer();
    }, [id]);

    useEffect(() => {
        if (offer?.offer_end_date) {
            const calculateTimeLeft = () => {
                const endDate = new Date(offer.offer_end_date);
                const now = new Date();
                const difference = endDate - now;

                if (difference > 0) {
                    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                    const minutes = Math.floor((difference / 1000 / 60) % 60);
                    const seconds = Math.floor((difference / 1000) % 60);
                    setTimeLeft(`${days} Days ${hours} Hours, ${minutes} Minutes, ${seconds} Seconds`);
                } else {
                    setTimeLeft('Offer expired');
                }
            };

            const timer = setInterval(calculateTimeLeft, 1000);

            return () => clearInterval(timer);
        }
    }, [offer]);

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    };

    if (!offer) return <div>Loading offer details...</div>;

    const translatedText = {
        en: {
            offerValid: 'Offer valid till',
            terms: '*Terms & conditions apply',
        },
        ar: {
            offerValid: 'العرض ساري حتى',
            terms: '*تطبق الشروط والأحكام',
        },
    };

    return (
        <>
            <Container fluid>
                <Row className="align-items-center mt-3 mb-3">
                    <Col>
                        <DropdownButton
                            title={`Preview Offer In: ${language === 'en' ? 'English' : 'Arabic'}`}
                            onSelect={handleLanguageChange}
                        >
                            <Dropdown.Item eventKey="en">English</Dropdown.Item>
                            <Dropdown.Item eventKey="ar">Arabic</Dropdown.Item>
                        </DropdownButton>
                    </Col>
                    <Col className="text-end">
                        <Button variant="secondary" onClick={() => navigate(-1)}>
                            Back
                        </Button>
                    </Col>
                </Row>
            </Container>
            <div className="offer-preview-banner">
                <img
                    src={`https://beta.api.nhcholding.com/storage/${offer.offer_banner}`}
                    alt={offer[`offer_name_${language}`]}
                />
            </div>
            <div className="offer-preview-bg">
                <Container className={`offer-preview-spacing offer-preview-page ${language === 'ar' ? 'rtl' : ''}`}>
                    <Row className="align-items-center">
                        <Col lg={3}>
                            <div className="offer-preview-content">
                                <h2>{offer[`offer_name_${language}`]}</h2>
                                <p>{offer[`offer_description_${language}`]}</p>
                                <h3>
                                    {translatedText[language].offerValid}{' '}
                                    {new Date(offer.offer_end_date).toLocaleDateString('en-US', {
                                        day: 'numeric',
                                        month: 'long',
                                    })}
                                </h3>
                                <p className="countdown-timer">{timeLeft}</p>
                                <p>{translatedText[language].terms}</p>
                            </div>
                        </Col>

                        <Col lg={9}>
                            <div className="preview-offer-form-wrap">
                                <h2>Offer Form Box</h2>
                                <p>Offer Form will be rendered here on Live</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default OfferPreview;

