import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import MaintenanceCalculatorTable from '../../../components/tables/MaintenanceCalculatorTable/MaintenanceCalculatorTable';
import { useNavigate } from 'react-router-dom';  // Import useNavigate

const MaintenanceCalculator = () => {
    const navigate = useNavigate();  // Initialize useNavigate

    const handleAddNewCalculation = () => {
        navigate('/cms/maintenance-calculator/add-new');  
    };

    return (
        <Container fluid>
            <Row className="mt-5 mb-5">
                <Col md={6}>
                    <h3>Maintenance Calculator</h3>
                </Col>
                <Col md={6} className="text-end">
                    <Button variant="primary" onClick={handleAddNewCalculation}>
                        Add New Calculation
                    </Button>
                </Col>
            </Row>
            <MaintenanceCalculatorTable />
        </Container>
    );
};

export default MaintenanceCalculator;
