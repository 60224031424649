import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import BrandLogo from '../../../assets/images/logo.png';
import './ForgotPasswordForm.css';

const ForgotPasswordForm = () => {
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [step, setStep] = useState(1);
    const navigate = useNavigate();

    const handleEmailSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://beta.api.nhcholding.com/api/forgot-password', { email });
            if (response.data.status === 'success') {
                toast.success(response.data.message);
                setStep(2);
            }
        } catch (error) {
            toast.error('Failed to send verification code. Please try again.');
        }
    };

    const handleResetSubmit = async (e) => {
        e.preventDefault();

        if (verificationCode.length !== 6) {
            toast.error('Please enter a valid 6-digit verification code');
            return;
        }
        
        if (newPassword !== confirmPassword) {
            toast.error('Passwords do not match');
            return;
        }

        if (newPassword.length < 8) {
            toast.error('Password should be at least 8 characters');
            return;
        }

        try {
            const response = await axios.post('https://beta.api.nhcholding.com/api/reset-password', {
                code: verificationCode,
                email,
                password: newPassword,
                password_confirmation: confirmPassword
            });

            if (response.data.status === 'success') {
                toast.success('Your password has been reset successfully!');
                setTimeout(() => navigate('/login'), 2000);
            }
        } catch (error) {
            toast.error('Failed to reset password. Please check your token and try again.');
        }
    };

    const stepTitles = ["Enter Email", "Verify Code and Reset Password"];

    return (
        <div className="forgot-password-form-container d-flex flex-column align-items-center justify-content-center vh-100">
            <ToastContainer />
            <div className="forgot-password-logo mb-4">
                <img src={BrandLogo} alt="Brand Logo" />
            </div>

            <div className="progress w-100 mb-4" style={{ maxWidth: '400px', height: '6px' }}>
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${(step / 2) * 100}%` }}
                    aria-valuenow={(step / 2) * 100}
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
            <h5 className="text-center mb-4">{stepTitles[step - 1]}</h5>

            {step === 1 && (
                <form onSubmit={handleEmailSubmit} className="forgot-password-form w-100" style={{ maxWidth: '400px' }}>
                    <div className="form-group mb-3">
                        <label htmlFor="email" className="form-label">Email Address</label>
                        <input
                            type="email"
                            id="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Send Code</button>
                </form>
            )}

            {step === 2 && (
                <form onSubmit={handleResetSubmit} className="forgot-password-form w-100" style={{ maxWidth: '400px' }}>
                    <div className="form-group mb-3">
                        <label htmlFor="verificationCode" className="form-label">Verification Code</label>
                        <input
                            type="text"
                            id="verificationCode"
                            className="form-control"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value.replace(/\D/g, ''))}
                            required
                            maxLength={6}
                            placeholder="Enter 6-digit code"
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="newPassword" className="form-label">Set New Password</label>
                        <input
                            type="password"
                            id="newPassword"
                            className="form-control"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="confirmPassword" className="form-label">Confirm New Password</label>
                        <input
                            type="password"
                            id="confirmPassword"
                            className="form-control"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Reset Password</button>
                </form>
            )}
        </div>
    );
};

export default ForgotPasswordForm;
