import React from 'react';
import { Button, Container } from 'react-bootstrap';
import BannerTable from '../../../components/tables/BannerTable/BannerTable';
import { useNavigate } from 'react-router-dom';

const BannerManagement = () => {
    const navigate = useNavigate();

    const handleAddNewBanner = () => {
        navigate('/cms/banner-management/add-new');
    };

    return (
        <Container fluid>
            <div className='d-flex justify-content-between align-items-center mt-5 mb-5'>
                <h3>Banner Management</h3>
                <Button variant="primary" onClick={handleAddNewBanner} className="mb-3">
                    Add New Banner
                </Button>
            </div>
            <BannerTable />
        </Container>
    );
};

export default BannerManagement;
