import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Container, Badge, Form, Row, Col, Button, Pagination } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ApprovalTracking = () => {
    const [trackingData, setTrackingData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [moduleFilter, setModuleFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [createdByFilter, setCreatedByFilter] = useState('');

    useEffect(() => {
        const fetchTrackingData = async () => {
            try {
                const response = await axios.get('https://beta.api.nhcholding.com/api/approval-tracking', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });

                if (response.data && response.data.status) {
                    setTrackingData(response.data.data);
                    setFilteredData(response.data.data);  // Initial filtered data set to full data
                } else {
                    toast.error('Failed to load tracking data.');
                }
            } catch (error) {
                console.error('Error fetching approval tracking data:', error);
                toast.error('Error loading approval tracking data.');
            } finally {
                setLoading(false);
            }
        };

        fetchTrackingData();
    }, []);

    const getStatusBadge = (status) => {
        const statusMapping = {
            approved: { text: 'Approved & Published', variant: 'success' },
            rejected_for_edit: { text: 'Needs Revision', variant: 'warning' },
            rejected: { text: 'Rejected', variant: 'danger' },
            manager_approved: { text: 'Approved by Manager', variant: 'info' },
            pending: { text: 'In Review', variant: 'secondary' }
        };
        const { text, variant } = statusMapping[status] || { text: status, variant: 'dark' };
        return <Badge bg={variant}>{text}</Badge>;
    };

    // Filter data based on current filters
    useEffect(() => {
        const filtered = trackingData.filter(item => {
            const matchesModule = moduleFilter ? item.module === moduleFilter : true;
            const matchesStatus = statusFilter ? item.current_status === statusFilter : true;
            const matchesCreatedBy = createdByFilter ? item.created_by.toLowerCase().includes(createdByFilter.toLowerCase()) : true;

            return matchesModule && matchesStatus && matchesCreatedBy;
        });
        setFilteredData(filtered);
    }, [moduleFilter, statusFilter, createdByFilter, trackingData]);

    // Pagination calculations
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const clearFilters = () => {
        setModuleFilter('');
        setStatusFilter('');
        setCreatedByFilter('');
    };

    if (loading) {
        return <div>Loading approval tracking data...</div>;
    }

    return (
        <Container fluid>
            <ToastContainer />
            <div className="approval-tracking-page mt-5 mb-5">
                <h3>Approval Tracking</h3>
            </div>

            <Form className="mb-3">
                <Row>
                    <Col md={3}>
                        <Form.Control
                            type="text"
                            placeholder="Search by Created By"
                            value={createdByFilter}
                            onChange={(e) => setCreatedByFilter(e.target.value)}
                            className="input-brand-shadow"
                        />
                    </Col>
                    <Col md={3}>
                        <Form.Control
                            as="select"
                            value={moduleFilter}
                            onChange={(e) => setModuleFilter(e.target.value)}
                            className="input-brand-shadow"
                        >
                            <option value="">Filter by Module</option>
                            {[...new Set(trackingData.map(item => item.module))].map(module => (
                                <option key={module} value={module}>{module}</option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col md={3}>
                        <Form.Control
                            as="select"
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                            className="input-brand-shadow"
                        >
                            <option value="">Filter by Status</option>
                            <option value="approved">Approved & Published</option>
                            <option value="rejected_for_edit">Needs Revision</option>
                            <option value="rejected">Rejected</option>
                            <option value="manager_approved">Approved by Manager</option>
                            <option value="pending">In Review</option>
                        </Form.Control>
                    </Col>

                    <Col md={3} className="text-end">
                        <Button variant="secondary" onClick={clearFilters}>Clear Filters</Button>
                    </Col>
                </Row>
            </Form>

            <div className="table-brand-shadow">
                <Table responsive striped bordered hover className="text-center">
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Module</th>
                            <th>Item Name</th>
                            <th>Created By</th>
                            <th>Created At</th>
                            <th>Current Status</th>
                            <th>Rejection Reason</th>
                            <th>Approvers</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.length === 0 ? (
                            <tr>
                                <td colSpan="8">No approval tracking data available.</td>
                            </tr>
                        ) : (
                            currentItems.map((item, index) => (
                                <tr key={item.id}>
                                    <td>{indexOfFirstItem + index + 1}</td>
                                    <td>{item.module}</td>
                                    <td>{item.item_name}</td>
                                    <td>{item.created_by}</td>
                                    <td>{new Date(item.created_at).toLocaleString()}</td>
                                    <td>{getStatusBadge(item.current_status)}</td>
                                    <td>{item.rejection_reason || 'N/A'}</td>
                                    <td>
                                        <ul className="list-unstyled mb-0">
                                            {item.approvers.map((approver, idx) => (
                                                <li key={idx}>
                                                    <strong>Level {approver.level}</strong>: {approver.role} - {approver.status}
                                                    {approver.comment && <div className="text-muted">Comment: {approver.comment}</div>}
                                                    {approver.approved_at && (
                                                        <div className="text-muted">Approved at: {new Date(approver.approved_at).toLocaleString()}</div>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
            </div>

            <Pagination className="justify-content-end">
                {[...Array(totalPages)].map((_, i) => (
                    <Pagination.Item
                        key={i + 1}
                        active={i + 1 === currentPage}
                        onClick={() => handlePageChange(i + 1)}
                    >
                        {i + 1}
                    </Pagination.Item>
                ))}
            </Pagination>
        </Container>
    );
};

export default ApprovalTracking;
