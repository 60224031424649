import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Header.css';
import BrandLogo from '../../../assets/images/logo.png';
import { Menu, LogOut } from 'lucide-react';
import { Offcanvas } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';

const Header = ({ onLogout }) => {
    const [show, setShow] = React.useState(false);
    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleLogout = async () => {
        try {
            // Retrieve the token from localStorage
            const token = localStorage.getItem('token');

            // Attempt to call the logout API
            await axios.post(
                'https://beta.api.nhcholding.com/api/logout',
                {},
                { headers: { Authorization: `Bearer ${token}` } }
            );

            // If successful, remove token, userRoles, and redirect to login
            localStorage.removeItem('token');
            localStorage.removeItem('userRoles'); // Remove userRoles from localStorage
            onLogout();
            navigate('/login'); // Redirect to login page
        } catch (error) {
            console.error('Logout failed:', error);

            // If error is 401, handle token expiry or invalid token
            if (error.response && error.response.status === 401) {
                console.warn('Token may be expired or invalid.');
                localStorage.removeItem('token');
                localStorage.removeItem('userRoles'); // Remove userRoles if token is invalid
                navigate('/login'); // Redirect to login page
            }
        }
    };

    return (
        <header className="header d-flex justify-content-between align-items-center p-3">
            <div className="hamburger" onClick={handleShow}>
                <Menu size={24} />
            </div>
            <div className="logo">
                <img src={BrandLogo} alt="Brand Logo" />
            </div>
            <div className="logout d-flex align-items-center" onClick={handleLogout}>
                <LogOut size={20} className="me-2" />
                <span>Logout</span>
            </div>

            {/* Offcanvas Sidebar */}
            <Offcanvas show={show} onHide={handleClose} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Sidebar closeOffCanvas={handleClose} />
                </Offcanvas.Body>
            </Offcanvas>
        </header>
    );
};

export default Header;

