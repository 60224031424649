import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';

// List of vehicle options for the dropdown
const vehicleOptions = [
    'Dzire', 'Baleno', 'Ciaz', 'Fronx', 'Grand Vitara', 'Ertiga', 'Jimny 5 Doors',
    'Jimny', 'Carry', 'Eeco', 'Swift 2024', 'Vitara'
];

// Validation schema using Yup
const validationSchema = Yup.object({
    vehicleName: Yup.string().required('Vehicle Name is required'), // Only Vehicle Name is required
});

const SparePartsVariantsForm = ({ initialData = {}, isEdit = false }) => {
    const [variants, setVariants] = useState(initialData.variants || ['']); // Initialize with empty variant or initial data

    const formik = useFormik({
        initialValues: {
            vehicleName: initialData.vehicleName || '', // Prefill vehicle name for edit mode
            variants: variants
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            toast.success(isEdit ? 'Spare Parts Variants updated successfully!' : 'Spare Parts Variants submitted successfully!', {
                position: "top-right"
            });
            resetForm(); // Reset form after successful submission
            setVariants(['']); // Reset variant fields
        }
    });

    // For pre-filling the form in edit mode
    useEffect(() => {
        if (isEdit) {
            formik.setValues(initialData); // Pre-fill form for editing
            setVariants(initialData.variants || ['']);
        }
    }, [initialData, isEdit]);

    const handleAddVariant = () => {
        setVariants([...variants, '']); // Add new variant field
    };

    const handleVariantChange = (index, event) => {
        const newVariants = [...variants];
        newVariants[index] = event.target.value;
        setVariants(newVariants);
        formik.setFieldValue('variants', newVariants); // Update formik values
    };

    return (
        <div className="spare-parts-variants-form">
            <ToastContainer />
            <Form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col md={6}>
                        {/* Vehicle Name Dropdown */}
                        <Form.Group controlId="vehicleName">
                            <Form.Label>Vehicle Name</Form.Label>
                            <Form.Control
                                as="select"
                                name="vehicleName"
                                {...formik.getFieldProps('vehicleName')}
                                isInvalid={formik.touched.vehicleName && formik.errors.vehicleName}
                            >
                                <option value="">Select Vehicle</option>
                                {vehicleOptions.map((vehicle, index) => (
                                    <option key={index} value={vehicle}>{vehicle}</option>
                                ))}
                            </Form.Control>
                            {formik.touched.vehicleName && formik.errors.vehicleName ? (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.vehicleName}
                                </Form.Control.Feedback>
                            ) : null}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        {/* Variants Fields */}
                        {variants.map((variant, index) => (
                            <Form.Group key={index} controlId={`variant${index}`}>
                                <Form.Label>Variant {index + 1}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={variant}
                                    onChange={(event) => handleVariantChange(index, event)}
                                    placeholder="Enter variant"
                                />
                            </Form.Group>
                        ))}

                        {/* Add More Variant Button */}
                        <Button variant="secondary" onClick={handleAddVariant} className="my-3">
                            Add More Variant
                        </Button>
                    </Col>

                    <Col md={12} className="text-end">
                        {/* Submit Button */}
                        <Button type="submit" variant="primary" disabled={formik.isSubmitting}>
                            {isEdit ? 'Update Variants' : 'Submit'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default SparePartsVariantsForm;
