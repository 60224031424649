import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap'; // Import Table from react-bootstrap
import './VehicleColorTable.css';

// Dummy data for Vehicle Colors
const vehicleColors = [
    { id: 1, colorNameEn: 'Red', colorNameAr: 'أحمر', colorType: 'Single Tone', hexCode: '#FF0000' },
    { id: 2, colorNameEn: 'Blue', colorNameAr: 'أزرق', colorType: 'Dual Tone', hexCode: '#0000FF' },
    { id: 3, colorNameEn: 'Green', colorNameAr: 'أخضر', colorType: 'Single Tone', hexCode: '#008000' }
];

const VehicleColorTable = () => {
    const handleDelete = (id) => {
        // For now, just log the delete action, but you can add deletion logic later
        console.log(`Color with ID ${id} deleted.`);
    };

    return (
        <div className="table-container">
            <Table responsive striped bordered hover className="text-center">
                <thead>
                    <tr>
                        <th>Sr. No</th>
                        <th>Color Name (En)</th>
                        <th>Color Name (Ar)</th>
                        <th>Color Type</th>
                        <th>Hex Code</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {vehicleColors.length === 0 ? (
                        <tr>
                            <td colSpan="6" className="text-center">No colors available</td>
                        </tr>
                    ) : (
                        vehicleColors.map((color, index) => (
                            <tr key={color.id}>
                                <td>{index + 1}</td>
                                <td>{color.colorNameEn}</td>
                                <td>{color.colorNameAr}</td>
                                <td>{color.colorType}</td>
                                <td>
                                    {color.hexCode}
                                </td>
                                <td>
                                    {/* Link to Edit page */}
                                    <Link to={`/cms/vehicle-colors/edit/${color.id}`} className="btn btn-warning btn-sm">
                                        Edit
                                    </Link>
                                    {/* Delete button */}
                                    <button
                                        className="btn btn-danger btn-sm ms-2"
                                        onClick={() => handleDelete(color.id)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default VehicleColorTable;
