import React from 'react';
import CityTable from '../../../components/tables/CityTable/CityTable';
import { useNavigate } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import './CityManagement.css';

const CityManagement = () => {
    const navigate = useNavigate();

    const handleAddNewCity = () => {
        navigate('/cms/cities/add-new'); // Navigation to add a new city page (to be created)
    };

    return (
        <Container fluid>
            <div className="city-management-page">
                <div className="d-flex justify-content-between align-items-center mt-5 mb-5">
                    <h3>City Management</h3>
                    <Button className="btn btn-primary" onClick={handleAddNewCity}>Add New City</Button>
                </div>
                <CityTable />
            </div>
        </Container>

    );
};

export default CityManagement;
