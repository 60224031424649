// src/components/tables/CityTable/CityTable.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap'; // Using react-bootstrap for consistent design
import './CityTable.css'; // Assuming you have some custom styles here

// Dummy data for cities
const cities = [
    { id: 'City001', cityNameEn: 'Riyadh', cityNameAr: 'الرياض' },
    { id: 'City002', cityNameEn: 'Jeddah', cityNameAr: 'جدة' },
    { id: 'City003', cityNameEn: 'Dammam', cityNameAr: 'الدمام' },
];

const CityTable = () => {
    const handleDelete = (id) => {
        console.log(`City with ID ${id} deleted.`);
        // Here you can implement the actual delete functionality, for example:
        // dispatch(deleteCityAction(id));
    };

    return (
        <div className="table-container">
            <Table striped bordered hover responsive className="text-center">
                <thead>
                    <tr>
                        <th>Sr. No</th>
                        <th>City Id</th>
                        <th>City Name (En)</th>
                        <th>City Name (Ar)</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {cities.length === 0 ? (
                        <tr>
                            <td colSpan="5" className="text-center">No cities available</td>
                        </tr>
                    ) : (
                        cities.map((city, index) => (
                            <tr key={city.id}>
                                <td>{index + 1}</td>
                                <td>{city.id}</td>
                                <td>{city.cityNameEn}</td>
                                <td>{city.cityNameAr}</td>
                                <td>
                                    {/* Edit button with Link to Edit page */}
                                    <Link to={`/cms/cities/edit/${city.id}`} className="btn btn-warning btn-sm">
                                        Edit
                                    </Link>
                                    {/* Delete button */}
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        className="ms-2"
                                        onClick={() => handleDelete(city.id)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default CityTable;
