import React from 'react';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';

// Vehicle options
const vehicleOptions = ['Dzire', 'Baleno', 'Ciaz', 'Fronx', 'Grand Vitara'];

// Validation schema using Yup
const validationSchema = Yup.object({
    vehicleName: Yup.string().required('Vehicle Name is required'),
    mileagePrices: Yup.array().of(
        Yup.object({
            mileage: Yup.number()
                .typeError('Mileage must be a number')
                .integer('Mileage must be a whole number')
                .min(0, 'Mileage must be a positive number')
                .required('Mileage is required'),
            price: Yup.number()
                .typeError('Price must be a number')
                .min(0, 'Price must be a positive number')
                .required('Price is required')
                .test('is-decimal', 'Price must be a decimal or integer', (value) =>
                    (value + '').match(/^\d+(\.\d+)?$/) // Ensures the price accepts decimal numbers
                ),
        })
    ).required('At least one mileage and price is required'),
});

const MaintenanceCalculationForm = ({ initialData = {}, isEdit = false }) => {
    const formik = useFormik({
        initialValues: {
            vehicleName: initialData.vehicleName || '',
            mileagePrices: initialData.mileagePrices || [{ mileage: '', price: '' }]
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            toast.success(isEdit ? 'Maintenance Calculation updated successfully!' : 'Maintenance Calculation added successfully!', {
                position: "top-right",
            });
            resetForm();
        },
    });

    return (
        <FormikProvider value={formik}>
            <ToastContainer />
            <Form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col md={6}>
                        {/* Vehicle Name Dropdown */}
                        <Form.Group controlId="vehicleName">
                            <Form.Label>Vehicle Name</Form.Label>
                            <Form.Control
                                as="select"
                                name="vehicleName"
                                value={formik.values.vehicleName}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.vehicleName && formik.errors.vehicleName}
                            >
                                <option value="">Select Vehicle</option>
                                {vehicleOptions.map((vehicle, index) => (
                                    <option key={index} value={vehicle}>
                                        {vehicle}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.vehicleName}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <FieldArray
                    name="mileagePrices"
                    render={(arrayHelpers) => (
                        <>
                            {formik.values.mileagePrices.map((mp, index) => (
                                <Row key={index} className="my-3 d-flex align-items-end">
                                    <Col md={5}>
                                        <Form.Group controlId={`mileagePrices[${index}].mileage`}>
                                            <Form.Label>Mileage</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name={`mileagePrices[${index}].mileage`}
                                                value={mp.mileage}
                                                onChange={formik.handleChange}
                                                isInvalid={
                                                    formik.touched.mileagePrices &&
                                                    formik.touched.mileagePrices[index] &&
                                                    formik.errors.mileagePrices &&
                                                    formik.errors.mileagePrices[index] &&
                                                    formik.errors.mileagePrices[index].mileage
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.mileagePrices && formik.errors.mileagePrices[index]?.mileage}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={5}>
                                        <Form.Group controlId={`mileagePrices[${index}].price`}>
                                            <Form.Label>Price</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={`mileagePrices[${index}].price`}
                                                value={mp.price}
                                                onChange={formik.handleChange}
                                                isInvalid={
                                                    formik.touched.mileagePrices &&
                                                    formik.touched.mileagePrices[index] &&
                                                    formik.errors.mileagePrices &&
                                                    formik.errors.mileagePrices[index] &&
                                                    formik.errors.mileagePrices[index].price
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.mileagePrices && formik.errors.mileagePrices[index]?.price}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={2} className="text-end">
                                        <Button
                                            variant="danger"
                                            onClick={() => arrayHelpers.remove(index)} // Remove this row
                                            className="mt-2"
                                        >
                                            Remove
                                        </Button>
                                    </Col>
                                </Row>
                            ))}
                            <Button
                                variant="secondary"
                                onClick={() => arrayHelpers.push({ mileage: '', price: '' })} // Add new row
                                className="mt-3"
                            >
                                Add More
                            </Button>
                        </>
                    )}
                />

                <div className="text-end mt-4">
                    <Button type="submit" variant="primary" disabled={formik.isSubmitting}>
                        {isEdit ? 'Update' : 'Submit'}
                    </Button>
                </div>
            </Form>
        </FormikProvider>
    );
};

export default MaintenanceCalculationForm;

