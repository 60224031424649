import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SparePartsPricingForm from '../../../../components/forms/SparePartsPricingForm/SparePartsPricingForm'; // Reusing the same form
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Row, Col } from 'react-bootstrap';

// Dummy data for spare parts pricing (to simulate pre-filled data)
const dummyData = {
    'Dzire': {
        vehicleName: 'Dzire',
        variantsData: [
            { variant: 'GL', descriptionEn: 'Standard Dzire Variant 1', pricing: 100, descriptionAr: 'متغير ديزاير 1' },
            { variant: 'GLX', descriptionEn: 'Standard Dzire Variant 2', pricing: 120, descriptionAr: 'متغير ديزاير 2' },
        ]
    },
    'Baleno': {
        vehicleName: 'Baleno',
        variantsData: [
            { variant: 'Alpha', descriptionEn: 'Standard Baleno Variant 1', pricing: 90, descriptionAr: 'متغير بالينو 1' },
        ]
    },
    // Add more dummy data for other vehicles as needed
};

const EditSparePartsPricing = () => {
    const { id } = useParams(); // Get vehicle name from URL parameters
    const [initialData, setInitialData] = useState({
        vehicleName: '',
        variants: []
    });

    useEffect(() => {
        // Fetching data based on the vehicle id (name) from dummy data
        if (dummyData[id]) {
            const vehicleData = dummyData[id];
            setInitialData({
                vehicleName: vehicleData.vehicleName,
                variants: vehicleData.variantsData
            });
        }
    }, [id]);

    const handleSubmit = (formData) => {
        // This function will handle form submission in Edit context
        toast.success('Spare Parts Pricing updated successfully!', {
            position: "top-right"
        });
        console.log('Updated data: ', formData); // Just log the form data for now
    };

    return (
        <Container fluid>
            <div className="edit-spare-parts-pricing mt-5 mb-5">
                <h3>Edit Spare Parts Pricing</h3>
            </div>
            <ToastContainer />
            <Row>
                <Col md={4}>
                    <h5>Edit Spare Parts Pricing Details</h5>
                    <p>Please edit Spare Parts pricing based on variants available here.</p>
                </Col>
                <Col md={8}>
                    <SparePartsPricingForm initialData={initialData} onSubmit={handleSubmit} isEdit />
                </Col>
            </Row>
        </Container>

    );
};

export default EditSparePartsPricing;
