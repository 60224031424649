import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Formik, FieldArray, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LocationForm = ({ category, handleCategoryChange, isEditMode = false, initialValues = null }) => {
    // Default initial values for fields
    const defaultInitialValues = {
        city: '',
        locationNameEn: '',
        locationNameAr: '',
        telephoneNumbers: [''],
        workingHours: {
            sundayToThursday: [{ from: '', to: '' }],
            saturday: [{ from: '', to: '' }],
            friday: [{ from: '', to: '', closed: false }],
            saturdayToThursday: [{ from: '', to: '' }],
            break: [{ from: '', to: '' }]
        },
        address: '',
        googleURL: '',
        mapIframe: '',
    };

    const formInitialValues = initialValues || defaultInitialValues;

    const validationSchema = Yup.object().shape({
        city: Yup.string().required('City is required'),
        locationNameEn: Yup.string()
            .matches(/^[A-Za-z\s]+$/, 'Location Name (En) must contain only English letters and spaces')
            .min(3, 'Location Name (En) must be at least 3 characters long')
            .required('Location Name (En) is required'),
        locationNameAr: Yup.string()
            .matches(/^[\u0600-\u06FF\s]+$/, 'Location Name (Ar) must contain only Arabic letters and spaces')
            .min(3, 'Location Name (Ar) must be at least 3 characters long')
            .required('Location Name (Ar) is required'),
        telephoneNumbers: Yup.array()
            .of(
                Yup.string()
                    .matches(/^\d{10}$/, 'Telephone Number must be a 10-digit number')
                    .required('Telephone is required')
            )
            .min(1, 'At least one telephone number is required'),
        address: Yup.string().required('Address is required'),
        googleURL: Yup.string()
            .url('Must be a valid URL')
            .required('Google Map URL is required'),
        mapIframe: Yup.string()
            .matches(
                /^<iframe src="https:\/\/www\.google\.com\/maps\/embed\?pb=!.*<\/iframe>$/,
                'Map iFrame must follow the Google Maps iframe format'
            )
            .required('Map iFrame is required'),
    });

    const handleSubmit = (values, { resetForm }) => {
        let isValid = true;

        // Conditional validation for working hours based on category
        if (category === 'Showroom') {
            if (values.workingHours.sundayToThursday.some(range => !range.from || !range.to)) {
                toast.error('Sunday to Thursday hours are required.');
                isValid = false;
            }
            if (values.workingHours.saturday.some(range => !range.from || !range.to)) {
                toast.error('Saturday hours are required.');
                isValid = false;
            }
            if (values.workingHours.friday.some(range => !range.closed && (!range.from || !range.to))) {
                toast.error('Friday hours are required unless closed.');
                isValid = false;
            }
        } else {
            if (values.workingHours.saturdayToThursday.some(range => !range.from || !range.to)) {
                toast.error('Saturday to Thursday hours are required.');
                isValid = false;
            }
            if (values.workingHours.break.some(range => !range.from || !range.to)) {
                toast.error('Break hours are required.');
                isValid = false;
            }
            if (values.workingHours.friday.some(range => !range.closed && (!range.from || !range.to))) {
                toast.error('Friday hours are required unless closed.');
                isValid = false;
            }
        }

        // Show success toast and reset form if all fields are valid
        if (isValid) {
            toast.success(isEditMode ? 'Location updated successfully!' : 'Location added successfully!', {
                position: "top-right",
                autoClose: 3000,
            });
            if (!isEditMode) resetForm();
        }
    };

    return (
        <>
            <ToastContainer />
            <Formik
                initialValues={formInitialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, errors, touched }) => (
                    <FormikForm>
                        <Form.Group as={Col}>
                            <Form.Label>Category</Form.Label>
                            <Form.Control as="select" value={category} onChange={handleCategoryChange}>
                                <option value="">Select Category</option>
                                <option value="Showroom">Showroom</option>
                                <option value="Service Centers">Service Centers</option>
                                <option value="Watani Express">Watani Express</option>
                                <option value="Spare Parts">Spare Parts</option>
                                <option value="Service Providers">Service Providers</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Select City</Form.Label>
                            <Form.Control
                                as="select"
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                isInvalid={touched.city && !!errors.city}
                            >
                                <option value="">Select City</option>
                                <option value="Riyadh">Riyadh</option>
                                <option value="Jeddah">Jeddah</option>
                                <option value="Makkah">Makkah</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Location Name (En)</Form.Label>
                            <Form.Control
                                type="text"
                                name="locationNameEn"
                                value={values.locationNameEn}
                                onChange={handleChange}
                                isInvalid={touched.locationNameEn && !!errors.locationNameEn}
                            />
                            <Form.Control.Feedback type="invalid">{errors.locationNameEn}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Location Name (Ar)</Form.Label>
                            <Form.Control
                                type="text"
                                name="locationNameAr"
                                value={values.locationNameAr}
                                onChange={handleChange}
                                isInvalid={touched.locationNameAr && !!errors.locationNameAr}
                            />
                            <Form.Control.Feedback type="invalid">{errors.locationNameAr}</Form.Control.Feedback>
                        </Form.Group>

                        <FieldArray
                            name="telephoneNumbers"
                            render={(arrayHelpers) => (
                                <Form.Group as={Col}>
                                    <Form.Label>Telephone Numbers</Form.Label>
                                    {values.telephoneNumbers.map((tel, index) => (
                                        <Row key={index}>
                                            <Col md={10}>
                                                <Form.Control
                                                    type="text"
                                                    name={`telephoneNumbers.${index}`}
                                                    value={tel}
                                                    onChange={handleChange}
                                                    isInvalid={touched.telephoneNumbers && !!errors.telephoneNumbers?.[index]}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.telephoneNumbers?.[index]}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col md={2}>
                                                <Button
                                                    variant="danger"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                    disabled={values.telephoneNumbers.length === 1}
                                                >
                                                    Remove
                                                </Button>
                                            </Col>
                                        </Row>
                                    ))}
                                    <Button variant="secondary" onClick={() => arrayHelpers.push('')}>
                                        Add More Telephone Numbers
                                    </Button>
                                </Form.Group>
                            )}
                        />

                        {category === 'Showroom' ? (
                            <>
                                <FieldArray name="workingHours.sundayToThursday">
                                    {({ push, remove }) => (
                                        <div>
                                            <h6>Sunday to Thursday</h6>
                                            {values.workingHours.sundayToThursday.map((range, index) => (
                                                <Row key={index}>
                                                    <Col>
                                                        <Form.Control
                                                            type="time"
                                                            name={`workingHours.sundayToThursday.${index}.from`}
                                                            value={range.from}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.workingHours?.sundayToThursday?.[index]?.from}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Control
                                                            type="time"
                                                            name={`workingHours.sundayToThursday.${index}.to`}
                                                            value={range.to}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.workingHours?.sundayToThursday?.[index]?.to}
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => remove(index)}
                                                            disabled={values.workingHours.sundayToThursday.length === 1}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Button variant="secondary" onClick={() => push({ from: '', to: '' })}>
                                                Add More Time
                                            </Button>
                                        </div>
                                    )}
                                </FieldArray>

                                <FieldArray name="workingHours.saturday">
                                    {({ push, remove }) => (
                                        <div>
                                            <h6>Saturday</h6>
                                            {values.workingHours.saturday.map((range, index) => (
                                                <Row key={index}>
                                                    <Col>
                                                        <Form.Control
                                                            type="time"
                                                            name={`workingHours.saturday.${index}.from`}
                                                            value={range.from}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.workingHours?.saturday?.[index]?.from}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Control
                                                            type="time"
                                                            name={`workingHours.saturday.${index}.to`}
                                                            value={range.to}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.workingHours?.saturday?.[index]?.to}
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => remove(index)}
                                                            disabled={values.workingHours.saturday.length === 1}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Button variant="secondary" onClick={() => push({ from: '', to: '' })}>
                                                Add More Time
                                            </Button>
                                        </div>
                                    )}
                                </FieldArray>

                                <FieldArray name="workingHours.friday">
                                    {({ push, remove }) => (
                                        <div>
                                            <h6>Friday</h6>
                                            {values.workingHours.friday.map((range, index) => (
                                                <Row key={index}>
                                                    <Col>
                                                        <Form.Control
                                                            type="time"
                                                            name={`workingHours.friday.${index}.from`}
                                                            value={range.from}
                                                            onChange={handleChange}
                                                            disabled={range.closed}
                                                            isInvalid={!!errors.workingHours?.friday?.[index]?.from}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Control
                                                            type="time"
                                                            name={`workingHours.friday.${index}.to`}
                                                            value={range.to}
                                                            onChange={handleChange}
                                                            disabled={range.closed}
                                                            isInvalid={!!errors.workingHours?.friday?.[index]?.to}
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name={`workingHours.friday.${index}.closed`}
                                                            label="Closed"
                                                            checked={range.closed}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => remove(index)}
                                                            disabled={values.workingHours.friday.length === 1}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Button variant="secondary" onClick={() => push({ from: '', to: '', closed: false })}>
                                                Add More Time
                                            </Button>
                                        </div>
                                    )}
                                </FieldArray>
                            </>
                        ) : (
                            <>
                                <FieldArray name="workingHours.saturdayToThursday">
                                    {({ push, remove }) => (
                                        <div>
                                            <h6>Saturday to Thursday</h6>
                                            {values.workingHours.saturdayToThursday.map((range, index) => (
                                                <Row key={index}>
                                                    <Col>
                                                        <Form.Control
                                                            type="time"
                                                            name={`workingHours.saturdayToThursday.${index}.from`}
                                                            value={range.from}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.workingHours?.saturdayToThursday?.[index]?.from}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Control
                                                            type="time"
                                                            name={`workingHours.saturdayToThursday.${index}.to`}
                                                            value={range.to}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.workingHours?.saturdayToThursday?.[index]?.to}
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => remove(index)}
                                                            disabled={values.workingHours.saturdayToThursday.length === 1}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Button variant="secondary" onClick={() => push({ from: '', to: '' })}>
                                                Add More Time
                                            </Button>
                                        </div>
                                    )}
                                </FieldArray>

                                <FieldArray name="workingHours.break">
                                    {({ push, remove }) => (
                                        <div>
                                            <h6>Break</h6>
                                            {values.workingHours.break.map((range, index) => (
                                                <Row key={index}>
                                                    <Col>
                                                        <Form.Control
                                                            type="time"
                                                            name={`workingHours.break.${index}.from`}
                                                            value={range.from}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.workingHours?.break?.[index]?.from}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Control
                                                            type="time"
                                                            name={`workingHours.break.${index}.to`}
                                                            value={range.to}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.workingHours?.break?.[index]?.to}
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => remove(index)}
                                                            disabled={values.workingHours.break.length === 1}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Button variant="secondary" onClick={() => push({ from: '', to: '' })}>
                                                Add More Time
                                            </Button>
                                        </div>
                                    )}
                                </FieldArray>

                                <FieldArray name="workingHours.friday">
                                    {({ push, remove }) => (
                                        <div>
                                            <h6>Friday</h6>
                                            {values.workingHours.friday.map((range, index) => (
                                                <Row key={index}>
                                                    <Col>
                                                        <Form.Control
                                                            type="time"
                                                            name={`workingHours.friday.${index}.from`}
                                                            value={range.from}
                                                            onChange={handleChange}
                                                            disabled={range.closed}
                                                            isInvalid={!!errors.workingHours?.friday?.[index]?.from}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Control
                                                            type="time"
                                                            name={`workingHours.friday.${index}.to`}
                                                            value={range.to}
                                                            onChange={handleChange}
                                                            disabled={range.closed}
                                                            isInvalid={!!errors.workingHours?.friday?.[index]?.to}
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name={`workingHours.friday.${index}.closed`}
                                                            label="Closed"
                                                            checked={range.closed}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => remove(index)}
                                                            disabled={values.workingHours.friday.length === 1}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Button variant="secondary" onClick={() => push({ from: '', to: '', closed: false })}>
                                                Add More Time
                                            </Button>
                                        </div>
                                    )}
                                </FieldArray>
                            </>
                        )}

                        <Form.Group as={Col}>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                isInvalid={touched.address && !!errors.address}
                            />
                            <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Google Map URL</Form.Label>
                            <Form.Control
                                type="url"
                                name="googleURL"
                                value={values.googleURL}
                                onChange={handleChange}
                                isInvalid={touched.googleURL && !!errors.googleURL}
                            />
                            <Form.Control.Feedback type="invalid">{errors.googleURL}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Map iFrame</Form.Label>
                            <Form.Control
                                type="text"
                                name="mapIframe"
                                value={values.mapIframe}
                                onChange={handleChange}
                                isInvalid={touched.mapIframe && !!errors.mapIframe}
                            />
                            <Form.Control.Feedback type="invalid">{errors.mapIframe}</Form.Control.Feedback>
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            {isEditMode ? 'Save Changes' : 'Submit'}
                        </Button>
                    </FormikForm>
                )}
            </Formik>
        </>
    );
};

export default LocationForm;
