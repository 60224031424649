import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col, Container } from 'react-bootstrap';
import SparePartsVariantsTable from '../../../components/tables/SparePartsVariantsTable/SparePartsVariantsTable';
import './SparePartsVariants.css';

const SparePartsVariants = () => {
    return (
        <Container fluid>
             <div className="spare-parts-variants-page">
            <Row className="align-items-center mt-5 mb-5">
                <Col md={6}>
                    <h3 className="page-title">Spare Parts Variants</h3>
                </Col>
                <Col md={6} className="text-end">
                    <Link to="/spare-parts/variants/add">
                        <Button variant="primary">Add New Spare Parts Variant</Button>
                    </Link>
                </Col>
            </Row>
            
            <SparePartsVariantsTable />
        </div>
        </Container>
       
    );
};

export default SparePartsVariants;
