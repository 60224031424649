import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserRolesForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isEditMode = location.state?.isEditMode || false;
    const userId = location.state?.userId || null;

    const [departments, setDepartments] = useState([]);
    const [roles, setRoles] = useState([]);
    const [initialValues, setInitialValues] = useState({
        userName: '',
        email: '',
        department: '',
        role: '',
        password: '',
        confirmPassword: '',
    });
    const [loading, setLoading] = useState(isEditMode);

    // Function to map status to readable text
    const getStatusText = (status) => {
        const statusMapping = {
            admin: 'Admin',
            'mr-user': 'Marketing User',
            'mr-manager': 'Marketing Manager',
            it: 'IT User',
            manager: 'Manager',
            user: 'User',
            'website-user': 'Website User',
        };
        return statusMapping[status] || status;
    };

    useEffect(() => {
        const fetchUserData = async () => {
            if (isEditMode && userId) {
                try {
                    const response = await axios.get(`https://beta.api.nhcholding.com/api/users/${userId}`, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                    });
                    const user = response.data;
                    setInitialValues({
                        userName: user.name,
                        email: user.email,
                        department: user.department_id ? user.department_id.toString() : '',
                        role: user.roles[0]?.name || '',
                        password: '',
                        confirmPassword: '',
                    });
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    toast.error('Failed to load user data for editing.');
                    navigate('/user-roles');
                }
            }
        };

        const fetchDepartments = async () => {
            try {
                const response = await axios.get('https://beta.api.nhcholding.com/api/departments', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                setDepartments(response.data);
            } catch (error) {
                toast.error('Error fetching departments.');
            }
        };

        const fetchRoles = async () => {
            try {
                const response = await axios.get('https://beta.api.nhcholding.com/api/roles', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                setRoles(response.data.roles || []);
            } catch (error) {
                toast.error('Error fetching roles.');
            }
        };

        if (isEditMode) {
            fetchUserData();
        }
        fetchDepartments();
        fetchRoles();
    }, [isEditMode, userId, navigate]);

    const validationSchema = Yup.object().shape({
        userName: Yup.string().required('User Name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        department: Yup.string().required('Department is required'),
        role: Yup.string().required('Role is required'),
        password: Yup.string().min(8, 'Password must be at least 8 characters').when('isEditMode', {
            is: false,
            then: Yup.string().required('Password is required'),
        }),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .when('isEditMode', {
                is: false,
                then: Yup.string().required('Confirm Password is required'),
            }),
    });

    const handleSubmit = async (values, { resetForm }) => {
        const payload = {
            name: values.userName,
            email: values.email,
            department_id: values.department,
            roles: [values.role]
        };
    
        if (!isEditMode || values.password) {
            payload.password = values.password;
            payload.password_confirmation = values.confirmPassword;
        }
    
        try {
            const url = isEditMode
                ? `https://beta.api.nhcholding.com/api/users/${userId}`
                : 'https://beta.api.nhcholding.com/api/users';
            const method = isEditMode ? 'put' : 'post';
    
            const response = await axios({
                method,
                url,
                data: payload,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
    
            if (response.status === (isEditMode ? 200 : 201)) {
                toast.success(isEditMode ? 'User updated successfully!' : 'User created successfully!');
                resetForm();
                navigate('/user-roles', { state: { updated: true } });
            } else {
                toast.error('An error occurred. Please try again.');
            }
        } catch (error) {
            console.error('Error during submission:', error);
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                Object.values(errors).flat().forEach(errMessage => {
                    toast.error(errMessage);
                });
            } else {
                toast.error(isEditMode ? 'Failed to update user.' : 'Failed to create user.');
            }
        }
    };
    

    if (loading) {
        return <div>Loading user data...</div>;
    }

    return (
        <>
            <ToastContainer />
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, errors, touched }) => (
                    <FormikForm>
                        <Row className='align-items-center'>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>User Name<span className='required-mark'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="userName"
                                        value={values.userName}
                                        onChange={handleChange}
                                        isInvalid={touched.userName && !!errors.userName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.userName}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Email<span className='required-mark'>*</span></Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={touched.email && !!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Department<span className='required-mark'>*</span></Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="department"
                                        value={values.department}
                                        onChange={handleChange}
                                        isInvalid={touched.department && !!errors.department}
                                    >
                                        <option value="">Select Department</option>
                                        {departments.map((dept) => (
                                            <option key={dept.id} value={dept.id}>{dept.name}</option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.department}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Role<span className='required-mark'>*</span></Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="role"
                                        value={values.role}
                                        onChange={handleChange}
                                        isInvalid={touched.role && !!errors.role}
                                    >
                                        <option value="">Select Role</option>
                                        {roles.map((role) => (
                                            <option key={role.name} value={role.name}>
                                                {getStatusText(role.name)}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.role}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            {!isEditMode && (
                                <>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Set New Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                isInvalid={touched.password && !!errors.password}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.password}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={5}>
                                        <Form.Group>
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="confirmPassword"
                                                value={values.confirmPassword}
                                                onChange={handleChange}
                                                isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.confirmPassword}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </>
                            )}
                        </Row>
                        <div className="text-end">
                            <Button variant="primary" type="submit">
                                {isEditMode ? 'Update Changes' : 'Submit'}
                            </Button>
                        </div>
                    </FormikForm>
                )}
            </Formik>
        </>
    );
};

export default UserRolesForm;
