import React from 'react';
import SetApprovalHierarchyForm from '../../../components/forms/SetApprovalHierarchyForm/SetApprovalHierarchyForm';
import { Container, Row, Col } from 'react-bootstrap';

const SetHierarchy = () => {
    return (
        <Container fluid>
            <div className='set-hierarchy-page mt-5 mb-5'>
                <h3>Set Approval Hierarchy</h3>
            </div>

            <Row>
                <Col md={4}>
                    <h5>Approval Hierarchy Details</h5>
                    <p>Set the Approval Hierarchy Details here.</p>
                </Col>

                <Col md={8}>
                    <SetApprovalHierarchyForm isEditMode={false} />
                </Col>
            </Row>
        </Container>
    );
};

export default SetHierarchy;
