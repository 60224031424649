


// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Table, Button, Badge, Pagination, Form, Row, Col } from 'react-bootstrap';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import './OfferTable.css';

// const OfferTable = () => {
//     const [offers, setOffers] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [offersPerPage] = useState(5);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [startDateFilter, setStartDateFilter] = useState('');
//     const [endDateFilter, setEndDateFilter] = useState('');
//     const [statusFilter, setStatusFilter] = useState('');
//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchOffers = async () => {
//             try {
//                 const response = await axios.get('https://beta.api.nhcholding.com/api/offers', {
//                     headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
//                 });
//                 if (response.data && Array.isArray(response.data.data)) {
//                     setOffers(response.data.data);
//                 } else {
//                     console.error("Unexpected response format:", response.data);
//                     toast.error('Failed to load offers.');
//                 }
//             } catch (error) {
//                 console.error('Error fetching offers:', error);
//                 toast.error('Error loading offers.');
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchOffers();
//     }, []);

//     const getStatusBadge = (status) => {
//         const statusMapping = {
//             approved: { text: 'Approved & Published', variant: 'success' },
//             rejected_for_edit: { text: 'Needs Revision', variant: 'warning' },
//             rejected: { text: 'Rejected', variant: 'danger' },
//             manager_approved: { text: 'Approved by Manager', variant: 'info' },
//             pending: { text: 'In Review', variant: 'secondary' }
//         };
//         const { text, variant } = statusMapping[status] || { text: status, variant: 'dark' };
//         return <Badge bg={variant}>{text}</Badge>;
//     };

//     const isExpired = (endDate) => {
//         const today = new Date();
//         const offerEndDate = new Date(endDate);
//         return offerEndDate < today;
//     };

//     const handleView = (id) => {
//         navigate(`/offers/view/${id}`);
//     };

//     // Apply filters to offers
//     const filteredOffers = offers.filter((offer) => {
//         const matchesSearch = offer.offer_name_en.toLowerCase().includes(searchTerm.toLowerCase()) ||
//             offer.offer_name_ar.toLowerCase().includes(searchTerm.toLowerCase());
//         const matchesStartDate = startDateFilter ? offer.offer_start_date === startDateFilter : true;
//         const matchesEndDate = endDateFilter ? offer.offer_end_date === endDateFilter : true;
//         const matchesStatus = statusFilter ? offer.status === statusFilter : true;

//         return matchesSearch && matchesStartDate && matchesEndDate && matchesStatus;
//     });

//     // Pagination calculations
//     const indexOfLastOffer = currentPage * offersPerPage;
//     const indexOfFirstOffer = indexOfLastOffer - offersPerPage;
//     const currentOffers = filteredOffers.slice(indexOfFirstOffer, indexOfLastOffer);
//     const totalPages = Math.ceil(filteredOffers.length / offersPerPage);

//     const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

//     if (loading) return <div>Loading offers...</div>;

//     return (
//         <div className="table-container">
//             <Form className="mb-3">
//                 <Row>
//                     <Col md={4}>
//                         <Form.Control
//                             type="search"
//                             placeholder="Search by Offer Name"
//                             value={searchTerm}
//                             onChange={(e) => setSearchTerm(e.target.value)}
//                             className='input-brand-shadow'
//                         />
//                     </Col>
//                     <Col md={2}>
//                         <Form.Control
//                             as="select"
//                             value={startDateFilter}
//                             onChange={(e) => setStartDateFilter(e.target.value)}
//                             className='input-brand-shadow'
//                         >
//                             <option value="">Start Date</option>
//                             {[...new Set(offers.map(offer => offer.offer_start_date))].map((date) => (
//                                 <option key={date} value={date}>{date}</option>
//                             ))}
//                         </Form.Control>
//                     </Col>
//                     <Col md={2}>
//                         <Form.Control
//                             as="select"
//                             value={endDateFilter}
//                             onChange={(e) => setEndDateFilter(e.target.value)}
//                             className='input-brand-shadow'
//                         >
//                             <option value="">End Date</option>
//                             {[...new Set(offers.map(offer => offer.offer_end_date))].map((date) => (
//                                 <option key={date} value={date}>{date}</option>
//                             ))}
//                         </Form.Control>
//                     </Col>
//                     <Col md={2}>
//                         <Form.Control
//                             as="select"
//                             value={statusFilter}
//                             onChange={(e) => setStatusFilter(e.target.value)}
//                             className='input-brand-shadow'
//                         >
//                             <option value="">Status</option>
//                             <option value="approved">Approved & Published</option>
//                             <option value="rejected_for_edit">Needs Revision</option>
//                             <option value="rejected">Rejected</option>
//                             <option value="manager_approved">Approved by Manager</option>
//                             <option value="pending">In Review</option>
//                         </Form.Control>
//                     </Col>
//                     <Col md={2} className='text-end'>
//                         <Button variant="secondary" onClick={() => {
//                             setSearchTerm('');
//                             setStartDateFilter('');
//                             setEndDateFilter('');
//                             setStatusFilter('');
//                         }}>Clear Filters</Button>
//                     </Col>
//                 </Row>
//             </Form>

//             <div className='table-brand-shadow'>
//                 <Table responsive striped bordered hover className="text-center">
//                     <thead className='brand-table-head'>
//                         <tr>
//                             <th>Sr. No</th>
//                             <th>Offer Id</th>
//                             <th>Offer Name (En)</th>
//                             <th>Offer Name (Ar)</th>
//                             <th>Offer Start Date</th>
//                             <th>Offer End Date</th>
//                             <th>Status</th>
//                             <th>Actions</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {currentOffers.length === 0 ? (
//                             <tr>
//                                 <td colSpan="8" className="text-center">No offers are available</td>
//                             </tr>
//                         ) : (
//                             currentOffers.map((offer, index) => {
//                                 const expired = isExpired(offer.offer_end_date);
//                                 return (
//                                     <tr key={offer.id}>
//                                         <td>{indexOfFirstOffer + index + 1}</td>
//                                         <td>{offer.id}</td>
//                                         <td>{offer.offer_name_en}</td>
//                                         <td>{offer.offer_name_ar}</td>
//                                         <td>{offer.offer_start_date}</td>
//                                         <td>{offer.offer_end_date}</td>
//                                         <td>{getStatusBadge(offer.status)}</td>
//                                         <td>
//                                             {expired ? (
//                                                 <Button variant="primary" size="sm" onClick={() => handleView(offer.id)}>
//                                                     View
//                                                 </Button>
//                                             ) : (
//                                                 <>
//                                                     <Button variant="warning" size="sm" onClick={() => navigate(`/offers/edit/${offer.id}`)}>
//                                                         Edit
//                                                     </Button>{' '}
//                                                     <Button variant="primary" size="sm" onClick={() => handleView(offer.id)}>
//                                                         View
//                                                     </Button>
//                                                 </>
//                                             )}
//                                         </td>
//                                     </tr>
//                                 );
//                             })
//                         )}
//                     </tbody>
//                 </Table>
//             </div>

//             <div className='table-brand-shadow'>

//             </div>

//             <Pagination className="justify-content-end">
//                 {[...Array(totalPages)].map((_, i) => (
//                     <Pagination.Item
//                         key={i + 1}
//                         active={i + 1 === currentPage}
//                         onClick={() => handlePageChange(i + 1)}
//                     >
//                         {i + 1}
//                     </Pagination.Item>
//                 ))}
//             </Pagination>
//         </div>
//     );
// };

// export default OfferTable;









// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Card, Button, Badge, Pagination, Form, Row, Col } from 'react-bootstrap';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import './OfferTable.css';

// const OfferTable = () => {
//     const [offers, setOffers] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [offersPerPage] = useState(8);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [startDateFilter, setStartDateFilter] = useState('');
//     const [endDateFilter, setEndDateFilter] = useState('');
//     const [statusFilter, setStatusFilter] = useState('');
//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchOffers = async () => {
//             try {
//                 const response = await axios.get('https://beta.api.nhcholding.com/api/offers', {
//                     headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
//                 });
//                 if (response.data && Array.isArray(response.data.data)) {
//                     setOffers(response.data.data);
//                 } else {
//                     console.error("Unexpected response format:", response.data);
//                     toast.error('Failed to load offers.');
//                 }
//             } catch (error) {
//                 console.error('Error fetching offers:', error);
//                 toast.error('Error loading offers.');
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchOffers();
//     }, []);

//     const getStatusBadge = (status) => {
//         const statusMapping = {
//             approved: { text: 'Approved & Published', variant: 'success' },
//             rejected_for_edit: { text: 'Needs Revision', variant: 'warning' },
//             rejected: { text: 'Rejected', variant: 'danger' },
//             manager_approved: { text: 'Approved by Manager', variant: 'info' },
//             pending: { text: 'In Review', variant: 'secondary' }
//         };
//         const { text, variant } = statusMapping[status] || { text: status, variant: 'dark' };
//         return <Badge bg={variant}>{text}</Badge>;
//     };

//     const handleView = (id) => {
//         navigate(`/offers/view/${id}`);
//     };

//     // Apply filters to offers
//     const filteredOffers = offers.filter((offer) => {
//         const matchesSearch = offer.offer_name_en.toLowerCase().includes(searchTerm.toLowerCase()) ||
//             offer.offer_name_ar.toLowerCase().includes(searchTerm.toLowerCase());
//         const matchesStartDate = startDateFilter ? offer.offer_start_date === startDateFilter : true;
//         const matchesEndDate = endDateFilter ? offer.offer_end_date === endDateFilter : true;
//         const matchesStatus = statusFilter ? offer.status === statusFilter : true;

//         return matchesSearch && matchesStartDate && matchesEndDate && matchesStatus;
//     });

//     // Pagination calculations
//     const indexOfLastOffer = currentPage * offersPerPage;
//     const indexOfFirstOffer = indexOfLastOffer - offersPerPage;
//     const currentOffers = filteredOffers.slice(indexOfFirstOffer, indexOfLastOffer);
//     const totalPages = Math.ceil(filteredOffers.length / offersPerPage);

//     const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

//     if (loading) return <div>Loading offers...</div>;

//     return (
//         <div className="card-container">
//             <Form className="mb-3">
//                 <Row>
//                     <Col md={4}>
//                         <Form.Control
//                             type="search"
//                             placeholder="Search by Offer Name"
//                             value={searchTerm}
//                             onChange={(e) => setSearchTerm(e.target.value)}
//                             className="input-brand-shadow"
//                         />
//                     </Col>
//                     <Col md={2}>
//                         <Form.Control
//                             as="select"
//                             value={startDateFilter}
//                             onChange={(e) => setStartDateFilter(e.target.value)}
//                             className="input-brand-shadow form-select"
//                         >
//                             <option value="">Start Date</option>
//                             {[...new Set(offers.map((offer) => offer.offer_start_date))].map((date) => (
//                                 <option key={date} value={date}>{date}</option>
//                             ))}
//                         </Form.Control>
//                     </Col>
//                     <Col md={2}>
//                         <Form.Control
//                             as="select"
//                             value={endDateFilter}
//                             onChange={(e) => setEndDateFilter(e.target.value)}
//                             className="input-brand-shadow form-select"
//                         >
//                             <option value="">End Date</option>
//                             {[...new Set(offers.map((offer) => offer.offer_end_date))].map((date) => (
//                                 <option key={date} value={date}>{date}</option>
//                             ))}
//                         </Form.Control>
//                     </Col>
//                     <Col md={2}>
//                         <Form.Control
//                             as="select"
//                             value={statusFilter}
//                             onChange={(e) => setStatusFilter(e.target.value)}
//                             className="input-brand-shadow form-select"
//                         >
//                             <option value="">Status</option>
//                             <option value="approved">Approved & Published</option>
//                             <option value="rejected_for_edit">Needs Revision</option>
//                             <option value="rejected">Rejected</option>
//                             <option value="manager_approved">Approved by Manager</option>
//                             <option value="pending">In Review</option>
//                         </Form.Control>
//                     </Col>
//                     <Col md={2} className="text-end">
//                         <Button
//                             variant="secondary"
//                             onClick={() => {
//                                 setSearchTerm('');
//                                 setStartDateFilter('');
//                                 setEndDateFilter('');
//                                 setStatusFilter('');
//                             }}
//                         >
//                             Clear Filters
//                         </Button>
//                     </Col>
//                 </Row>
//             </Form>

//             <Row>
//                 {currentOffers.length === 0 ? (
//                     <div className="text-center">No offers are available</div>
//                 ) : (
//                     currentOffers.map((offer, index) => (
//                         <Col key={offer.id} md={6} lg={3} className="mb-4">
//                             <Card className="h-100 offer-card-wrap">
//                                 <div className='offer-card-status-wrap'>
//                                     {getStatusBadge(offer.status)}
//                                 </div>

//                                 <Card.Img
//                                     variant="top"
//                                     src={`https://beta.api.nhcholding.com/storage/${offer.offer_banner}`}
//                                     alt={offer.offer_name_en}
//                                 />
//                                 <Card.Body>
//                                     <Card.Title>{offer.offer_name_en}</Card.Title>
//                                     <Card.Subtitle className="mb-2 text-muted">
//                                         {offer.offer_name_ar}
//                                     </Card.Subtitle>
//                                     <Card.Text>
//                                         <strong>Start:</strong> {offer.offer_start_date} <br />
//                                         <strong>End:</strong> {offer.offer_end_date} <br />

//                                     </Card.Text>
//                                 </Card.Body>
//                                 <div className="d-grid col-12 gap-2 mx-auto">
//                                     <Button
//                                         variant="warning"
//                                         size="md"
//                                         onClick={() => navigate(`/offers/edit/${offer.id}`)}
//                                     >
//                                         Edit
//                                     </Button>
//                                     <Button
//                                         variant="primary"
//                                         size="md"
//                                         onClick={() => handleView(offer.id)}
//                                     >
//                                         View
//                                     </Button>
//                                 </div>
//                             </Card>
//                         </Col>
//                     ))
//                 )}
//             </Row>

//             <Pagination className="justify-content-end">
//                 {[...Array(totalPages)].map((_, i) => (
//                     <Pagination.Item
//                         key={i + 1}
//                         active={i + 1 === currentPage}
//                         onClick={() => handlePageChange(i + 1)}
//                     >
//                         {i + 1}
//                     </Pagination.Item>
//                 ))}
//             </Pagination>
//         </div>
//     );
// };

// export default OfferTable;


import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Badge, Pagination, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './OfferTable.css';

const OfferTable = () => {
    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [offersPerPage] = useState(8);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDateFilter, setStartDateFilter] = useState('');
    const [endDateFilter, setEndDateFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOffers = async () => {
            try {
                const response = await axios.get('https://beta.api.nhcholding.com/api/offers', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                if (response.data && Array.isArray(response.data.data)) {
                    setOffers(response.data.data);
                } else {
                    console.error("Unexpected response format:", response.data);
                    toast.error('Failed to load offers.');
                }
            } catch (error) {
                console.error('Error fetching offers:', error);
                toast.error('Error loading offers.');
            } finally {
                setLoading(false);
            }
        };

        fetchOffers();
    }, []);

    const getStatusBadge = (status) => {
        const statusMapping = {
            approved: { text: 'Approved & Published', variant: 'success' },
            rejected_for_edit: { text: 'Needs Revision', variant: 'warning' },
            rejected: { text: 'Rejected', variant: 'danger' },
            manager_approved: { text: 'Approved by Manager', variant: 'info' },
            pending: { text: 'In Review', variant: 'secondary' }
        };
        const { text, variant } = statusMapping[status] || { text: status, variant: 'dark' };
        return <Badge bg={variant}>{text}</Badge>;
    };

    const handleView = (id) => {
        navigate(`/offers/view/${id}`);
    };

    const handlePreview = (id) => {
        navigate(`/offers/preview/${id}`);
    };

    // Apply filters to offers
    const filteredOffers = offers.filter((offer) => {
        const matchesSearch = offer.offer_name_en.toLowerCase().includes(searchTerm.toLowerCase()) ||
            offer.offer_name_ar.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesStartDate = startDateFilter ? offer.offer_start_date === startDateFilter : true;
        const matchesEndDate = endDateFilter ? offer.offer_end_date === endDateFilter : true;
        const matchesStatus = statusFilter ? offer.status === statusFilter : true;

        return matchesSearch && matchesStartDate && matchesEndDate && matchesStatus;
    });

    // Pagination calculations
    const indexOfLastOffer = currentPage * offersPerPage;
    const indexOfFirstOffer = indexOfLastOffer - offersPerPage;
    const currentOffers = filteredOffers.slice(indexOfFirstOffer, indexOfLastOffer);
    const totalPages = Math.ceil(filteredOffers.length / offersPerPage);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    if (loading) return <div>Loading offers...</div>;

    return (
        <div className="card-container">
            <Form className="mb-3">
                <Row>
                    <Col md={4}>
                        <Form.Control
                            type="search"
                            placeholder="Search by Offer Name"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="input-brand-shadow"
                        />
                    </Col>
                    <Col md={2}>
                        <Form.Control
                            as="select"
                            value={startDateFilter}
                            onChange={(e) => setStartDateFilter(e.target.value)}
                            className="input-brand-shadow form-select"
                        >
                            <option value="">Start Date</option>
                            {[...new Set(offers.map((offer) => offer.offer_start_date))].map((date) => (
                                <option key={date} value={date}>{date}</option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col md={2}>
                        <Form.Control
                            as="select"
                            value={endDateFilter}
                            onChange={(e) => setEndDateFilter(e.target.value)}
                            className="input-brand-shadow form-select"
                        >
                            <option value="">End Date</option>
                            {[...new Set(offers.map((offer) => offer.offer_end_date))].map((date) => (
                                <option key={date} value={date}>{date}</option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col md={2}>
                        <Form.Control
                            as="select"
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                            className="input-brand-shadow form-select"
                        >
                            <option value="">Status</option>
                            <option value="approved">Approved & Published</option>
                            <option value="rejected_for_edit">Needs Revision</option>
                            <option value="rejected">Rejected</option>
                            <option value="manager_approved">Approved by Manager</option>
                            <option value="pending">In Review</option>
                        </Form.Control>
                    </Col>
                    <Col md={2} className="text-end">
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setSearchTerm('');
                                setStartDateFilter('');
                                setEndDateFilter('');
                                setStatusFilter('');
                            }}
                        >
                            Clear Filters
                        </Button>
                    </Col>
                </Row>
            </Form>

            <Row>
                {currentOffers.length === 0 ? (
                    <div className="text-center">No offers are available</div>
                ) : (
                    currentOffers.map((offer, index) => (
                        <Col key={offer.id} md={6} lg={3} className="mb-4">
                            <Card className="h-100 offer-card-wrap">
                                <div className='offer-card-status-wrap'>
                                    {getStatusBadge(offer.status)}
                                </div>

                                <Card.Img
                                    variant="top"
                                    src={`https://beta.api.nhcholding.com/storage/${offer.offer_banner}`}
                                    alt={offer.offer_name_en}
                                />
                                <Card.Body>
                                    <Card.Title>{offer.offer_name_en}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">
                                        {offer.offer_name_ar}
                                    </Card.Subtitle>
                                    <Card.Text>
                                        <strong>Start:</strong> {offer.offer_start_date} <br />
                                        <strong>End:</strong> {offer.offer_end_date} <br />

                                    </Card.Text>
                                </Card.Body>
                                <div className="d-grid col-12 gap-2 mx-auto">
                                    <Button
                                        variant="warning"
                                        size="md"
                                        onClick={() => navigate(`/offers/edit/${offer.id}`)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="primary"
                                        size="md"
                                        onClick={() => handleView(offer.id)}
                                    >
                                        View
                                    </Button>
                                    {(offer.status === 'pending' || 
                                        offer.status === 'manager_approved' || 
                                        offer.status === 'rejected_for_edit' || 
                                        offer.status === 'rejected') && (
                                        <Button
                                            variant="info"
                                            size="md"
                                            onClick={() => handlePreview(offer.id)}
                                        >
                                            Preview
                                        </Button>
                                    )}
                                </div>
                            </Card>
                        </Col>
                    ))
                )}
            </Row>

            <Pagination className="justify-content-end">
                {[...Array(totalPages)].map((_, i) => (
                    <Pagination.Item
                        key={i + 1}
                        active={i + 1 === currentPage}
                        onClick={() => handlePageChange(i + 1)}
                    >
                        {i + 1}
                    </Pagination.Item>
                ))}
            </Pagination>
        </div>
    );
};

export default OfferTable;
