import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import UTMForm from '../../../components/forms/UTMForm/UTMForm';

const AddNewUTM = () => {
    return (
        <Container fluid>
            <div className="add-new-utm-page mt-5 mb-5">
                <h3 className='mb-4'>Create New UTM</h3>
                <Row>
                    <Col md={4}>
                        <h5>Create New UTM </h5>
                        <p>Add the UTM details here to create UTM link.</p>
                    </Col>

                    <Col md={8}>
                        <UTMForm />
                    </Col>
                </Row>

            </div>
        </Container>
    );
};

export default AddNewUTM;
