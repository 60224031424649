import React from 'react';
import SetApprovalHierarchyForm from '../../../components/forms/SetApprovalHierarchyForm/SetApprovalHierarchyForm';
import { useParams } from 'react-router-dom'; // To fetch the ID or unique identifier
import { Container, Row, Col } from 'react-bootstrap';

const EditHierarchy = () => {
    const { id } = useParams(); // Get the ID to load specific hierarchy data for edit

    return (

        <Container fluid>
            <div className='edit-approval-hierarchy-page mt-5 mb-5'>
                <h2>Edit Approval Hierarchy</h2>
            </div>

            <Row>
                <Col md={4}>
                <h5>Edit Approval Hierarchy Details</h5>
                <p>Edit the Approval Hierarchy Details here.</p>
                </Col>
                <Col md={8}>
                    <SetApprovalHierarchyForm isEditMode={true} hierarchyId={id} />
                </Col>
            </Row>
        </Container>



    );
};

export default EditHierarchy;
