import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Image, Badge, Pagination, Form, Row, Col, Modal, Spinner, Container } from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ApprovalRequestsTable = () => {
    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [offersPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDateFilter, setStartDateFilter] = useState('');
    const [endDateFilter, setEndDateFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [rejectionReason, setRejectionReason] = useState('');
    const [comment, setComment] = useState('');
    const [approvingOfferId, setApprovingOfferId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOffers = async () => {
            try {
                const response = await axios.get('https://beta.api.nhcholding.com/api/offers', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                if (response.data && Array.isArray(response.data.data)) {
                    setOffers(response.data.data);
                } else {
                    toast.error('Failed to load offers.');
                }
            } catch (error) {
                toast.error('Error loading offers.');
            } finally {
                setLoading(false);
            }
        };

        fetchOffers();
    }, []);

    const getStatusBadge = (status) => {
        const statusMapping = {
            approved: { text: 'Approved & Published', variant: 'success' },
            rejected_for_edit: { text: 'Needs Revision', variant: 'warning' },
            rejected: { text: 'Rejected', variant: 'danger' },
            manager_approved: { text: 'Approved by Manager', variant: 'info' },
            pending: { text: 'In Review', variant: 'secondary' }
        };
        const { text, variant } = statusMapping[status] || { text: status, variant: 'dark' };
        return <Badge bg={variant}>{text}</Badge>;
    };

    const isExpired = (endDate) => {
        const today = new Date();
        const offerEndDate = new Date(endDate);
        return offerEndDate < today;
    };

    const handleApprove = async (offerId) => {
        setApprovingOfferId(offerId);
        try {
            await axios.post(`https://beta.api.nhcholding.com/api/offers/${offerId}/approve`, null, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            toast.success('Offer has been approved');
            updateOfferStatus(offerId, 'approved');
        } catch (error) {
            toast.error('Failed to approve offer');
        } finally {
            setApprovingOfferId(null);
        }
    };

    const handleReject = async () => {
        if (comment.length < 10) {
            toast.error("Rejection comment must be at least 10 characters.");
            return;
        }
        try {
            await axios.post(`https://beta.api.nhcholding.com/api/offers/${selectedOffer}/reject`, {
                comment,
                rejection_type: rejectionReason
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            toast.success('Offer has been rejected');
            updateOfferStatus(selectedOffer, rejectionReason);
            closeRejectModal();
        } catch (error) {
            toast.error('Failed to reject offer');
        }
    };

    const updateOfferStatus = (offerId, newStatus) => {
        setOffers((prevOffers) =>
            prevOffers.map((offer) =>
                offer.id === offerId ? { ...offer, status: newStatus } : offer
            )
        );
    };

    const openRejectModal = (offerId) => {
        setSelectedOffer(offerId);
        setShowRejectModal(true);
    };

    const closeRejectModal = () => {
        setShowRejectModal(false);
        setRejectionReason('');
        setComment('');
    };

    const handleView = (id) => {
        navigate(`/offers/view/${id}`);
    };

    const filteredOffers = offers.filter((offer) => {
        const matchesSearch = offer.offer_name_en.toLowerCase().includes(searchTerm.toLowerCase()) ||
            offer.offer_name_ar.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesStartDate = startDateFilter ? offer.offer_start_date === startDateFilter : true;
        const matchesEndDate = endDateFilter ? offer.offer_end_date === endDateFilter : true;
        const matchesStatus = statusFilter ? offer.status === statusFilter : true;

        return matchesSearch && matchesStartDate && matchesEndDate && matchesStatus;
    });

    const indexOfLastOffer = currentPage * offersPerPage;
    const indexOfFirstOffer = indexOfLastOffer - offersPerPage;
    const currentOffers = filteredOffers.slice(indexOfFirstOffer, indexOfLastOffer);
    const totalPages = Math.ceil(filteredOffers.length / offersPerPage);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    if (loading) return <div>Loading offers...</div>;

    return (
        <Container fluid>
             <div className="table-container">
            <ToastContainer />
            <div className='approval-requests-page mt-5 mb-5'>
                <h3>Approval Requests</h3>
            </div>

            <Form className="mb-3">
                <Row>
                    <Col md={4}>
                        <Form.Control
                            type="search"
                            placeholder="Search by Offer Name"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="input-brand-shadow"
                        />
                    </Col>
                    <Col md={2}>
                        <Form.Control
                            as="select"
                            value={startDateFilter}
                            onChange={(e) => setStartDateFilter(e.target.value)}
                            className="input-brand-shadow"
                        >
                            <option value="">Start Date</option>
                            {[...new Set(offers.map(offer => offer.offer_start_date))].map((date) => (
                                <option key={date} value={date}>{date}</option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col md={2}>
                        <Form.Control
                            as="select"
                            value={endDateFilter}
                            onChange={(e) => setEndDateFilter(e.target.value)}
                            className="input-brand-shadow"
                        >
                            <option value="">End Date</option>
                            {[...new Set(offers.map(offer => offer.offer_end_date))].map((date) => (
                                <option key={date} value={date}>{date}</option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col md={2}>
                        <Form.Control
                            as="select"
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                            className="input-brand-shadow"
                        >
                            <option value="">Status</option>
                            <option value="approved">Approved & Published</option>
                            <option value="rejected_for_edit">Needs Revision</option>
                            <option value="rejected">Rejected</option>
                            <option value="manager_approved">Approved by Manager</option>
                            <option value="pending">In Review</option>
                        </Form.Control>
                    </Col>
                    <Col md={2} className="text-end">
                        <Button variant="secondary" onClick={() => {
                            setSearchTerm('');
                            setStartDateFilter('');
                            setEndDateFilter('');
                            setStatusFilter('');
                        }}>Clear Filters</Button>
                    </Col>
                </Row>
            </Form>

            <div className="table-brand-shadow">
                <Table responsive striped bordered hover className="text-center">
                    <thead>
                        <tr>
                            <th>Sr. No</th>
                            <th>Offer Id</th>
                            <th>Offer Banner</th>
                            <th>Offer Name (En)</th>
                            <th>Offer Name (Ar)</th>
                            <th>Offer Start Date</th>
                            <th>Offer End Date</th>
                            <th>Approval Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentOffers.length === 0 ? (
                            <tr>
                                <td colSpan="9" className="text-center">No offers are available</td>
                            </tr>
                        ) : (
                            currentOffers.map((offer, index) => {
                                const expired = isExpired(offer.offer_end_date);
                                return (
                                    <tr key={offer.id}>
                                        <td>{indexOfFirstOffer + index + 1}</td>
                                        <td>{offer.id}</td>
                                        <td>
                                            {offer.offer_banner && (
                                                <Image
                                                    src={`https://beta.api.nhcholding.com/storage/${offer.offer_banner}`}
                                                    alt="Offer Banner"
                                                    width={80}
                                                    height={80}
                                                    rounded
                                                />
                                            )}
                                        </td>
                                        <td>{offer.offer_name_en}</td>
                                        <td>{offer.offer_name_ar}</td>
                                        <td>{offer.offer_start_date}</td>
                                        <td>{offer.offer_end_date}</td>
                                        <td>{getStatusBadge(offer.status)}</td>
                                        <td>
                                            <Button variant="info" size="sm" onClick={() => handleView(offer.id)}>View</Button>{' '}
                                            {!expired && (
                                                <>
                                                    <Button
                                                        variant="success"
                                                        size="sm"
                                                        onClick={() => handleApprove(offer.id)}
                                                        disabled={offer.status === 'approved' || approvingOfferId === offer.id}
                                                    >
                                                        {approvingOfferId === offer.id ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Approve"}
                                                    </Button>{' '}
                                                    <Button
                                                        variant="danger"
                                                        size="sm"
                                                        onClick={() => openRejectModal(offer.id)}
                                                        disabled={offer.status === 'approved'}
                                                    >
                                                        Reject
                                                    </Button>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </Table>
            </div>

            <Pagination className="justify-content-end">
                {[...Array(totalPages)].map((_, i) => (
                    <Pagination.Item
                        key={i + 1}
                        active={i + 1 === currentPage}
                        onClick={() => handlePageChange(i + 1)}
                    >
                        {i + 1}
                    </Pagination.Item>
                ))}
            </Pagination>

            <Modal show={showRejectModal} onHide={closeRejectModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Reject Offer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="rejectionReason">
                            <Form.Label>Reason for Rejection</Form.Label>
                            <Form.Control as="select" value={rejectionReason} onChange={(e) => setRejectionReason(e.target.value)} required>
                                <option value="">Select reason</option>
                                <option value="rejected_for_edit">Reject for Edit</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="comment" className="mt-3">
                            <Form.Label>Rejection Comment</Form.Label>
                            <Form.Control as="textarea" rows={3} value={comment} onChange={(e) => setComment(e.target.value)} required />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeRejectModal}>Cancel</Button>
                    <Button variant="danger" onClick={handleReject}>Reject</Button>
                </Modal.Footer>
            </Modal>
        </div>
        </Container>
       
    );
};

export default ApprovalRequestsTable;
