import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './SparePartsVariantsTable.css';

// Dummy data for spare parts variants
const sparePartsData = [
    {
        id: 'SP001',
        vehicleName: 'Swift',
        variants: ['GL', 'GLX', 'GL Plus Dual Tone'],
    },
    {
        id: 'SP002',
        vehicleName: 'Baleno',
        variants: ['2019-2023 GL', '2019-2023 GLX', '2024 GL', '2024 GLX'],
    },
    {
        id: 'SP003',
        vehicleName: 'Grand Vitara',
        variants: ['2WDGL', '2WD GLX', '2WD GLX+SR', '4WD GLX+SR'],
    },
];

const SparePartsVariantsTable = () => {
    const handleDelete = (id) => {
        console.log(`Spare part with ID ${id} deleted.`);
        // Add delete logic here (API call or state update when APIs are ready)
    };
    
    return (
        <div className="table-responsive">
            <Table responsive striped bordered hover className='text-center'>
                <thead>
                    <tr>
                        <th>Sr. No</th>
                        <th>Spare Parts Id</th>
                        <th>Vehicle Name</th>
                        <th>Spare Parts Variants</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sparePartsData.length === 0 ? (
                        <tr>
                            <td colSpan="5" className="text-center">No spare parts variants available</td>
                        </tr>
                    ) : (
                        sparePartsData.map((sparePart, index) => (
                            <tr key={sparePart.id}>
                                <td>{index + 1}</td>
                                <td>{sparePart.id}</td>
                                <td>{sparePart.vehicleName}</td>
                                <td>{sparePart.variants.join(', ')}</td> {/* Display variants as a comma-separated list */}
                                <td>
                                    <Link to={`/spare-parts-variants/edit/${sparePart.id}`} className="btn btn-warning btn-sm">
                                        Edit
                                    </Link>
                                    <button className="btn btn-danger btn-sm ms-2" onClick={() => handleDelete(sparePart.id)}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default SparePartsVariantsTable;

