import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MaintenanceCalculationForm from '../../../../components/forms/MaintenanceCalculationForm/MaintenanceCalculationForm';

const AddNewCalculation = () => {
    return (
        <Container fluid>
            <div className='add-new-calculation-page mt-5 mb-5'>
                <h3>Add New Maintenance Calculation</h3>
            </div>

            <Row>
                <Col md={4}>
                    <h5>Add Maintenance Calculator Prices</h5>
                    <p>Please add Maintenance Calculator based on vehicle here.</p>
                </Col>

                <Col md={8}>
                <MaintenanceCalculationForm />
                </Col>
            </Row>
            
        </Container>
    );
};

export default AddNewCalculation;
