import React, { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import UserRoleTable from '../../../components/tables/UserRoleTable/UserRoleTable';
import { useNavigate } from 'react-router-dom';

const UserRoles = () => {
    const navigate = useNavigate();
    const [isMarketingManager, setIsMarketingManager] = useState(false);

    useEffect(() => {
        // Retrieve and parse user roles from localStorage
        const storedRoles = JSON.parse(localStorage.getItem('userRoles') || '[]');
        console.log('Retrieved roles from localStorage:', storedRoles); // Debug log

        // Check if the 'mr-manager' role is present
        const hasMarketingRole = Array.isArray(storedRoles) && storedRoles.includes('mr-manager');
        setIsMarketingManager(hasMarketingRole);
        console.log('Is Marketing Manager (should hide button):', hasMarketingRole); // Debug log
    }, []);

    const handleAddUser = () => {
        navigate('/user-roles/add-new');
    };

    return (
        <Container fluid>
            <div className='user-roles-page'>
                <div className="d-flex justify-content-between align-items-center mt-5 mb-5">
                    <h3>User Roles</h3>
                    {/* Conditionally render the Add New User button */}
                    {!isMarketingManager && (
                        <Button onClick={handleAddUser} variant="primary">
                            Add New User
                        </Button>
                    )}
                </div>
                <UserRoleTable isMarketingManager={isMarketingManager} />
            </div>
        </Container>
    );
};

export default UserRoles;
