import React from 'react';
import { Button, Container } from 'react-bootstrap';
import BlogManagementTable from '../../../components/tables/BlogManagementTable/BlogManagementTable';
import { useNavigate } from 'react-router-dom';

const BlogManagement = () => {
    const navigate = useNavigate();

    const handleAddNewBlog = () => {
        navigate('/cms/blog-management/add-new');
    };

    return (
        <Container fluid>
            <div className="d-flex justify-content-between align-items-center mt-5 mb-5">
                <h3>Blog Management</h3>
                <Button variant="primary" onClick={handleAddNewBlog}>Add New Blog</Button>
            </div>
            <BlogManagementTable />
        </Container>

    );
};

export default BlogManagement;
