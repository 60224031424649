import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SparePartsVariantsForm from '../../../../components/forms/SparePartsVariantsForm/SparePartsVariantsForm';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Dummy data for spare parts variants (until API is ready)
const dummySparePartsData = [
    { id: 'SP001', vehicleName: 'Dzire', variants: ['GL', 'GLX'] },
    { id: 'SP002', vehicleName: 'Baleno', variants: ['Zeta', 'Alpha'] },
    { id: 'SP003', vehicleName: 'Ertiga', variants: ['VXI', 'ZXI'] },
];

const EditSparePartsVariants = () => {
    const { id } = useParams(); // Get the ID from the URL
    const [initialData, setInitialData] = useState(null);

    useEffect(() => {
        // Simulating fetch from dummy data
        const sparePart = dummySparePartsData.find(item => item.id === id);
        if (sparePart) {
            setInitialData(sparePart); // Set the form initial data for editing
        }
    }, [id]);

    const handleFormSubmit = (values) => {
        // Simulating form submission with dummy data
        toast.success('Spare Parts Variants updated successfully!', {
            position: "top-right"
        });
        console.log('Updated Spare Parts Data:', values);
    };

    return (
        <div className="edit-spare-parts-variants">
            <ToastContainer />
            <h3>Edit Spare Parts Variants</h3>
            {initialData ? (
                <SparePartsVariantsForm
                    initialData={initialData}
                    onSubmit={handleFormSubmit}
                    isEdit={true} // Pass isEdit to true so form knows it's in edit mode
                />
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default EditSparePartsVariants;
