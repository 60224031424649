import React from 'react';
import { useParams } from 'react-router-dom';
import OfferForm from '../../../components/forms/OfferForm/OfferForm';
import { Col, Container, Row } from 'react-bootstrap';

const EditOffer = () => {
    const { id } = useParams(); // Get the offer ID from the URL params

    return (
        <Container fluid>
            <div className="edit-offer-page mt-5 mb-5">
                <h2>Edit Offer</h2>
            </div>

            <Row>
                <Col md={4}>
                    <h5>Offer Details</h5>
                    <p>Edit the offer details here.</p>
                </Col>

                <Col md={8}>
                    {/* Pass offer ID and edit mode flag to OfferForm */}
                    <OfferForm offerId={id} isEdit={true} />
                </Col>
            </Row>
        </Container>
    );
};

export default EditOffer;
