import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SparePartsVariantsForm from '../../../../components/forms/SparePartsVariantsForm/SparePartsVariantsForm';

const AddNewSparePartsVariants = () => {
    return (
        <Container fluid>
            <div className="add-new-spare-parts-variants mt-5 mb-5">
                <h3>Add New Spare Parts Variants</h3>
            </div>
            <Row>
                <Col md={4}>
                    <h5>Spare Parts Variants Addtion</h5>
                    <p>Please add the Spare Parts Variants details here.</p>
                </Col>

                <Col md={8}>
                    <SparePartsVariantsForm />
                </Col>
            </Row>


        </Container>

    );
};

export default AddNewSparePartsVariants;
