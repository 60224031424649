import React from 'react';
import { Container } from 'react-bootstrap';
import VehicleForm from '../../../components/forms/VehicleForm/VehicleForm';

const EditVehicle = () => {
    const handleSubmit = (values) => {
        console.log("Updated vehicle data:", values);
        // Future API call can go here to submit the updated data
    };

    return (
        <Container fluid>

            <div className='edit-vehicle-page mt-5 mb-5'>
                <h3>Edit Vehicle</h3>
            </div>
            <VehicleForm
                isEditMode={true}
                onSubmit={handleSubmit}
            />

        </Container>


    );
};

export default EditVehicle;
