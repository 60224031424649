import React from 'react';
import { Button, Container } from 'react-bootstrap';
import SparePartsPricingTable from '../../../components/tables/SparePartsPricingTable/SparePartsPricingTable';
import './SparePartsPricing.css';
import { Link } from 'react-router-dom';

const SparePartsPricing = () => {
    return (
        <Container fluid>
            <div className="spare-parts-pricing-page mt-5 mb-5">
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h3>Spare Parts Pricing</h3>
                    <Link to="/spare-parts-management/spare-parts-pricing/add">
                        <Button variant="primary">Add New Spare Parts Pricing</Button>
                    </Link>
                </div>
                <SparePartsPricingTable />
            </div>
        </Container>

    );
};

export default SparePartsPricing;
