import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// Dummy data for now
const approvalHierarchies = [
    {
        id: 1,
        module: 'Offer Management',
        approvers: [
            { name: 'John Doe', role: 'Manager', level: 1 },
            { name: 'Jane Smith', role: 'Director', level: 2 },
        ]
    },
    {
        id: 2,
        module: 'Vehicle Management',
        approvers: [
            { name: 'Samuel Lee', role: 'Supervisor', level: 1 },
            { name: 'Lisa Wong', role: 'CEO', level: 2 },
        ]
    }
];

const ApprovalHierarchyTable = () => {
    const navigate = useNavigate();

    const handleEdit = (id) => {
        navigate(`/user-approvals/edit-hierarchy/${id}`);
    };

    return (
        <Table esponsive striped bordered hover className='text-center'>
            <thead>
                <tr>
                    <th>Module</th>
                    <th>Approvers</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {approvalHierarchies.map(hierarchy => (
                    <tr key={hierarchy.id}>
                        <td>{hierarchy.module}</td>
                        <td>
                            {hierarchy.approvers.map((approver, index) => (
                                <div key={index}>
                                    {`Level ${approver.level}: ${approver.name} (${approver.role})`}
                                </div>
                            ))}
                        </td>
                        <td>
                            <Button variant="warning" size="sm" onClick={() => handleEdit(hierarchy.id)}>
                                Edit
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default ApprovalHierarchyTable;
