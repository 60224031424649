import React, { useState, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Form as BootstrapForm } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

const BlogForm = ({ onSubmit }) => {
    const [contentEn, setContentEn] = useState(''); // Content for English
    const [contentAr, setContentAr] = useState(''); // Content for Arabic
    const quillRef = useRef(null); // Quill instance reference

    const initialValues = {
        featuredImage: null,
        titleEn: '',
        titleAr: '',
    };

    const validationSchema = Yup.object().shape({
        featuredImage: Yup.mixed().required('Featured Image is required'),
        titleEn: Yup.string().required('Blog Title (En) is required'),
        titleAr: Yup.string().required('Blog Title (Ar) is required'),
    });

    const handleFormSubmit = (values, { resetForm }, isDraft = false) => {
        const dataToSubmit = {
            ...values,
            contentEn,
            contentAr,
            status: isDraft ? 'Draft' : 'Published',
        }; // Combine values with content editors and status
        onSubmit(dataToSubmit);
        toast.success(isDraft ? 'Blog saved as draft!' : 'Blog published successfully!');
        resetForm();
        setContentEn(''); // Clear English editor content
        setContentAr(''); // Clear Arabic editor content
    };

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link', 'image'],
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],
            ['clean'],
        ],
    };

    return (
        <div>
            <ToastContainer />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => handleFormSubmit(values, actions, false)}
            >
                {({ setFieldValue, errors, touched, handleSubmit }) => (
                    <Form>
                        <Row className="mb-3">
                            <Col md={6}>
                                <BootstrapForm.Group>
                                    <BootstrapForm.Label>Upload Featured Image</BootstrapForm.Label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        onChange={(e) => setFieldValue('featuredImage', e.target.files[0])}
                                    />
                                    {touched.featuredImage && errors.featuredImage && (
                                        <div className="text-danger">{errors.featuredImage}</div>
                                    )}
                                </BootstrapForm.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <BootstrapForm.Group>
                                    <BootstrapForm.Label>Blog Title (En)</BootstrapForm.Label>
                                    <Field
                                        type="text"
                                        name="titleEn"
                                        placeholder="Enter blog title in English"
                                        className="form-control"
                                    />
                                    {touched.titleEn && errors.titleEn && (
                                        <div className="text-danger">{errors.titleEn}</div>
                                    )}
                                </BootstrapForm.Group>
                            </Col>
                            <Col md={6}>
                                <BootstrapForm.Group>
                                    <BootstrapForm.Label>Blog Title (Ar)</BootstrapForm.Label>
                                    <Field
                                        type="text"
                                        name="titleAr"
                                        placeholder="Enter blog title in Arabic"
                                        className="form-control"
                                    />
                                    {touched.titleAr && errors.titleAr && (
                                        <div className="text-danger">{errors.titleAr}</div>
                                    )}
                                </BootstrapForm.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={12}>
                                <BootstrapForm.Group>
                                    <BootstrapForm.Label>Content (En)</BootstrapForm.Label>
                                    <ReactQuill
                                        ref={quillRef}
                                        theme="snow"
                                        value={contentEn}
                                        onChange={(value) => setContentEn(value)} // Controlled by contentEn state
                                        modules={modules}
                                        placeholder="Write your blog content in English here..."
                                    />
                                </BootstrapForm.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={12}>
                                <BootstrapForm.Group>
                                    <BootstrapForm.Label>Content (Ar)</BootstrapForm.Label>
                                    <ReactQuill
                                        ref={quillRef}
                                        theme="snow"
                                        value={contentAr}
                                        onChange={(value) => setContentAr(value)} // Controlled by contentAr state
                                        modules={modules}
                                        placeholder="Write your blog content in Arabic here..."
                                    />
                                </BootstrapForm.Group>
                            </Col>
                        </Row>
                        <div className="text-end">
                            <Button
                                variant="secondary"
                                className="me-2"
                                onClick={() => handleFormSubmit(initialValues, { resetForm: handleSubmit }, true)}
                            >
                                Save as Draft
                            </Button>
                            <Button
                                variant="primary"
                                onClick={handleSubmit}
                            >
                                Publish
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default BlogForm;