// import React from 'react';
// import { Container, Row, Col, Card, Table, Badge } from 'react-bootstrap';
// import {
//     PieChart,
//     Pie,
//     Cell,
//     BarChart,
//     Bar,
//     XAxis,
//     YAxis,
//     CartesianGrid,
//     Tooltip,
//     LineChart,
//     Line,
//     Legend,
//     AreaChart,
//     Area,
// } from 'recharts';
// import './Dashboard.css';
// import OfferTable from '../../components/tables/OfferTable/OfferTable';
// import UserRoleTable from '../../components/tables/UserRoleTable/UserRoleTable';
// import ApprovalHierarchyTable from '../../components/tables/ApprovalHierarchyTable/ApprovalHierarchyTable';
// import ApprovalRequestsTable from '../UserRolesApproval/UserApproval/ApprovalRequests';
// import ApprovalTracking from '../UserRolesApproval/UserApproval/ApprovalTracking';
// import UserRoleCharts from '../../components/charts/UserRoleCharts/UserRoleCharts';

// const Dashboard = () => {
//     // Sample Data
//     const offerData = {
//         total: 120,
//         active: 80,
//         expired: 25,
//         pending: 10,
//         approved: 95,
//         rejected: 5,
//     };

//     const offerStatusData = [
//         { name: 'Approved', value: 95, color: '#28a745' },
//         { name: 'Pending', value: 10, color: '#ffc107' },
//         { name: 'Rejected', value: 5, color: '#dc3545' },
//     ];

//     const vehicleData = [
//         { category: 'Sedans', count: 50 },
//         { category: 'SUVs', count: 40 },
//         { category: 'Hatchbacks', count: 30 },
//         { category: 'Trucks', count: 10 },
//     ];

//     const approvalTrendData = [
//         { week: 'Week 1', time: 4 },
//         { week: 'Week 2', time: 5 },
//         { week: 'Week 3', time: 3 },
//         { week: 'Week 4', time: 6 },
//     ];

//     const sparePartsData = [
//         { name: 'Brake Pads', sales: 100 },
//         { name: 'Oil Filter', sales: 80 },
//         { name: 'Air Filter', sales: 70 },
//         { name: 'Spark Plug', sales: 60 },
//         { name: 'Battery', sales: 50 },
//     ];

//     const revenueTrend = [
//         { month: 'Jan', revenue: 5000 },
//         { month: 'Feb', revenue: 7000 },
//         { month: 'Mar', revenue: 8000 },
//         { month: 'Apr', revenue: 6000 },
//         { month: 'May', revenue: 9000 },
//         { month: 'Jun', revenue: 7000 },
//     ];

//     const userRolesData = [
//         { name: 'Admin', value: 10, color: '#007bff' },
//         { name: 'Manager', value: 15, color: '#28a745' },
//         { name: 'Staff', value: 25, color: '#ffc107' },
//     ];

//     const userKPI = {
//         totalUsers: 50,
//         activeUsers: 40,
//     };

//     const financialMetrics = {
//         totalRevenue: 50000,
//         pendingPayments: 12000,
//     };

//     return (
//         <Container fluid className="dashboard-page">

//             <div className='user-title'>
//                 <h3>Good Day, Sultan Siddiqui</h3>
//             </div>
//             {/* Offer Management */}
//             <Row className="mt-4">
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Total Offers</Card.Title>
//                             <h3>{offerData.total}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Active Offers</Card.Title>
//                             <h3>{offerData.active}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Pending Offers</Card.Title>
//                             <h3>{offerData.pending}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Rejected Offers</Card.Title>
//                             <h3>{offerData.rejected}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>

//             <Row className="mt-4">
//                 <Col md={3}>
//                     <Card className='mb-3'>
//                         <Card.Body>
//                             <Card.Title>Offer Status Breakdown</Card.Title>
//                             <PieChart width={300} height={300}>
//                                 <Pie
//                                     data={offerStatusData}
//                                     dataKey="value"
//                                     nameKey="name"
//                                     cx="50%"
//                                     cy="50%"
//                                     outerRadius={100}
//                                     label
//                                 >
//                                     {offerStatusData.map((entry, index) => (
//                                         <Cell key={`cell-${index}`} fill={entry.color} />
//                                     ))}
//                                 </Pie>
//                                 <Tooltip />
//                             </PieChart>
//                         </Card.Body>
//                     </Card>
//                 </Col>

//                 <Col md={9}>
//                     <Card className='mb-3'>
//                         <Card.Body>
//                             <Card.Title>Current Offers</Card.Title>
//                             <OfferTable />
//                         </Card.Body>
//                     </Card>
//                 </Col>

//                 <Col md={12}>
//                     <Card className='mb-3'>
//                         <Card.Body>
//                             {/* <Card.Title>Approval Requests</Card.Title> */}
//                             <ApprovalRequestsTable />
//                         </Card.Body>
//                     </Card>
//                 </Col>

//                 <Col md={9}>
//                     <Card className='mb-3'>
//                         <Card.Body>
//                             <Card.Title>Users Roles</Card.Title>
//                             <UserRoleTable />
//                         </Card.Body>
//                     </Card>
//                 </Col>

//                 <Col md={3}>
//                     <div className='mb-3'>
//                         <UserRoleCharts />
//                     </div>
//                 </Col>

//                 <Col md={6}>
//                     <Card className='mb-3'>
//                         <Card.Body>
//                             {/* <Card.Title>Approval Requests</Card.Title> */}
//                             <ApprovalTracking />
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>

//             {/* <Row className="mt-4">
//                 <Col md={6}>
//                     <Card>
//                         <Card.Body>
//                             <Card.Title>Vehicles by Category</Card.Title>
//                             <BarChart
//                                 width={500}
//                                 height={300}
//                                 data={vehicleData}
//                                 margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
//                             >
//                                 <CartesianGrid strokeDasharray="3 3" />
//                                 <XAxis dataKey="category" />
//                                 <YAxis />
//                                 <Tooltip />
//                                 <Bar dataKey="count" fill="#007bff" />
//                             </BarChart>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={6}>
//                     <Card>
//                         <Card.Body>
//                             <Card.Title>Approval Time Trends</Card.Title>
//                             <LineChart
//                                 width={500}
//                                 height={300}
//                                 data={approvalTrendData}
//                                 margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
//                             >
//                                 <CartesianGrid strokeDasharray="3 3" />
//                                 <XAxis dataKey="week" />
//                                 <YAxis />
//                                 <Tooltip />
//                                 <Legend />
//                                 <Line type="monotone" dataKey="time" stroke="#007bff" />
//                             </LineChart>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={6}>
//                     <Card>
//                         <Card.Body>
//                             <Card.Title>Pending Approvals</Card.Title>
//                             <Table responsive striped bordered hover className="text-center">
//                                 <thead>
//                                     <tr>
//                                         <th>#</th>
//                                         <th>Approval ID</th>
//                                         <th>Module</th>
//                                         <th>Item</th>
//                                         <th>Status</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     <tr>
//                                         <td>1</td>
//                                         <td>1001</td>
//                                         <td>Offer</td>
//                                         <td>Summer Sale</td>
//                                         <td>
//                                             <Badge bg="secondary">Pending</Badge>
//                                         </td>
//                                     </tr>
//                                     <tr>
//                                         <td>2</td>
//                                         <td>1002</td>
//                                         <td>Spare Part</td>
//                                         <td>Brake Pad</td>
//                                         <td>
//                                             <Badge bg="info">Manager Approved</Badge>
//                                         </td>
//                                     </tr>
//                                 </tbody>
//                             </Table>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>

//             <Row className="mt-4">
//                 <Col md={6}>
//                     <Card>
//                         <Card.Body>
//                             <Card.Title>Top Spare Parts by Sales</Card.Title>
//                             <BarChart width={500} height={300} data={sparePartsData}>
//                                 <CartesianGrid strokeDasharray="3 3" />
//                                 <XAxis dataKey="name" />
//                                 <YAxis />
//                                 <Tooltip />
//                                 <Bar dataKey="sales" fill="#28a745" />
//                             </BarChart>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={6}>
//                     <Card>
//                         <Card.Body>
//                             <Card.Title>Revenue Trends</Card.Title>
//                             <AreaChart width={500} height={300} data={revenueTrend}>
//                                 <CartesianGrid strokeDasharray="3 3" />
//                                 <XAxis dataKey="month" />
//                                 <YAxis />
//                                 <Tooltip />
//                                 <Area type="monotone" dataKey="revenue" stroke="#007bff" fill="#007bff" />
//                             </AreaChart>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>

//             <Row className="mt-4">
//                 <Col md={6}>
//                     <Card>
//                         <Card.Body>
//                             <Card.Title>User Role Distribution</Card.Title>
//                             <PieChart width={300} height={300}>
//                                 <Pie
//                                     data={userRolesData}
//                                     dataKey="value"
//                                     nameKey="name"
//                                     cx="50%"
//                                     cy="50%"
//                                     outerRadius={100}
//                                     label
//                                 >
//                                     {userRolesData.map((entry, index) => (
//                                         <Cell key={`cell-${index}`} fill={entry.color} />
//                                     ))}
//                                 </Pie>
//                                 <Tooltip />
//                             </PieChart>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={6}>
//                     <Card>
//                         <Card.Body>
//                             <Card.Title>Financial Overview</Card.Title>
//                             <Row>
//                                 <Col>
//                                     <h5>Total Revenue</h5>
//                                     <h3>${financialMetrics.totalRevenue}</h3>
//                                 </Col>
//                                 <Col>
//                                     <h5>Pending Payments</h5>
//                                     <h3>${financialMetrics.pendingPayments}</h3>
//                                 </Col>
//                             </Row>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row> */}
//         </Container>
//     );
// };

// export default Dashboard;


// import React, { useEffect, useState } from 'react';
// import { Container, Row, Col, Card } from 'react-bootstrap';
// import {
//         PieChart,
//         Pie,
//         Cell,
//         BarChart,
//         Bar,
//         XAxis,
//         YAxis,
//         CartesianGrid,
//         Tooltip,
//         LineChart,
//         Line,
//         Legend,
//         AreaChart,
//         Area,
//         ResponsiveContainer
//     } from 'recharts';
// import axios from 'axios';
// import './Dashboard.css';
// import OfferTable from '../../components/tables/OfferTable/OfferTable';
// import UserRoleTable from '../../components/tables/UserRoleTable/UserRoleTable';
// import ApprovalHierarchyTable from '../../components/tables/ApprovalHierarchyTable/ApprovalHierarchyTable';
// import ApprovalRequestsTable from '../UserRolesApproval/UserApproval/ApprovalRequests';
// import ApprovalTracking from '../UserRolesApproval/UserApproval/ApprovalTracking';
// import UserRoleCharts from '../../components/charts/UserRoleCharts/UserRoleCharts';
// import ApprovalTrackingChart from '../../components/charts/ApprovalTrackingChart/ApprovalTrackingChart';

// const Dashboard = () => {
//     const [offerCounts, setOfferCounts] = useState({
//         total: 0,
//         active: 0,
//         pending: 0,
//         rejected: 0,
//         expired: 0,
//         approved: 0,
//     });

//     const [offerStatusData, setOfferStatusData] = useState([]);

//     useEffect(() => {
//         const fetchOfferData = async () => {
//             try {
//                 const response = await axios.get('https://beta.api.nhcholding.com/api/offers', {
//                     headers: {
//                         Authorization: `Bearer ${localStorage.getItem('token')}`,
//                     },
//                 });

//                 if (response.data && Array.isArray(response.data.data)) {
//                     const offers = response.data.data;

//                     // Calculate counts
//                     const total = offers.length;
//                     const active = offers.filter((offer) => offer.status === 'approved').length;
//                     const pending = offers.filter((offer) => offer.status === 'pending').length;
//                     const rejected = offers.filter((offer) => offer.status === 'rejected_for_edit').length;
//                     const expired = offers.filter((offer) => new Date(offer.offer_end_date) < new Date()).length;
//                     const approved = offers.filter((offer) => offer.status === 'approved').length;

//                     // Update state
//                     setOfferCounts({ total, active, pending, rejected, expired, approved });

//                     // Prepare data for Offer Status Breakdown
//                     setOfferStatusData([
//                         { name: 'Approved', value: approved, color: '#28a745' },
//                         { name: 'Pending', value: pending, color: '#ffc107' },
//                         { name: 'Rejected', value: rejected, color: '#dc3545' },
//                     ]);
//                 } else {
//                     console.error('Unexpected response format:', response.data);
//                 }
//             } catch (error) {
//                 console.error('Error fetching offers:', error);
//             }
//         };

//         fetchOfferData();
//     }, []);

//     return (
//         <Container fluid className="dashboard-page">
//             <div className="user-title">
//                 <h3>Good Day, Sultan Siddiqui</h3>
//             </div>

//             {/* Offer Management */}
//             {/* <Row className="mt-4">
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Total Offers</Card.Title>
//                             <h3>{offerCounts.total}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Active Offers</Card.Title>
//                             <h3>{offerCounts.active}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Pending Offers</Card.Title>
//                             <h3>{offerCounts.pending}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Rejected Offers</Card.Title>
//                             <h3>{offerCounts.rejected}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row> */}

//             <Row className="mt-4">
//                 {Object.keys(offerCounts).map((key, index) => (
//                     <Col md={3} key={index}>
//                         <Card className="mb-3">
//                             <Card.Body>
//                                 <Card.Title>{`${key.charAt(0).toUpperCase() + key.slice(1)} Offers`}</Card.Title>
//                                 <h3>{offerCounts[key]}</h3>
//                                 <ResponsiveContainer width="100%" height={50}>
//                                     <BarChart data={[{ name: key, value: offerCounts[key] }]}>
//                                         <CartesianGrid strokeDasharray="3 3" />
//                                         <XAxis dataKey="name" hide />
//                                         <YAxis hide />
//                                         <Tooltip />
//                                         <Bar dataKey="value" fill="#007bff" />
//                                     </BarChart>
//                                 </ResponsiveContainer>
//                                 <div className="text-muted" style={{ marginTop: '10px' }}>
//                                     {key === 'expired'
//                                         ? 'Includes offers that have passed their validity.'
//                                         : 'Keep track of these metrics regularly.'}
//                                 </div>
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                 ))}
//             </Row>

//             <Row className="mt-4">
//                 <Col md={3}>
//                     <Card>
//                         <Card.Body>
//                             <Card.Title>Offer Status Breakdown</Card.Title>
//                             <PieChart width={300} height={300}>
//                                 <Pie
//                                     data={offerStatusData}
//                                     dataKey="value"
//                                     nameKey="name"
//                                     cx="50%"
//                                     cy="50%"
//                                     outerRadius={100}
//                                     label
//                                 >
//                                     {offerStatusData.map((entry, index) => (
//                                         <Cell key={`cell-${index}`} fill={entry.color} />
//                                     ))}
//                                 </Pie>
//                                 <Tooltip />
//                             </PieChart>
//                         </Card.Body>
//                     </Card>
//                 </Col>

//                 <Col md={9}>
//                     <Card>
//                         <Card.Body>
//                             <Card.Title>Current Offers</Card.Title>
//                             <OfferTable />
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>

//             {/* Additional Sections */}
//             <Row className="mt-4">
//                 <Col md={9}>
//                     <Card>
//                         <Card.Body>
//                             <Card.Title>User Roles</Card.Title>
//                             <UserRoleTable />
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card>
//                         <Card.Body>
//                             {/* <Card.Title>User Role Distribution</Card.Title> */}
//                             <UserRoleCharts />
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>

//             <Row className="mt-4">
//                 <Col md={12}>
//                     <Card className='mb-4'>
//                         <Card.Body>

//                             <ApprovalRequestsTable />
//                         </Card.Body>
//                     </Card>
//                 </Col>

//                 <Col md={3}>
//                     <Card>
//                         <Card.Body>
//                             <ApprovalTrackingChart/>
//                         </Card.Body>
//                     </Card>
//                 </Col>

//                 <Col md={9}>
//                     <Card>
//                         <Card.Body>
//                             <ApprovalTracking />
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>
//         </Container>
//     );
// };

// export default Dashboard;



// import React, { useEffect, useState } from 'react';
// import { Container, Row, Col, Card } from 'react-bootstrap';
// import {
//     PieChart,
//     Pie,
//     Cell,
//     Tooltip,
//     ResponsiveContainer,
// } from 'recharts';
// import axios from 'axios';
// import './Dashboard.css';
// import OfferTable from '../../components/tables/OfferTable/OfferTable';
// import UserRoleTable from '../../components/tables/UserRoleTable/UserRoleTable';
// import ApprovalRequestsTable from '../UserRolesApproval/UserApproval/ApprovalRequests';
// import ApprovalTracking from '../UserRolesApproval/UserApproval/ApprovalTracking';
// import UserRoleCharts from '../../components/charts/UserRoleCharts/UserRoleCharts';
// import ApprovalTrackingChart from '../../components/charts/ApprovalTrackingChart/ApprovalTrackingChart';
// import OfferBarChart from '../../components/charts/OfferBarChart/OfferBarChart'; // Newly created component for Offer Count visualization

// const Dashboard = () => {
//     const [offerCounts, setOfferCounts] = useState({
//         total: 0,
//         active: 0,
//         pending: 0,
//         rejected: 0,
//         expired: 0,
//         approved: 0,
//     });

//     const [offerStatusData, setOfferStatusData] = useState([]);

//     useEffect(() => {
//         const fetchOfferData = async () => {
//             try {
//                 const response = await axios.get('https://beta.api.nhcholding.com/api/offers', {
//                     headers: {
//                         Authorization: `Bearer ${localStorage.getItem('token')}`,
//                     },
//                 });

//                 if (response.data && Array.isArray(response.data.data)) {
//                     const offers = response.data.data;

//                     // Calculate counts
//                     const total = offers.length;
//                     const active = offers.filter((offer) => offer.status === 'approved').length;
//                     const pending = offers.filter((offer) => offer.status === 'pending').length;
//                     const rejected = offers.filter((offer) => offer.status === 'rejected_for_edit').length;
//                     const expired = offers.filter((offer) => new Date(offer.offer_end_date) < new Date()).length;
//                     const approved = offers.filter((offer) => offer.status === 'approved').length;

//                     // Update state
//                     setOfferCounts({ total, active, pending, rejected, expired, approved });

//                     // Prepare data for Offer Status Breakdown
//                     setOfferStatusData([
//                         { name: 'Approved', value: approved, color: '#28a745' },
//                         { name: 'Pending', value: pending, color: '#ffc107' },
//                         { name: 'Rejected', value: rejected, color: '#dc3545' },
//                     ]);
//                 } else {
//                     console.error('Unexpected response format:', response.data);
//                 }
//             } catch (error) {
//                 console.error('Error fetching offers:', error);
//             }
//         };

//         fetchOfferData();
//     }, []);

//     return (
//         <Container fluid className="dashboard-page">
//             <div className="user-title">
//                 <h3>Good Day, Sultan Siddiqui</h3>
//             </div>

//             {/* Offer Management */}
//             <Row className="mt-4">
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Total Offers</Card.Title>
//                             <h3>{offerCounts.total}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Active Offers</Card.Title>
//                             <h3>{offerCounts.active}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Pending Offers</Card.Title>
//                             <h3>{offerCounts.pending}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Rejected Offers</Card.Title>
//                             <h3>{offerCounts.rejected}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>

//             <Row className="mt-4">
//                 <Col md={4}>
//                     <Card className='mb-4'>
//                         <Card.Body>
//                             <Card.Title>Offer Status Breakdown</Card.Title>
//                             <ResponsiveContainer width="100%" height={300}>
//                                 <PieChart>
//                                     <Pie
//                                         data={offerStatusData}
//                                         dataKey="value"
//                                         nameKey="name"
//                                         cx="50%"
//                                         cy="50%"
//                                         outerRadius={100}
//                                         label
//                                     >
//                                         {offerStatusData.map((entry, index) => (
//                                             <Cell key={`cell-${index}`} fill={entry.color} />
//                                         ))}
//                                     </Pie>
//                                     <Tooltip />
//                                 </PieChart>
//                             </ResponsiveContainer>
//                         </Card.Body>
//                     </Card>

//                     <Card>
//                         <Card.Body>
//                             <Card.Title>Offer Count Overview</Card.Title>
//                             <OfferBarChart offerCounts={offerCounts}/>
//                         </Card.Body>
//                     </Card>
//                 </Col>


//                 <Col md={8}>
//                 <Card>
//                          <Card.Body>
//                              <Card.Title>Current Offers</Card.Title>
//                             <OfferTable />
//                        </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>

//             <Row className="mt-4">
//                 <Col md={9}>
//                     <Card>
//                         <Card.Body>
//                             <Card.Title>User Roles</Card.Title>
//                             <UserRoleTable />
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card>
//                         <Card.Body>
//                             <UserRoleCharts />
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>

//             <Row className="mt-4">
//                 <Col md={12}>
//                     <Card>
//                         <Card.Body>
//                             <ApprovalRequestsTable />
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card>
//                         <Card.Body>
//                             <ApprovalTrackingChart />
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={9}>
//                     <Card>
//                         <Card.Body>
//                             <ApprovalTracking />
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>
//         </Container>
//     );
// };

// export default Dashboard;


// import React, { useEffect, useState } from 'react';
// import { Container, Row, Col, Card } from 'react-bootstrap';
// import {
//     PieChart,
//     Pie,
//     Cell,
//     Tooltip,
//     ResponsiveContainer,
// } from 'recharts';
// import axios from 'axios';
// import './Dashboard.css';
// import OfferTable from '../../components/tables/OfferTable/OfferTable';
// import UserRoleTable from '../../components/tables/UserRoleTable/UserRoleTable';
// import ApprovalRequestsTable from '../UserRolesApproval/UserApproval/ApprovalRequests';
// import ApprovalTracking from '../UserRolesApproval/UserApproval/ApprovalTracking';
// import UserRoleCharts from '../../components/charts/UserRoleCharts/UserRoleCharts';
// import ApprovalTrackingChart from '../../components/charts/ApprovalTrackingChart/ApprovalTrackingChart';
// import OfferBarChart from '../../components/charts/OfferBarChart/OfferBarChart';

// const Dashboard = () => {
//     const [offerCounts, setOfferCounts] = useState({
//         total: 0,
//         active: 0,
//         pending: 0,
//         rejected: 0,
//         expired: 0,
//         approved: 0,
//     });

//     const [offerStatusData, setOfferStatusData] = useState([]);
//     const [userRole, setUserRole] = useState(null);

//     useEffect(() => {
//         // Fetch user role from local storage or API
//         const fetchUserRole = () => {
//             const role = localStorage.getItem('userRole'); // Assuming role is stored in localStorage
//             setUserRole(role);
//         };

//         const fetchOfferData = async () => {
//             try {
//                 const response = await axios.get('https://beta.api.nhcholding.com/api/offers', {
//                     headers: {
//                         Authorization: `Bearer ${localStorage.getItem('token')}`,
//                     },
//                 });

//                 if (response.data && Array.isArray(response.data.data)) {
//                     const offers = response.data.data;

//                     // Calculate counts
//                     const total = offers.length;
//                     const active = offers.filter((offer) => offer.status === 'approved').length;
//                     const pending = offers.filter((offer) => offer.status === 'pending').length;
//                     const rejected = offers.filter((offer) => offer.status === 'rejected_for_edit').length;
//                     const expired = offers.filter((offer) => new Date(offer.offer_end_date) < new Date()).length;
//                     const approved = offers.filter((offer) => offer.status === 'approved').length;

//                     // Update state
//                     setOfferCounts({ total, active, pending, rejected, expired, approved });

//                     // Prepare data for Offer Status Breakdown
//                     setOfferStatusData([
//                         { name: 'Approved', value: approved, color: '#28a745' },
//                         { name: 'Pending', value: pending, color: '#ffc107' },
//                         { name: 'Rejected', value: rejected, color: '#dc3545' },
//                     ]);
//                 } else {
//                     console.error('Unexpected response format:', response.data);
//                 }
//             } catch (error) {
//                 console.error('Error fetching offers:', error);
//             }
//         };

//         fetchUserRole();
//         fetchOfferData();
//     }, []);

//     return (
//         <Container fluid className="dashboard-page">
//             <div className="user-title">
//                 <h3>Good Day, Sultan Siddiqui</h3>
//             </div>

//             {/* Offer Management */}
//             <Row className="mt-4">
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Total Offers</Card.Title>
//                             <h3>{offerCounts.total}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Active Offers</Card.Title>
//                             <h3>{offerCounts.active}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Pending Offers</Card.Title>
//                             <h3>{offerCounts.pending}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Rejected Offers</Card.Title>
//                             <h3>{offerCounts.rejected}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>

//             <Row className="mt-4">
//                 <Col md={4}>
//                     <Card className="mb-4">
//                         <Card.Body>
//                             <Card.Title>Offer Status Breakdown</Card.Title>
//                             <ResponsiveContainer width="100%" height={300}>
//                                 <PieChart>
//                                     <Pie
//                                         data={offerStatusData}
//                                         dataKey="value"
//                                         nameKey="name"
//                                         cx="50%"
//                                         cy="50%"
//                                         outerRadius={100}
//                                         label
//                                     >
//                                         {offerStatusData.map((entry, index) => (
//                                             <Cell key={`cell-${index}`} fill={entry.color} />
//                                         ))}
//                                     </Pie>
//                                     <Tooltip />
//                                 </PieChart>
//                             </ResponsiveContainer>
//                         </Card.Body>
//                     </Card>

//                     <Card>
//                         <Card.Body>
//                             <Card.Title>Offer Count Overview</Card.Title>
//                             <OfferBarChart offerCounts={offerCounts} />
//                         </Card.Body>
//                     </Card>
//                 </Col>

//                 <Col md={8}>
//                     <Card>
//                         <Card.Body>
//                             <Card.Title>Current Offers</Card.Title>
//                             <OfferTable />
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>

//             {/* Conditional Rendering Based on Role */}
//             {(userRole !== 'marketing-user') && (
//                 <Row className="mt-4">
//                     <Col md={9}>
//                         <Card>
//                             <Card.Body>
//                                 <Card.Title>User Roles</Card.Title>
//                                 <UserRoleTable />
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                     {userRole !== 'marketing-manager' && (
//                         <Col md={3}>
//                             <Card>
//                                 <Card.Body>
//                                     <UserRoleCharts />
//                                 </Card.Body>
//                             </Card>
//                         </Col>
//                     )}
//                 </Row>
//             )}

//             <Row className="mt-4">
//                 {(userRole !== 'marketing-user') && (
//                     <Col md={12}>
//                         <Card>
//                             <Card.Body>
//                                 <ApprovalRequestsTable />
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                 )}
//                 <Col md={3}>
//                     <Card>
//                         <Card.Body>
//                             <ApprovalTrackingChart />
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={9}>
//                     <Card>
//                         <Card.Body>
//                             <ApprovalTracking />
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>
//         </Container>
//     );
// };

// export default Dashboard;


// import React, { useEffect, useState } from 'react';
// import { Container, Row, Col, Card } from 'react-bootstrap';
// import {
//     PieChart,
//     Pie,
//     Cell,
//     Tooltip,
//     ResponsiveContainer,
// } from 'recharts';
// import axios from 'axios';
// import './Dashboard.css';
// import OfferTable from '../../components/tables/OfferTable/OfferTable';
// import UserRoleTable from '../../components/tables/UserRoleTable/UserRoleTable';
// import ApprovalRequestsTable from '../UserRolesApproval/UserApproval/ApprovalRequests';
// import ApprovalTracking from '../UserRolesApproval/UserApproval/ApprovalTracking';
// import UserRoleCharts from '../../components/charts/UserRoleCharts/UserRoleCharts';
// import ApprovalTrackingChart from '../../components/charts/ApprovalTrackingChart/ApprovalTrackingChart';
// import OfferBarChart from '../../components/charts/OfferBarChart/OfferBarChart';

// const Dashboard = () => {
//     const [offerCounts, setOfferCounts] = useState({
//         total: 0,
//         active: 0,
//         pending: 0,
//         rejected: 0,
//         expired: 0,
//         approved: 0,
//     });
//     const [offerStatusData, setOfferStatusData] = useState([]);
//     const [userRoles, setUserRoles] = useState([]);
//     const [userName, setUserName] = useState('');

//     useEffect(() => {
//         // Fetch user roles from localStorage or API
//         const roles = JSON.parse(localStorage.getItem('userRoles')) || [];
//         setUserRoles(roles);

//         const fetchOfferData = async () => {
//             try {
//                 const response = await axios.get('https://beta.api.nhcholding.com/api/offers', {
//                     headers: {
//                         Authorization: `Bearer ${localStorage.getItem('token')}`,
//                     },
//                 });

//                 if (response.data && Array.isArray(response.data.data)) {
//                     const offers = response.data.data;

//                     // Calculate counts
//                     const total = offers.length;
//                     const active = offers.filter((offer) => offer.status === 'approved').length;
//                     const pending = offers.filter((offer) => offer.status === 'pending').length;
//                     const rejected = offers.filter((offer) => offer.status === 'rejected_for_edit').length;
//                     const expired = offers.filter((offer) => new Date(offer.offer_end_date) < new Date()).length;
//                     const approved = offers.filter((offer) => offer.status === 'approved').length;

//                     // Update state
//                     setOfferCounts({ total, active, pending, rejected, expired, approved });

//                     // Prepare data for Offer Status Breakdown
//                     setOfferStatusData([
//                         { name: 'Approved', value: approved, color: '#28a745' },
//                         { name: 'Pending', value: pending, color: '#ffc107' },
//                         { name: 'Rejected', value: rejected, color: '#dc3545' },
//                     ]);
//                 } else {
//                     console.error('Unexpected response format:', response.data);
//                 }
//             } catch (error) {
//                 console.error('Error fetching offers:', error);
//             }
//         };

//         fetchOfferData();

//         const storedName = localStorage.getItem('userName') || 'Guest';
//         setUserName(storedName);
//     }, []);

//     const hasRole = (role) => userRoles.includes(role);

//     return (
//         <Container fluid className="dashboard-page">
//             <div className="user-title">
//             <h3>Good Day, {userName}</h3>
//             </div>

//             {/* Offer Management */}
//             <Row className="mt-4">
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Total Offers</Card.Title>
//                             <h3>{offerCounts.total}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Active Offers</Card.Title>
//                             <h3>{offerCounts.active}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Pending Offers</Card.Title>
//                             <h3>{offerCounts.pending}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//                 <Col md={3}>
//                     <Card className="mb-3">
//                         <Card.Body>
//                             <Card.Title>Rejected Offers</Card.Title>
//                             <h3>{offerCounts.rejected}</h3>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>

//             <Row className="mt-4">
//                 <Col md={4}>
//                     <Card className="mb-4">
//                         <Card.Body>
//                             <Card.Title>Offer Status Breakdown</Card.Title>
//                             <ResponsiveContainer width="100%" height={300}>
//                                 <PieChart>
//                                     <Pie
//                                         data={offerStatusData}
//                                         dataKey="value"
//                                         nameKey="name"
//                                         cx="50%"
//                                         cy="50%"
//                                         outerRadius={100}
//                                         label
//                                     >
//                                         {offerStatusData.map((entry, index) => (
//                                             <Cell key={`cell-${index}`} fill={entry.color} />
//                                         ))}
//                                     </Pie>
//                                     <Tooltip />
//                                 </PieChart>
//                             </ResponsiveContainer>
//                         </Card.Body>
//                     </Card>

//                     <Card>
//                         <Card.Body>
//                             <Card.Title>Offer Count Overview</Card.Title>
//                             <OfferBarChart offerCounts={offerCounts} />
//                         </Card.Body>
//                     </Card>
//                 </Col>

//                 <Col md={8}>
//                     <Card>
//                         <Card.Body>
//                             <Card.Title>Current Offers</Card.Title>
//                             <OfferTable />
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>

//             {/* User Role Table and Chart */}
//             {!hasRole('mr-user') && (
//                 <Row className="mt-4">
//                     <Col md={hasRole('mr-manager') ? 12 : 9}>
//                         <Card>
//                             <Card.Body>
//                                 <Card.Title>User Roles</Card.Title>
//                                 <UserRoleTable />
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                     {!hasRole('mr-manager') && (
//                         <Col md={3}>
//                             <Card>
//                                 <Card.Body>
//                                     <UserRoleCharts />
//                                 </Card.Body>
//                             </Card>
//                         </Col>
//                     )}
//                 </Row>
//             )}

//             {/* Approval Tracking */}
//             <Row className="mt-4">
//                 {!hasRole('mr-user') && (
//                     <Col md={12}>
//                         <Card>
//                             <Card.Body>
//                                 <ApprovalRequestsTable />
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                 )}
//                 {!hasRole('mr-user') && (
//                     <Col md={3}>
//                         <Card>
//                             <Card.Body>
//                                 <ApprovalTrackingChart />
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                 )}

//                 <Col md={hasRole('mr-user') ? 12 : 9}>
//                     <Card>
//                         <Card.Body>
//                             <ApprovalTracking />
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>
//         </Container>
//     );
// };

// export default Dashboard;



import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import {
    PieChart,
    Pie,
    Cell,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import axios from 'axios';
import './Dashboard.css';
import OfferTable from '../../components/tables/OfferTable/OfferTable';
import ApprovalRequestsTable from '../UserRolesApproval/UserApproval/ApprovalRequests';
import ApprovalTracking from '../UserRolesApproval/UserApproval/ApprovalTracking';
import UserRoleCharts from '../../components/charts/UserRoleCharts/UserRoleCharts';
import ApprovalTrackingChart from '../../components/charts/ApprovalTrackingChart/ApprovalTrackingChart';
import OfferBarChart from '../../components/charts/OfferBarChart/OfferBarChart';
import UserRoleTable from '../../components/tables/UserRoleTable/UserRoleTable'; // Ensure it's available for other roles

const Dashboard = () => {
    const [offerCounts, setOfferCounts] = useState({
        total: 0,
        active: 0,
        pending: 0,
        rejected: 0,
        expired: 0,
        approved: 0,
    });
    const [offerStatusData, setOfferStatusData] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [userName, setUserName] = useState('');

    useEffect(() => {
        // Fetch user roles from localStorage or API
        const roles = JSON.parse(localStorage.getItem('userRoles')) || [];
        setUserRoles(roles);

        const fetchOfferData = async () => {
            try {
                const response = await axios.get('https://beta.api.nhcholding.com/api/offers', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                if (response.data && Array.isArray(response.data.data)) {
                    const offers = response.data.data;

                    // Calculate counts
                    const total = offers.length;
                    const active = offers.filter((offer) => offer.status === 'approved').length;
                    const pending = offers.filter((offer) => offer.status === 'pending').length;
                    const rejected = offers.filter((offer) => offer.status === 'rejected_for_edit').length;
                    const expired = offers.filter((offer) => new Date(offer.offer_end_date) < new Date()).length;
                    const approved = offers.filter((offer) => offer.status === 'approved').length;

                    // Update state
                    setOfferCounts({ total, active, pending, rejected, expired, approved });

                    // Prepare data for Offer Status Breakdown
                    setOfferStatusData([
                        { name: 'Approved', value: approved, color: '#28a745' },
                        { name: 'Pending', value: pending, color: '#ffc107' },
                        { name: 'Rejected', value: rejected, color: '#dc3545' },
                    ]);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching offers:', error);
            }
        };

        fetchOfferData();

        const storedName = localStorage.getItem('userName') || 'Guest';
        setUserName(storedName);
    }, []);

    const hasRole = (role) => userRoles.includes(role);

    return (
        <Container fluid className="dashboard-page">
            <div className="user-title">
                <h3>Good Day, {userName}</h3>
            </div>

            {/* Offer Management */}
            <Row className="mt-4">
                <Col md={3}>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Total Offers</Card.Title>
                            <h3>{offerCounts.total}</h3>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Active Offers</Card.Title>
                            <h3>{offerCounts.active}</h3>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Pending Offers</Card.Title>
                            <h3>{offerCounts.pending}</h3>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Rejected Offers</Card.Title>
                            <h3>{offerCounts.rejected}</h3>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col md={4}>
                    <Card className="mb-4">
                        <Card.Body>
                            <Card.Title>Offer Status Breakdown</Card.Title>
                            <ResponsiveContainer width="100%" height={300}>
                                <PieChart>
                                    <Pie
                                        data={offerStatusData}
                                        dataKey="value"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={100}
                                        label
                                    >
                                        {offerStatusData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                </PieChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>

                    <Card>
                        <Card.Body>
                            <Card.Title>Offer Count Overview</Card.Title>
                            <OfferBarChart offerCounts={offerCounts} />
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={8}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Current Offers</Card.Title>
                            <OfferTable />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* User Role Table and Chart */}
            {/* {!hasRole('mr-user') && (
                <Row className="mt-4">
                    <Col md={hasRole('mr-manager') ? 12 : 9}>
                        <Card>
                            <Card.Body>
                                <Card.Title>User Roles</Card.Title>
                                <UserRoleTable />
                            </Card.Body>
                        </Card>
                    </Col>
                    {!hasRole('mr-manager' && 'mr-user') && (
                        <Col md={3}>
                            <Card>
                                <Card.Body>
                                    <UserRoleCharts />
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Row>
            )} */}

            {/* Approval Tracking */}
            <Row className="mt-4">
                {!hasRole('mr-user') && (
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <ApprovalRequestsTable />
                            </Card.Body>
                        </Card>
                    </Col>
                )}
                {!hasRole('mr-user') && (
                    <Col md={3}>
                        <Card>
                            <Card.Body>
                                <ApprovalTrackingChart />
                            </Card.Body>
                        </Card>
                    </Col>
                )}

                <Col md={hasRole('mr-user') ? 12 : 9}>
                    <Card>
                        <Card.Body>
                            <ApprovalTracking />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Dashboard;
