import React from 'react';
import { Table, Button } from 'react-bootstrap';

const BlogManagementTable = () => {
    const blogs = [
        {
            id: 1,
            featuredImage: 'https://www.suzukinajeebauto.com/static/media/news-11-1.38cf1452923cfa20bca1.jpg',
            title: "React Basics",
            author: "John Doe",
            status: "Published",
            publishedDate: "2024-10-28",
        },
        {
            id: 2,
            featuredImage: 'https://www.suzukinajeebauto.com/static/media/news-11-1.38cf1452923cfa20bca1.jpg',
            title: "Advanced CSS",
            author: "Jane Smith",
            status: "Draft",
            publishedDate: "2024-10-22",
        },
    ];

    const handleEdit = (id) => {
        console.log("Edit Blog ID:", id);
        // Navigate to the edit page for the specific blog
    };

    const handleDelete = (id) => {
        console.log("Delete Blog ID:", id);
        // Implement deletion logic or confirmation dialog here
    };

    return (
        <Table responsive striped bordered hover className='text-center'>
            <thead>
                <tr>
                    <th>Sr. No.</th>
                    <th>Featured Image</th>
                    <th>Title</th>
                    <th>Author</th>
                    <th>Status</th>
                    <th>Published Date</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {blogs.map((blog, index) => (
                    <tr key={blog.id}>
                        <td>{index + 1}</td>
                        <td>
                            <img src={blog.featuredImage} alt="Featured Image" width={120} />
                        </td>
                        <td>{blog.title}</td>
                        <td>{blog.author}</td>
                        <td>{blog.status}</td>
                        <td>{blog.publishedDate}</td>
                        <td>
                            <Button variant="warning" size="sm" onClick={() => handleEdit(blog.id)}>Edit</Button>{' '}

                            <Button variant="danger" size="sm" onClick={() => handleDelete(blog.id)}>Delete</Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default BlogManagementTable;


