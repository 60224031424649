import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import VehicleColorForm from '../../../../components/forms/VehicleColorForm/VehicleColorForm';
import './AddNewColor.css';

const AddNewColor = () => {
    return (
        <Container fluid>
            <div className="add-new-color-page mt-5 mb-5">
                <h3>Add New Vehicle Color</h3>
            </div>
            <Row>
                <Col md={4}>
                <h5>Vehicle Color Details</h5>
                <p>Fill the Vehcle Color details here</p>
                </Col>
                <Col md={8}>
                    <VehicleColorForm />
                </Col>
            </Row>

        </Container>
    );
};

export default AddNewColor;
