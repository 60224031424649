import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import './SparePartsPricingTable.css';

// Dummy data for spare parts pricing
const sparePartsPricingData = [
    {
        id: '1',
        vehicleName: 'Dzire',
        variants: 'GL, GLX',
        status: 'Active',
    },
    {
        id: '2',
        vehicleName: 'Baleno',
        variants: 'Alpha, Zeta',
        status: 'Inactive',
    },
    {
        id: '3',
        vehicleName: 'Ciaz',
        variants: 'VXi, ZXi',
        status: 'Active',
    },
];

const SparePartsPricingTable = () => {
    const handleDelete = (id) => {
        console.log(`Spare Parts Pricing with ID ${id} deleted.`);
        // Implement your delete logic here
    };

    return (
        <div className="table-responsive">
            <Table responsive striped bordered hover className='text-center'>
                <thead>
                    <tr>
                        <th>Sr. No</th>
                        <th>Vehicle Name</th>
                        <th>Spare Parts Pricing Variants</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sparePartsPricingData.length === 0 ? (
                        <tr>
                            <td colSpan="5" className="text-center">No spare parts pricing available</td>
                        </tr>
                    ) : (
                        sparePartsPricingData.map((data, index) => (
                            <tr key={data.id}>
                                <td>{index + 1}</td>
                                <td>{data.vehicleName}</td>
                                <td>{data.variants}</td>
                                <td>{data.status}</td>
                                <td>
                                    <Link to={`/spare-parts-management/spare-parts-pricing/edit/${data.vehicleName}`} className="btn btn-warning btn-sm">
                                        Edit
                                    </Link>
                                    <Button variant="danger" className="btn-sm ms-2" onClick={() => handleDelete(data.id)}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default SparePartsPricingTable;
