import React, { useState } from 'react';
import LocationForm from '../../../../components/forms/LocationForm/LocationForm';
import { Row, Col, Container } from 'react-bootstrap';

const AddNewLocation = () => {
    const [category, setCategory] = useState('');
    const [showroomWorkingHours, setShowroomWorkingHours] = useState([
        { day: 'Sunday to Thursday', timeRanges: [{ from: '', to: '' }] },
        { day: 'Saturday', timeRanges: [{ from: '', to: '' }] },
        { day: 'Friday', timeRanges: [{ from: '', to: '', closed: false }] },
    ]);
    const [otherWorkingHours, setOtherWorkingHours] = useState([
        { day: 'Saturday to Thursday', timeRanges: [{ from: '', to: '' }] },
        { day: 'Break', timeRanges: [{ from: '', to: '' }] },
        { day: 'Friday', timeRanges: [{ from: '', to: '', closed: false }] },
    ]);

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    };

    return (
        <Container fluid>
                 <div className="add-new-location-page mt-5 mb-5">
            <h3>Add New Location</h3>
        </div>

            <Row>
                <Col md={4}>
                    <h5>Add the full details of Location</h5>
                    <p>Please add all the location details here.</p>
                </Col>

                <Col md={8}>
                <LocationForm 
                category={category}
                handleCategoryChange={handleCategoryChange}
                showroomWorkingHours={showroomWorkingHours}
                setShowroomWorkingHours={setShowroomWorkingHours}
                otherWorkingHours={otherWorkingHours}
                setOtherWorkingHours={setOtherWorkingHours}
            />
                </Col>
            </Row>
        </Container>
       
            
        
    );
};

export default AddNewLocation;
