import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import OfferTable from '../../components/tables/OfferTable/OfferTable'; // Import the table component
import { Container } from 'react-bootstrap';

const Offer = ({ offers }) => {
    const navigate = useNavigate(); // Initialize useNavigate

    return (
        <Container fluid>
            <div className="offer-page">
                <div className="page-header d-flex justify-content-between align-items-center mt-5 mb-5">
                    <h3>Offer Management</h3>
                    <button className="btn btn-primary" onClick={() => navigate('/offers/add-new')}>
                        Add New Offer
                    </button>
                </div>
                <OfferTable offers={offers} /> {/* Pass offers as a prop */}
            </div>
        </Container>
    );
};

export default Offer;
