import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';

const OfferBarChart = ({ offerCounts }) => {
    // Prepare data for the bar chart
    const chartData = [
        { name: 'Total', value: offerCounts.total, color: '#007bff' },
        { name: 'Active', value: offerCounts.active, color: '#28a745' },
        { name: 'Pending', value: offerCounts.pending, color: '#ffc107' },
        { name: 'Rejected', value: offerCounts.rejected, color: '#dc3545' },
        { name: 'Expired', value: offerCounts.expired, color: '#6c757d' },
    ];

    return (
        <div className="offer-bar-chart">
            <h4 className="text-center">Offer Status Overview</h4>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar
                        dataKey="value"
                        fill={(entry) => entry.color}
                        barSize={50}
                    >
                        {chartData.map((entry, index) => (
                            <Bar key={`bar-${index}`} fill={entry.color} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default OfferBarChart;
