import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import UTMTable from '../../components/tables/UTMTable/UTMTable';

const UTMBuilder = () => {
    const navigate = useNavigate();

    const handleCreateUTM = () => {
        navigate('/utm-builder/add-new'); // Navigate to AddNewUTM page
    };

    return (
        <Container fluid>
            <div className="utm-builder-page">
                <div className="page-header d-flex justify-content-between align-items-center mt-5 mb-5">
                    <h3>UTM Builder</h3>
                    <Button variant="primary" onClick={handleCreateUTM}>
                        Create UTM
                    </Button>
                </div>
                <UTMTable />
            </div>
        </Container>
    );
};

export default UTMBuilder;
