import React from 'react';
import OfferForm from '../../../components/forms/OfferForm/OfferForm';
import { Col, Container, Row } from 'react-bootstrap';

const AddNewOffer = () => {
    return (
        <Container fluid>
            <div className="add-new-offer-page mt-5 mb-5">
            <h3  className='mb-4'>Add New Offer</h3>
                <Row>
                    <Col md={4}>
                        <h5>Offer Details</h5>
                        <p>Add the complete offer details here.</p>
                    </Col>

                    <Col md={8}>
                        <OfferForm />
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default AddNewOffer;
