import React, { useState } from 'react';
import { Table, Button, Form, Row, Col, Pagination } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UTMTable = () => {
    const [utmData, setUtmData] = useState([
        {
            id: 1,
            destinationUrl: 'https://example.com',
            utmSource: 'google',
            utmMedium: 'cpc',
            utmCampaign: 'summer_sale',
            utmTerm: 'shoes',
            utmContent: 'ad_banner',
        },
        {
            id: 2,
            destinationUrl: 'https://example.com',
            utmSource: 'facebook',
            utmMedium: 'social',
            utmCampaign: 'new_launch',
            utmTerm: 'clothing',
            utmContent: 'video_ad',
        },
    ]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);

    const handleEdit = (id) => {
        toast.info(`Edit UTM ID: ${id}`);
    };

    const handleDelete = (id) => {
        const updatedData = utmData.filter((item) => item.id !== id);
        setUtmData(updatedData);
        toast.success('UTM entry deleted successfully!');
    };

    const handleCopyLink = (utmLink) => {
        console.log('Attempting to copy:', utmLink); // Debugging log
        if (navigator.clipboard) {
            navigator.clipboard
                .writeText(utmLink)
                .then(() => {
                    console.log('Link copied successfully!'); // Debugging log
                    toast.success('UTM link copied to clipboard!', {
                        position: 'top-right',
                    });
                })
                .catch((error) => {
                    console.error('Error copying link:', error);
                    toast.error('Failed to copy the link. Please try again.');
                });
        } else {
            console.warn('Clipboard API not supported. Falling back to prompt.');
            const successful = window.prompt('Copy this link:', utmLink);
            if (successful) {
                toast.success('UTM link copied successfully!', {
                    position: 'top-right',
                });
            }
        }
    };

    const generateUTMLink = (item) => {
        const url = new URL(item.destinationUrl);
        url.searchParams.append('utm_source', item.utmSource);
        url.searchParams.append('utm_medium', item.utmMedium);
        url.searchParams.append('utm_campaign', item.utmCampaign);
        if (item.utmTerm) url.searchParams.append('utm_term', item.utmTerm);
        if (item.utmContent) url.searchParams.append('utm_content', item.utmContent);

        return url.toString();
    };

    const filteredData = utmData.filter((item) => {
        return (
            item.destinationUrl.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.utmSource.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.utmCampaign.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="utm-table">
            <ToastContainer />
            <Form className="mb-3">
                <Row>
                    <Col md={6}>
                        <Form.Control
                            type="search"
                            placeholder="Search by URL, Source, or Campaign"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="input-brand-shadow"
                        />
                    </Col>
                    <Col md={6} className="text-end">
                        <Button
                            variant="secondary"
                            onClick={() => setSearchTerm('')}
                        >
                            Clear Filters
                        </Button>
                    </Col>
                </Row>
            </Form>

            <div className="table-brand-shadow">
                <Table responsive striped bordered hover className="text-center">
                    <thead className="brand-table-head">
                        <tr>
                            <th>Sr. No</th>
                            <th>Destination URL</th>
                            <th>UTM Source</th>
                            <th>UTM Medium</th>
                            <th>UTM Campaign</th>
                            <th>UTM Term</th>
                            <th>UTM Content</th>
                            <th>Generated UTM Link</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentData.length === 0 ? (
                            <tr>
                                <td colSpan="9" className="text-center">No UTM entries available</td>
                            </tr>
                        ) : (
                            currentData.map((item, index) => {
                                const utmLink = generateUTMLink(item);
                                return (
                                    <tr key={item.id}>
                                        <td>{indexOfFirstItem + index + 1}</td>
                                        <td>{item.destinationUrl}</td>
                                        <td>{item.utmSource}</td>
                                        <td>{item.utmMedium}</td>
                                        <td>{item.utmCampaign}</td>
                                        <td>{item.utmTerm || 'N/A'}</td>
                                        <td>{item.utmContent || 'N/A'}</td>
                                        <td>
                                            <div className="d-flex flex-column align-items-center">
                                                <span className="text-truncate" style={{ maxWidth: '200px' }}>
                                                    {utmLink}
                                                </span>
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    onClick={() => handleCopyLink(utmLink)}
                                                    className="mt-2"
                                                >
                                                    Copy
                                                </Button>
                                            </div>
                                        </td>
                                        <td>
                                            <Button
                                                variant="warning"
                                                size="sm"
                                                onClick={() => handleEdit(item.id)}
                                            >
                                                Edit
                                            </Button>{' '}
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() => handleDelete(item.id)}
                                            >
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </Table>
            </div>

            <Pagination className="justify-content-end">
                {[...Array(totalPages)].map((_, i) => (
                    <Pagination.Item
                        key={i + 1}
                        active={i + 1 === currentPage}
                        onClick={() => handlePageChange(i + 1)}
                    >
                        {i + 1}
                    </Pagination.Item>
                ))}
            </Pagination>
        </div>
    );
};

export default UTMTable;

