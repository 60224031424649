import React, { useState, useEffect } from 'react';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';

// Vehicle and variant options
const vehicleOptions = {
    'Dzire': ['GL', 'GLX'],
    'Baleno': ['Alpha', 'Zeta'],
    'Ciaz': ['VXi', 'ZXi'],
};

// Validation schema using Yup for primary form fields
const validationSchema = Yup.object({
    vehicleName: Yup.string().required('Vehicle Name is required'),
});

const SparePartsPricingForm = ({ initialData = {}, isEdit = false }) => {
    const [variants, setVariants] = useState([]);

    const formik = useFormik({
        initialValues: {
            vehicleName: initialData.vehicleName || '',
            variantsData: initialData.variantsData || {},
        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            const variantErrors = validateVariantsData(values.variantsData);

            if (variantErrors.length === 0) {
                toast.success(
                    isEdit
                        ? 'Spare Parts Pricing updated successfully!'
                        : 'Spare Parts Pricing added successfully!',
                    { position: "top-right" }
                );
                resetForm();
                setVariants([]); // Clear variants on successful submission
            } else {
                variantErrors.forEach((error) => toast.error(error, { position: "top-right" }));
            }
        },
    });

    // Populate variants based on vehicle selection
    useEffect(() => {
        const selectedVehicle = formik.values.vehicleName;
        const selectedVariants = selectedVehicle ? vehicleOptions[selectedVehicle] || [] : [];
        setVariants(selectedVariants);

        const initialVariantsData = {};
        selectedVariants.forEach((variant) => {
            if (!formik.values.variantsData[variant]) {
                initialVariantsData[variant] = [{ description: '', price: '', arabicDescription: '' }];
            } else {
                initialVariantsData[variant] = formik.values.variantsData[variant];
            }
        });
        formik.setFieldValue('variantsData', initialVariantsData);
    }, [formik.values.vehicleName]);

    const handleAddRow = (variant) => {
        const updatedVariants = [...formik.values.variantsData[variant], { description: '', price: '', arabicDescription: '' }];
        formik.setFieldValue(`variantsData.${variant}`, updatedVariants);
    };

    const handleRemoveRow = (variant, index) => {
        const updatedVariants = formik.values.variantsData[variant].filter((_, i) => i !== index);
        formik.setFieldValue(`variantsData.${variant}`, updatedVariants);
    };

    const validateVariantsData = (variantsData) => {
        const errors = [];
        const arabicPattern = /^[\u0600-\u06FF\s]+$/;
        const englishPattern = /^[A-Za-z\s]+$/;
        const decimalPattern = /^[1-9]\d*(\.\d+)?$/;

        Object.keys(variantsData).forEach((variant) => {
            variantsData[variant].forEach((row, rowIndex) => {
                if (!row.description) {
                    errors.push(`Description (En) is required for ${variant} row ${rowIndex + 1}`);
                } else if (!englishPattern.test(row.description)) {
                    errors.push(`Description (En) for ${variant} row ${rowIndex + 1} should contain only English text`);
                }
                if (!row.price) {
                    errors.push(`Pricing is required for ${variant} row ${rowIndex + 1}`);
                } else if (!decimalPattern.test(row.price) || parseFloat(row.price) < 1) {
                    errors.push(`Pricing for ${variant} row ${rowIndex + 1} should be a decimal value greater than 1`);
                }
                if (!row.arabicDescription) {
                    errors.push(`Description (Ar) is required for ${variant} row ${rowIndex + 1}`);
                } else if (!arabicPattern.test(row.arabicDescription)) {
                    errors.push(`Description (Ar) for ${variant} row ${rowIndex + 1} should contain only Arabic text`);
                }
            });
        });
        return errors;
    };

    return (
        <div className="spare-parts-pricing-form">
            <ToastContainer />
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="vehicleName">
                                <Form.Label>Vehicle Name</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="vehicleName"
                                    {...formik.getFieldProps('vehicleName')}
                                    isInvalid={formik.touched.vehicleName && !!formik.errors.vehicleName}
                                >
                                    <option value="">Select Vehicle</option>
                                    {Object.keys(vehicleOptions).map((vehicle, index) => (
                                        <option key={index} value={vehicle}>
                                            {vehicle}
                                        </option>
                                    ))}
                                </Form.Control>
                                {formik.touched.vehicleName && formik.errors.vehicleName && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.vehicleName}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    {variants.map((variant, variantIndex) => (
                        <div key={variant}>
                            <h5 className="mt-4">{variant}</h5>
                            <FieldArray name={`variantsData.${variant}`}>
                                {({ remove, push }) => (
                                    <>
                                        {formik.values.variantsData[variant]?.map((row, rowIndex) => (
                                            <Row key={rowIndex} className="mt-3">
                                                <Col md={4}>
                                                    <Form.Group controlId={`descriptionEn_${variantIndex}_${rowIndex}`}>
                                                        <Form.Label>Description (En)</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={row.description}
                                                            onChange={(e) =>
                                                                formik.setFieldValue(`variantsData.${variant}[${rowIndex}].description`, e.target.value)
                                                            }
                                                            placeholder="Enter English description"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group controlId={`pricing_${variantIndex}_${rowIndex}`}>
                                                        <Form.Label>Pricing</Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            step="0.01"
                                                            value={row.price}
                                                            onChange={(e) =>
                                                                formik.setFieldValue(`variantsData.${variant}[${rowIndex}].price`, e.target.value)
                                                            }
                                                            placeholder="Enter pricing (min 1)"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group controlId={`descriptionAr_${variantIndex}_${rowIndex}`}>
                                                        <Form.Label>Description (Ar)</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={row.arabicDescription}
                                                            onChange={(e) =>
                                                                formik.setFieldValue(`variantsData.${variant}[${rowIndex}].arabicDescription`, e.target.value)
                                                            }
                                                            placeholder="Enter Arabic description"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12} className="text-end mt-2">
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => handleRemoveRow(variant, rowIndex)}
                                                        disabled={formik.values.variantsData[variant].length === 1}
                                                    >
                                                        Remove
                                                    </Button>
                                                </Col>
                                            </Row>
                                        ))}
                                        <Button
                                            variant="secondary"
                                            onClick={() => handleAddRow(variant)}
                                            className="mt-3"
                                        >
                                            Add More Item
                                        </Button>
                                    </>
                                )}
                            </FieldArray>
                        </div>
                    ))}

                    <div className="text-end mt-4">
                        <Button type="submit" variant="primary">
                            {isEdit ? 'Update' : 'Submit'}
                        </Button>
                        {/* <Button
                            variant="secondary"
                            className="ms-2"
                            onClick={() => {
                                formik.resetForm();
                                setVariants([]);
                                toast.info('Form reset successfully', { position: "top-right" });
                            }}
                        >
                            Reset
                        </Button> */}
                    </div>
                </Form>
            </FormikProvider>
        </div>
    );
};

export default SparePartsPricingForm;
