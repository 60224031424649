import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CityForm from '../../../../components/forms/CityForm/CityForm';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'react-bootstrap';

// Dummy data for cities
const cityData = [
    { id: 'City001', cityNameEn: 'Riyadh', cityNameAr: 'الرياض' },
    { id: 'City002', cityNameEn: 'Jeddah', cityNameAr: 'جدة' },
    { id: 'City003', cityNameEn: 'Dammam', cityNameAr: 'الدمام' },
];

const EditCity = () => {
    const { id } = useParams();
    const [city, setCity] = useState(null);

    useEffect(() => {
        // Fetching city details based on id (dummy data)
        const selectedCity = cityData.find((city) => city.id === id);
        if (selectedCity) {
            setCity(selectedCity);
        } else {
            toast.error('City not found');
        }
    }, [id]);

    const handleSubmit = (values) => {
        // Simulate a successful update with toast notification
        toast.success('City updated successfully!');
        console.log('Updated city values:', values);
    };

    return (
        <Container fluid>
            <div className="edit-city-page mt-5 mb-5">
                <h3>Edit City</h3>
            </div>
            <Row>
                <Col md={4}>
                    <h5>Edit City Details</h5>
                    <p>Edit the city details here</p>
                </Col>
                <Col md={8}>
                    {city ? (
                        <CityForm initialData={city} onSubmit={handleSubmit} isEdit={true} />
                    ) : (
                        <p>Loading...</p>
                    )}
                </Col>
            </Row>


        </Container>

    );
};

export default EditCity;
