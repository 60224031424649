import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const BannerTable = () => {
    const banners = [
        {
            id: 1,
            bannerImage: 'https://www.suzukinajeebauto.com/static/media/home-banner-4.2b7ff8b7debf1994b81f.png',
            order: 1,
            titleEn: 'Banner Title 1',
            titleAr: 'عنوان البانر 1',
            subtitleEn: 'Subtitle 1',
            subtitleAr: 'العنوان الفرعي 1',
            status: 'Active',
        },
        {
            id: 2,
            bannerImage: 'https://www.suzukinajeebauto.com/static/media/home-banner.5bf3d06a5201df2a79d5.png',
            order: 2,
            titleEn: 'Banner Title 2',
            titleAr: 'عنوان البانر 2',
            subtitleEn: 'Subtitle 2',
            subtitleAr: 'العنوان الفرعي 2',
            status: 'Inactive',
        },
    ];

    const navigate = useNavigate();

    const handleEditBanner = (id) => {
        
        navigate(`/cms/banner-management/edit/${id}`);
    };

    return (
        <Table responsive striped bordered hover className='text-center'>
            <thead>
                <tr>
                    <th>Sr. No.</th>
                    <th>Banner Image</th>
                    <th>Banner Order</th>
                    <th>Banner Title (En)</th>
                    <th>Banner Title (Ar)</th>
                    <th>Banner Subtitle (En)</th>
                    <th>Banner Subtitle (Ar)</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {banners.map((banner, index) => (
                    <tr key={banner.id}>
                        <td>{index + 1}</td>
                        <td>
                            <img src={banner.bannerImage} alt="Banner" width={120} />
                        </td>
                        <td>{banner.order}</td>
                        <td>{banner.titleEn}</td>
                        <td>{banner.titleAr}</td>
                        <td>{banner.subtitleEn}</td>
                        <td>{banner.subtitleAr}</td>
                        <td>{banner.status}</td>
                        <td>
                        
                            <Button variant="warning" size="sm" className="me-2" onClick={() => handleEditBanner(1)}>Edit</Button>
                            <Button variant="danger" size="sm">
                                Delete
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default BannerTable;
