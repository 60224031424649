import React from 'react';
import { Container } from 'react-bootstrap';
import VehicleForm from '../../../components/forms/VehicleForm/VehicleForm';

const AddNewVehicle = () => {
    return (
        <Container fluid>
            <div className='mt-5 mb-5'>
                <h3 className="my-4">Add New Vehicle</h3>
            </div>
            <VehicleForm />
        </Container>
    );
};

export default AddNewVehicle;
