import React, { useEffect, useState } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Image, Card, Button } from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OfferView = () => {
    const { id } = useParams();
    const [offer, setOffer] = useState(null);
    const [loading, setLoading] = useState(true);
    const isAuthenticated = localStorage.getItem('token') !== null;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOffer = async () => {
            try {
                const response = await axios.get(`https://beta.api.nhcholding.com/api/offers/${id}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                setOffer(response.data);
            } catch (error) {
                console.error('Error fetching offer:', error);
                toast.error('Failed to load offer details.');
            } finally {
                setLoading(false);
            }
        };

        fetchOffer();
    }, [id]);

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    if (loading) {
        return <div>Loading offer details...</div>;
    }

    if (!offer) {
        return <div>Offer not found</div>;
    }

    const handleGoBack = () => {
        navigate(-1); // Goes back to the previous page in history
    };

    return (
        <Container className="mt-4" fluid>
            <ToastContainer />

            <div className="d-flex justify-content-between align-items-center mt-5 mb-5">
                <h3>Offer Details View</h3>
                <Button variant="primary" onClick={handleGoBack}>
                    Go Back
                </Button>
            </div>


            <Row className="justify-content-center">
                <Col md={8}>
                    <Card className="shadow-sm">
                        {offer.offer_banner && (
                            <Card.Img
                                variant="top"
                                src={`https://beta.api.nhcholding.com/storage/${offer.offer_banner}`}
                                alt="Offer Banner"
                                className="img-fluid"
                            />
                        )}
                        <Card.Body>
                            <Card.Title>Offer Details</Card.Title>
                            <Row className="mt-3">
                                <Col>
                                    <h5>Offer Name (English):</h5>
                                    <p>{offer.offer_name_en}</p>
                                </Col>
                                <Col>
                                    <h5>Offer Name (Arabic):</h5>
                                    <p>{offer.offer_name_ar}</p>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <h5>Description (English):</h5>
                                    <p>{offer.offer_description_en}</p>
                                </Col>
                                <Col>
                                    <h5>Description (Arabic):</h5>
                                    <p>{offer.offer_description_ar}</p>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <h5>Start Date:</h5>
                                    <p>{offer.offer_start_date}</p>
                                </Col>
                                <Col>
                                    <h5>End Date:</h5>
                                    <p>{offer.offer_end_date}</p>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <h5>Status:</h5>
                                    <p>{offer.status}</p>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <h5>Listed Vehicles for Offer:</h5>
                                    <ul>
                                        {offer.vehicles?.map((vehicle, index) => (
                                            <li key={index}>{vehicle}</li>
                                        ))}
                                    </ul>
                                </Col>
                                <Col>
                                    <h5>Showing Offer on Vehicles:</h5>
                                    <ul>
                                        {offer.related_vehicles?.map((vehicle, index) => (
                                            <li key={index}>{vehicle}</li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default OfferView;
