import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BannerForm from '../../../../components/forms/BannerForm/BannerForm';

const EditBanner = () => {

    const initialValues = {
        bannerImage: null,
        titleEn: 'Sample Title (En)',
        titleAr: 'Sample Title (Ar)',
        subtitleEn: 'Sample Subtitle (En)',
        subtitleAr: 'Sample Subtitle (Ar)',
        status: true, // Active by default
    };

    const handleFormSubmit = (values) => {
        console.log('Edited banner values:', values);
    };

    return (
        <Container fluid>
            <div className='mt-5 mb-5'>
                <h3>Edit Banner</h3>
            </div>
            <Row>
                <Col md={4}>
                    <h5>Edit Banner Details</h5>
                    <p>Update Banner details here as suitable.</p>
                </Col>
                <Col md={8}>
                    <BannerForm initialValues={initialValues} isEditMode={true} onSubmit={handleFormSubmit} />
                </Col>
            </Row>

        </Container>

    );
};

export default EditBanner;
