import React, { useState } from 'react';
import { Formik, FieldArray, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VehicleForm = ({ isEditMode = false, onSubmit }) => {

    const [columnsCreated, setColumnsCreated] = useState(false);
    const initialValues = {
        vehicleName: '',
        vehicleCategory: '',
        vehicleVideo: null,
        vehicleBrochure: null,
        driveLine: '',
        engine: '',
        horsePower: '',
        fuelEfficiency: '',
        fuelTankCapacity: '',
        vehicleVariants: [
            {
                variantName: '',
                price: '',
            },
        ],
        vehicleColors: [
            {
                colorImage: null,
                colorName: '',
            },
        ],
        variantFeatures: [
            {
                variantName: '',
                variantImage: null,
                features: [{ featureEn: '', featureAr: '' }],
            },
        ],
        exteriorImages: [
            {
                titleEn: '',
                titleAr: '',
                descriptionEn: '',
                descriptionAr: '',
                image: null,
            },
        ],
        interiorBanner: null,
        interiorDetails: [
            {
                titleEn: '',
                titleAr: '',
                descriptionEn: '',
                descriptionAr: '',
                picture: null,
            },
        ],
        safetyFeatures: [
            {
                titleEn: '',
                titleAr: '',
                descriptionEn: '',
                descriptionAr: '',
                image: null,
            },
        ],
        specificationVariants: [''], // Array to store each variant (GL, GL Plus, etc.)
        specifications: [
            {
                specificationEn: '',
                specificationAr: '',
                variants: []
            }
        ]
    };

    const validationSchema = Yup.object().shape({
        vehicleName: Yup.string().required('Vehicle Name is required'),
        vehicleCategory: Yup.string().required('Vehicle Category is required'),
        vehicleVideo: Yup.mixed().required('Vehicle Video is required'),
        vehicleBrochure: Yup.mixed().required('Vehicle Brochure is required'),
        driveLine: Yup.string().required('Drive Line is required'),
        engine: Yup.string().required('Engine is required'),
        horsePower: Yup.string().required('Horse Power is required'),
        fuelEfficiency: Yup.string().required('Fuel Efficiency is required'),
        fuelTankCapacity: Yup.string().required('Fuel Tank Capacity is required'),
        vehicleVariants: Yup.array().of(
            Yup.object().shape({
                variantName: Yup.string().required('Variant Name is required'),
                price: Yup.number().required('Price is required').positive(),
            })
        ),
        vehicleColors: Yup.array().of(
            Yup.object().shape({
                colorImage: Yup.mixed().required('Color Image is required'),
                colorName: Yup.string().required('Color Name is required'),
            })
        ),
        exteriorImages: Yup.array().of(
            Yup.object().shape({
                titleEn: Yup.string().required('Title (En) is required'),
                titleAr: Yup.string().required('Title (Ar) is required'),
                descriptionEn: Yup.string().required('Description (En) is required'),
                descriptionAr: Yup.string().required('Description (Ar) is required'),
                image: Yup.mixed().required('Exterior Image is required'),
            })
        ),
        interiorBanner: Yup.mixed().required('Interior Banner is required'),
        interiorDetails: Yup.array().of(
            Yup.object().shape({
                titleEn: Yup.string().required('Title (En) is required'),
                titleAr: Yup.string().required('Title (Ar) is required'),
                descriptionEn: Yup.string().required('Description (En) is required'),
                descriptionAr: Yup.string().required('Description (Ar) is required'),
                picture: Yup.mixed().required('Interior Picture is required'),
            })
        ),
        safetyFeatures: Yup.array().of(
            Yup.object().shape({
                titleEn: Yup.string().required('Title (En) is required'),
                titleAr: Yup.string().required('Title (Ar) is required'),
                descriptionEn: Yup.string().required('Description (En) is required'),
                descriptionAr: Yup.string().required('Description (Ar) is required'),
                image: Yup.mixed().required('Feature Image is required'),
            })
        ),
        specificationVariants: Yup.array().of(Yup.string().required('Variant name is required')),
        specifications: Yup.array().of(
            Yup.object().shape({
                specificationEn: Yup.string().required('Specification (En) is required'),
                specificationAr: Yup.string().required('Specification (Ar) is required'),
                variants: Yup.array().of(
                    Yup.object().shape({
                        en: Yup.string(),
                        ar: Yup.string(),
                        isYes: Yup.boolean(),
                        isNo: Yup.boolean()
                    })
                )
            })
        ),
    });

    return (
        <>
            <ToastContainer />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    onSubmit(values);
                    toast.success(isEditMode ? 'Vehicle updated successfully!' : 'Vehicle added successfully!');
                    resetForm();
                    setColumnsCreated(false);
                }}
            >
                {({ values, handleChange, setFieldValue, touched, errors }) => (
                    <FormikForm>

                        {/* Summary Section */}
                        <Row className='border-bottom pb-3'>
                            <Col md={4}>
                                <h5>Summary</h5>
                                <p>Overview details of Vehicle</p>
                            </Col>

                            <Col md={8}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Vehicle Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="vehicleName"
                                                value={values.vehicleName}
                                                onChange={handleChange}
                                                isInvalid={touched.vehicleName && !!errors.vehicleName}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.vehicleName}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Select Vehicle Category</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="vehicleCategory"
                                                value={values.vehicleCategory}
                                                onChange={handleChange}
                                                isInvalid={touched.vehicleCategory && !!errors.vehicleCategory}
                                            >
                                                <option value="">Select Category</option>
                                                <option value="Sedan">Sedan</option>
                                                <option value="Hatchback">Hatchback</option>
                                                <option value="SUV/Crossover">SUV/Crossover</option>
                                                <option value="Commercial">Commercial</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{errors.vehicleCategory}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Drive Line</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="driveLine"
                                                value={values.driveLine}
                                                onChange={handleChange}
                                                isInvalid={touched.driveLine && !!errors.driveLine}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.driveLine}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Engine</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="engine"
                                                value={values.engine}
                                                onChange={handleChange}
                                                isInvalid={touched.engine && !!errors.engine}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.engine}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Horse Power</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="horsePower"
                                                value={values.horsePower}
                                                onChange={handleChange}
                                                isInvalid={touched.horsePower && !!errors.horsePower}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.horsePower}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Fuel Efficiency</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="fuelEfficiency"
                                                value={values.fuelEfficiency}
                                                onChange={handleChange}
                                                isInvalid={touched.fuelEfficiency && !!errors.fuelEfficiency}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.fuelEfficiency}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Fuel Tank Capacity</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="fuelTankCapacity"
                                                value={values.fuelTankCapacity}
                                                onChange={handleChange}
                                                isInvalid={touched.fuelTankCapacity && !!errors.fuelTankCapacity}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.fuelTankCapacity}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Vehicle Video</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="vehicleVideo"
                                                onChange={(e) => setFieldValue("vehicleVideo", e.target.files[0])}
                                                isInvalid={touched.vehicleVideo && !!errors.vehicleVideo}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.vehicleVideo}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Vehicle Brochure</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="vehicleBrochure"
                                                onChange={(e) => setFieldValue("vehicleBrochure", e.target.files[0])}
                                                isInvalid={touched.vehicleBrochure && !!errors.vehicleBrochure}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.vehicleBrochure}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>


                        {/* Vehicle Variants */}
                        <Row className='border-bottom pt-3 pb-3'>
                            <Col md={4}>
                                <h5>Vehicle Variants</h5>
                                <p>Vehicle Variants with their prices</p>
                            </Col>
                            <Col md={8}>
                                {/* <FieldArray name="vehicleVariants">
                                    {({ remove, push }) => (
                                        <>
                                            {values.vehicleVariants.map((variant, index) => (
                                                <div key={index}>
                                                    <Row>
                                                        <Col md={5}>
                                                            <Form.Group>
                                                                <Form.Label>Variant Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`vehicleVariants.${index}.variantName`}
                                                                    value={variant.variantName}
                                                                    onChange={handleChange}
                                                                    isInvalid={touched.vehicleVariants?.[index]?.variantName && !!errors.vehicleVariants?.[index]?.variantName}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.vehicleVariants?.[index]?.variantName}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={5}>
                                                            <Form.Group>
                                                                <Form.Label>Price</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    name={`vehicleVariants.${index}.price`}
                                                                    value={variant.price}
                                                                    onChange={handleChange}
                                                                    isInvalid={touched.vehicleVariants?.[index]?.price && !!errors.vehicleVariants?.[index]?.price}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.vehicleVariants?.[index]?.price}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={2} className="align-self-center">
                                                            <Button variant="danger" onClick={() => remove(index)} disabled={values.vehicleVariants.length === 1}>Remove</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                            <Button variant="secondary" onClick={() => push({ variantName: '', price: '' })}>Add Variant</Button>
                                        </>
                                    )}
                                </FieldArray> */}

                                <FieldArray name="vehicleVariants">
                                    {({ remove, push }) => (
                                        <>
                                            {(values.vehicleVariants || []).map((variant, index) => (
                                                <div key={index}>
                                                    <Row>
                                                        <Col md={5}>
                                                            <Form.Group>
                                                                <Form.Label>Variant Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`vehicleVariants.${index}.variantName`}
                                                                    value={variant.variantName}
                                                                    onChange={handleChange}
                                                                    isInvalid={touched.vehicleVariants?.[index]?.variantName && !!errors.vehicleVariants?.[index]?.variantName}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.vehicleVariants?.[index]?.variantName}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={5}>
                                                            <Form.Group>
                                                                <Form.Label>Price</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    name={`vehicleVariants.${index}.price`}
                                                                    value={variant.price}
                                                                    onChange={handleChange}
                                                                    isInvalid={touched.vehicleVariants?.[index]?.price && !!errors.vehicleVariants?.[index]?.price}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.vehicleVariants?.[index]?.price}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={2} className="align-self-center">
                                                            <Button variant="danger" onClick={() => remove(index)} disabled={(values.vehicleVariants || []).length === 1}>Remove</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                            <Button variant="secondary" onClick={() => push({ variantName: '', price: '' })}>Add Variant</Button>
                                        </>
                                    )}
                                </FieldArray>

                            </Col>
                        </Row>


                        {/* Variant Features Section */}
                        <Row className='border-bottom pt-3 pb-3'>
                            <Col md={4}>
                                <h5>Variant Features</h5>
                                <p>Variant-specific features and details</p>
                            </Col>
                            <Col md={8}>
                                <FieldArray name="variantFeatures">
                                    {({ remove: removeVariant, push: pushVariant }) => (
                                        <>
                                            {values.variantFeatures.map((variant, index) => (
                                                <div key={index}>
                                                    <Row>
                                                        <Col md={5}>
                                                            <Form.Group>
                                                                <Form.Label>Variant Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`variantFeatures.${index}.variantName`}
                                                                    value={variant.variantName}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={5}>
                                                            <Form.Group>
                                                                <Form.Label>Variant Image</Form.Label>
                                                                <Form.Control
                                                                    type="file"
                                                                    name={`variantFeatures.${index}.variantImage`}
                                                                    onChange={(e) => setFieldValue(`variantFeatures.${index}.variantImage`, e.target.files[0])}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={2} className="align-self-center">
                                                            <Button variant="danger" onClick={() => removeVariant(index)} disabled={values.variantFeatures.length === 1}>Remove</Button>
                                                        </Col>
                                                    </Row>
                                                    <FieldArray name={`variantFeatures.${index}.features`}>
                                                        {({ remove: removeFeature, push: pushFeature }) => (
                                                            <>
                                                                {variant.features.map((feature, featureIndex) => (
                                                                    <Row key={featureIndex}>
                                                                        <Col md={5}>
                                                                            <Form.Group>
                                                                                <Form.Label>Feature (En)</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name={`variantFeatures.${index}.features.${featureIndex}.featureEn`}
                                                                                    value={feature.featureEn}
                                                                                    onChange={handleChange}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col md={5}>
                                                                            <Form.Group>
                                                                                <Form.Label>Feature (Ar)</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name={`variantFeatures.${index}.features.${featureIndex}.featureAr`}
                                                                                    value={feature.featureAr}
                                                                                    onChange={handleChange}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col md={2} className="align-self-center">
                                                                            <Button variant="danger" onClick={() => removeFeature(featureIndex)} disabled={variant.features.length === 1}>Remove</Button>
                                                                        </Col>
                                                                    </Row>
                                                                ))}
                                                                <Button variant="secondary mt-2" onClick={() => pushFeature({ featureEn: '', featureAr: '' })}>Add More Features</Button>
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </div>
                                            ))}
                                            <Button variant="secondary mt-3" onClick={() => pushVariant({ variantName: '', variantImage: null, features: [{ featureEn: '', featureAr: '' }] })}>
                                                Add More Variant for Features
                                            </Button>
                                        </>
                                    )}
                                </FieldArray>
                            </Col>
                        </Row>

                        {/* Exterior Section */}

                        <Row className='border-bottom pt-3 pb-3'>
                            <Col md={4}>
                                <h5>Vehicle Exterior Section</h5>
                                <p>Exterior details section of the Vehicle</p>
                            </Col>

                            <Col md={8}>
                                <FieldArray name="exteriorImages">
                                    {({ remove, push }) => (
                                        <>
                                            {values.exteriorImages.map((exterior, index) => (
                                                <div key={index}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <Form.Group>
                                                                <Form.Label>Exterior Title (En)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`exteriorImages.${index}.titleEn`}
                                                                    value={exterior.titleEn}
                                                                    onChange={handleChange}
                                                                    isInvalid={touched.exteriorImages?.[index]?.titleEn && !!errors.exteriorImages?.[index]?.titleEn}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.exteriorImages?.[index]?.titleEn}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group>
                                                                <Form.Label>Exterior Title (Ar)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`exteriorImages.${index}.titleAr`}
                                                                    value={exterior.titleAr}
                                                                    onChange={handleChange}
                                                                    isInvalid={touched.exteriorImages?.[index]?.titleAr && !!errors.exteriorImages?.[index]?.titleAr}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.exteriorImages?.[index]?.titleAr}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <Form.Group>
                                                                <Form.Label>Exterior Description (En)</Form.Label>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    name={`exteriorImages.${index}.descriptionEn`}
                                                                    value={exterior.descriptionEn}
                                                                    onChange={handleChange}
                                                                    isInvalid={touched.exteriorImages?.[index]?.descriptionEn && !!errors.exteriorImages?.[index]?.descriptionEn}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.exteriorImages?.[index]?.descriptionEn}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group>
                                                                <Form.Label>Exterior Description (Ar)</Form.Label>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    name={`exteriorImages.${index}.descriptionAr`}
                                                                    value={exterior.descriptionAr}
                                                                    onChange={handleChange}
                                                                    isInvalid={touched.exteriorImages?.[index]?.descriptionAr && !!errors.exteriorImages?.[index]?.descriptionAr}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.exteriorImages?.[index]?.descriptionAr}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Form.Group>
                                                                <Form.Label>Exterior Image</Form.Label>
                                                                <Form.Control
                                                                    type="file"
                                                                    name={`exteriorImages.${index}.image`}
                                                                    onChange={(e) => setFieldValue(`exteriorImages.${index}.image`, e.target.files[0])}
                                                                    isInvalid={touched.exteriorImages?.[index]?.image && !!errors.exteriorImages?.[index]?.image}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.exteriorImages?.[index]?.image}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Button variant="danger" onClick={() => remove(index)}>Remove Exterior</Button>
                                                </div>
                                            ))}
                                            <Button variant="secondary mt-2" onClick={() => push({ titleEn: '', titleAr: '', descriptionEn: '', descriptionAr: '', image: null })}>Add More Exterior Images</Button>
                                        </>
                                    )}
                                </FieldArray>
                            </Col>
                        </Row>


                        {/* Interior Section */}
                        <Row className='border-bottom pt-3 pb-3'>
                            <Col md={4}>
                                <h5>Vehicle Interior Section</h5>
                                <p>Interior details section of the Vehicle</p>
                            </Col>
                            <Col md={8}>
                                <Form.Group>
                                    <Form.Label>Interior Banner</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="interiorBanner"
                                        onChange={(e) => setFieldValue("interiorBanner", e.target.files[0])}
                                        isInvalid={touched.interiorBanner && !!errors.interiorBanner}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.interiorBanner}</Form.Control.Feedback>
                                </Form.Group>

                                <FieldArray name="interiorDetails">
                                    {({ remove, push }) => (
                                        <>
                                            {values.interiorDetails.map((interior, index) => (
                                                <div key={index}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <Form.Group>
                                                                <Form.Label>Interior Title (En)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`interiorDetails.${index}.titleEn`}
                                                                    value={interior.titleEn}
                                                                    onChange={handleChange}
                                                                    isInvalid={touched.interiorDetails?.[index]?.titleEn && !!errors.interiorDetails?.[index]?.titleEn}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.interiorDetails?.[index]?.titleEn}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group>
                                                                <Form.Label>Interior Title (Ar)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`interiorDetails.${index}.titleAr`}
                                                                    value={interior.titleAr}
                                                                    onChange={handleChange}
                                                                    isInvalid={touched.interiorDetails?.[index]?.titleAr && !!errors.interiorDetails?.[index]?.titleAr}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.interiorDetails?.[index]?.titleAr}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <Form.Group>
                                                                <Form.Label>Interior Description (En)</Form.Label>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    name={`interiorDetails.${index}.descriptionEn`}
                                                                    value={interior.descriptionEn}
                                                                    onChange={handleChange}
                                                                    isInvalid={touched.interiorDetails?.[index]?.descriptionEn && !!errors.interiorDetails?.[index]?.descriptionEn}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.interiorDetails?.[index]?.descriptionEn}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group>
                                                                <Form.Label>Interior Description (Ar)</Form.Label>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    name={`interiorDetails.${index}.descriptionAr`}
                                                                    value={interior.descriptionAr}
                                                                    onChange={handleChange}
                                                                    isInvalid={touched.interiorDetails?.[index]?.descriptionAr && !!errors.interiorDetails?.[index]?.descriptionAr}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.interiorDetails?.[index]?.descriptionAr}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Form.Group>
                                                                <Form.Label>Interior Picture</Form.Label>
                                                                <Form.Control
                                                                    type="file"
                                                                    name={`interiorDetails.${index}.picture`}
                                                                    onChange={(e) => setFieldValue(`interiorDetails.${index}.picture`, e.target.files[0])}
                                                                    isInvalid={touched.interiorDetails?.[index]?.picture && !!errors.interiorDetails?.[index]?.picture}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.interiorDetails?.[index]?.picture}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Button variant="danger" onClick={() => remove(index)}>Remove Interior Detail</Button>
                                                </div>
                                            ))}
                                            <Button variant="secondary mt-2" onClick={() => push({ titleEn: '', titleAr: '', descriptionEn: '', descriptionAr: '', picture: null })}>Add More Interior Details</Button>
                                        </>
                                    )}
                                </FieldArray>
                            </Col>
                        </Row>

                        {/* Safety & Special Features Section */}
                        <Row className='border-bottom pt-3 pb-3'>
                            <Col md={4}>
                                <h5>Safety & Special Features Section</h5>
                            </Col>
                            <Col md={8}>
                                <FieldArray name="safetyFeatures">
                                    {({ remove, push }) => (
                                        <>
                                            {values.safetyFeatures.map((feature, index) => (
                                                <div key={index}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <Form.Group>
                                                                <Form.Label>Features Title (En)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`safetyFeatures.${index}.titleEn`}
                                                                    value={feature.titleEn}
                                                                    onChange={handleChange}
                                                                    isInvalid={touched.safetyFeatures?.[index]?.titleEn && !!errors.safetyFeatures?.[index]?.titleEn}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.safetyFeatures?.[index]?.titleEn}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group>
                                                                <Form.Label>Features Title (Ar)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`safetyFeatures.${index}.titleAr`}
                                                                    value={feature.titleAr}
                                                                    onChange={handleChange}
                                                                    isInvalid={touched.safetyFeatures?.[index]?.titleAr && !!errors.safetyFeatures?.[index]?.titleAr}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.safetyFeatures?.[index]?.titleAr}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <Form.Group>
                                                                <Form.Label>Features Description (En)</Form.Label>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    name={`safetyFeatures.${index}.descriptionEn`}
                                                                    value={feature.descriptionEn}
                                                                    onChange={handleChange}
                                                                    isInvalid={touched.safetyFeatures?.[index]?.descriptionEn && !!errors.safetyFeatures?.[index]?.descriptionEn}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.safetyFeatures?.[index]?.descriptionEn}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group>
                                                                <Form.Label>Features Description (Ar)</Form.Label>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    name={`safetyFeatures.${index}.descriptionAr`}
                                                                    value={feature.descriptionAr}
                                                                    onChange={handleChange}
                                                                    isInvalid={touched.safetyFeatures?.[index]?.descriptionAr && !!errors.safetyFeatures?.[index]?.descriptionAr}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.safetyFeatures?.[index]?.descriptionAr}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Form.Group>
                                                                <Form.Label>Feature Image</Form.Label>
                                                                <Form.Control
                                                                    type="file"
                                                                    name={`safetyFeatures.${index}.image`}
                                                                    onChange={(e) => setFieldValue(`safetyFeatures.${index}.image`, e.target.files[0])}
                                                                    isInvalid={touched.safetyFeatures?.[index]?.image && !!errors.safetyFeatures?.[index]?.image}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.safetyFeatures?.[index]?.image}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Button variant="danger" onClick={() => remove(index)}>Remove Feature</Button>
                                                </div>
                                            ))}
                                            <Button variant="secondary mt-2" onClick={() => push({ titleEn: '', titleAr: '', descriptionEn: '', descriptionAr: '', image: null })}>Add More Features</Button>
                                        </>
                                    )}
                                </FieldArray>
                            </Col>
                        </Row>

                        {/* Vehicle Specification Section */}
                        <Row className="border-bottom pt-3 pb-3">
                            <Col md={4}>
                                <h5>Specification Variants</h5>
                                <p>Manage specifications as per variants</p>
                            </Col>
                            <Col md={8}>
                                <FieldArray name="specificationVariants">
                                    {({ push, remove }) => (
                                        <>
                                            {values.specificationVariants.map((variant, index) => (
                                                <Row key={index} className="align-items-center mb-2">
                                                    <Col md={8}>
                                                        <Form.Group>
                                                            <Form.Label>{`Specification Variant ${index + 1}`}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name={`specificationVariants.${index}`}
                                                                value={variant || ''}
                                                                onChange={handleChange}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={4} className="d-flex align-items-center">
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => remove(index)}
                                                            disabled={values.specificationVariants.length === 1}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ))}
                                            <div className='mb-2'>
                                                <Button
                                                    variant="secondary"
                                                    onClick={() => push('')}
                                                    className="mt-2 me-2"
                                                >
                                                    Add More Specification Variant
                                                </Button>
                                                <Button
                                                    variant="primary"
                                                    onClick={() => {
                                                        setColumnsCreated(true);
                                                        setFieldValue(
                                                            'specifications[0].variants',
                                                            values.specificationVariants.map(() => ({ en: '', isYes: false, isNo: false }))
                                                        );
                                                    }}
                                                    className="mt-2 ml-3"
                                                    disabled={columnsCreated}
                                                >
                                                    Create Specification Columns
                                                </Button>
                                            </div>

                                        </>
                                    )}
                                </FieldArray>
                                {columnsCreated && (
                                    <FieldArray name="specifications">
                                        {({ push, remove }) => (
                                            <>
                                                {values.specifications.map((specification, specIndex) => (
                                                    <Row key={specIndex} className="align-items-center mb-3">
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Specification Name (En)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`specifications.${specIndex}.specificationEn`}
                                                                    value={specification.specificationEn}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="mt-2">
                                                                <Form.Label>Specification Name (Ar)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`specifications.${specIndex}.specificationAr`}
                                                                    value={specification.specificationAr}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                        </Col>

                                                        {/* Dynamic Variant Columns with Variant Names and English/Arabic Fields */}
                                                        {values.specificationVariants.map((variantName, varIndex) => (
                                                            <Col md={3} key={varIndex}>
                                                                <Form.Group>
                                                                    <Form.Label>{variantName || `Variant ${varIndex + 1}`}</Form.Label>
                                                                    {/* English Input for Variant */}
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="English"
                                                                        name={`specifications.${specIndex}.variants.${varIndex}.en`}
                                                                        value={values.specifications[specIndex].variants[varIndex]?.en || ''}
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                `specifications.${specIndex}.variants.${varIndex}.en`,
                                                                                e.target.value
                                                                            );
                                                                            setFieldValue(
                                                                                `specifications.${specIndex}.variants.${varIndex}.isYes`,
                                                                                e.target.value ? false : values.specifications[specIndex].variants[varIndex].isYes
                                                                            );
                                                                            setFieldValue(
                                                                                `specifications.${specIndex}.variants.${varIndex}.isNo`,
                                                                                e.target.value ? false : values.specifications[specIndex].variants[varIndex].isNo
                                                                            );
                                                                        }}
                                                                        disabled={
                                                                            values.specifications[specIndex].variants[varIndex]?.isYes ||
                                                                            values.specifications[specIndex].variants[varIndex]?.isNo
                                                                        }
                                                                        className="mt-2"
                                                                    />
                                                                    {/* Arabic Input for Variant */}
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Arabic"
                                                                        name={`specifications.${specIndex}.variants.${varIndex}.ar`}
                                                                        value={values.specifications[specIndex].variants[varIndex]?.ar || ''}
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                `specifications.${specIndex}.variants.${varIndex}.ar`,
                                                                                e.target.value
                                                                            );
                                                                            setFieldValue(
                                                                                `specifications.${specIndex}.variants.${varIndex}.isYes`,
                                                                                e.target.value ? false : values.specifications[specIndex].variants[varIndex].isYes
                                                                            );
                                                                            setFieldValue(
                                                                                `specifications.${specIndex}.variants.${varIndex}.isNo`,
                                                                                e.target.value ? false : values.specifications[specIndex].variants[varIndex].isNo
                                                                            );
                                                                        }}
                                                                        disabled={
                                                                            values.specifications[specIndex].variants[varIndex]?.isYes ||
                                                                            values.specifications[specIndex].variants[varIndex]?.isNo
                                                                        }
                                                                        className="mt-2"
                                                                    />
                                                                    {/* Yes/No Checkboxes */}
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        label="Yes"
                                                                        checked={values.specifications[specIndex].variants[varIndex]?.isYes || false}
                                                                        onChange={(e) => {
                                                                            setFieldValue(`specifications.${specIndex}.variants.${varIndex}.isYes`, e.target.checked);
                                                                            if (e.target.checked) {
                                                                                setFieldValue(`specifications.${specIndex}.variants.${varIndex}.en`, '');
                                                                                setFieldValue(`specifications.${specIndex}.variants.${varIndex}.ar`, '');
                                                                                setFieldValue(`specifications.${specIndex}.variants.${varIndex}.isNo`, false);
                                                                            }
                                                                        }}
                                                                        disabled={!!values.specifications[specIndex].variants[varIndex]?.en || !!values.specifications[specIndex].variants[varIndex]?.ar}
                                                                        className="mt-2"
                                                                    />
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        label="No"
                                                                        checked={values.specifications[specIndex].variants[varIndex]?.isNo || false}
                                                                        onChange={(e) => {
                                                                            setFieldValue(`specifications.${specIndex}.variants.${varIndex}.isNo`, e.target.checked);
                                                                            if (e.target.checked) {
                                                                                setFieldValue(`specifications.${specIndex}.variants.${varIndex}.en`, '');
                                                                                setFieldValue(`specifications.${specIndex}.variants.${varIndex}.ar`, '');
                                                                                setFieldValue(`specifications.${specIndex}.variants.${varIndex}.isYes`, false);
                                                                            }
                                                                        }}
                                                                        disabled={!!values.specifications[specIndex].variants[varIndex]?.en || !!values.specifications[specIndex].variants[varIndex]?.ar}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        ))}

                                                        <Col md={12} className="mt-2">
                                                            <Button
                                                                variant="danger"
                                                                onClick={() => remove(specIndex)}
                                                                disabled={values.specifications.length === 1}
                                                            >
                                                                Remove Specification
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                ))}



                                                <Button
                                                    variant="secondary mt-3"
                                                    onClick={() => push({
                                                        specificationEn: '',
                                                        specificationAr: '',
                                                        variants: values.specificationVariants.map(() => ({ en: '', isYes: false, isNo: false }))
                                                    })}
                                                >
                                                    Add More Specification
                                                </Button>
                                            </>
                                        )}
                                    </FieldArray>
                                )}
                            </Col>
                        </Row>

                        {/* Submit Button */}
                        <div className="text-end mt-4">
                            <Button variant="primary" type="submit">
                                {isEditMode ? 'Update Vehicle' : 'Submit'}
                            </Button>
                        </div>
                    </FormikForm>
                )}
            </Formik>
        </>
    );
};

export default VehicleForm;

