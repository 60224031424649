// import React from 'react';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { Row, Col, Form, Button } from 'react-bootstrap';

// // Validation schema using Yup
// const validationSchema = Yup.object({
//     destinationUrl: Yup.string()
//         .required('Destination URL is required')
//         .url('Please enter a valid URL'),
//     utmSource: Yup.string()
//         .required('UTM Source is required')
//         .min(2, 'UTM Source must be at least 2 characters'),
//     utmMedium: Yup.string()
//         .required('UTM Medium is required')
//         .min(2, 'UTM Medium must be at least 2 characters'),
//     utmCampaign: Yup.string()
//         .required('UTM Campaign is required')
//         .min(2, 'UTM Campaign must be at least 2 characters'),
//     utmTerm: Yup.string().notRequired(), // Optional field
//     utmContent: Yup.string().notRequired(), // Optional field
// });

// const UTMForm = () => {
//     const formik = useFormik({
//         initialValues: {
//             destinationUrl: '',
//             utmSource: '',
//             utmMedium: '',
//             utmCampaign: '',
//             utmTerm: '',
//             utmContent: '',
//         },
//         validationSchema,
//         onSubmit: (values, { resetForm }) => {
//             const { destinationUrl, utmSource, utmMedium, utmCampaign, utmTerm, utmContent } = values;

//             // Construct UTM URL
//             const url = new URL(destinationUrl);
//             url.searchParams.append('utm_source', utmSource);
//             url.searchParams.append('utm_medium', utmMedium);
//             url.searchParams.append('utm_campaign', utmCampaign);
//             if (utmTerm) url.searchParams.append('utm_term', utmTerm);
//             if (utmContent) url.searchParams.append('utm_content', utmContent);

//             // Toast success and reset the form
//             toast.success('UTM URL generated successfully!', {
//                 position: 'top-right',
//             });
//             resetForm();

//             // Log generated URL to the console (replace with backend saving logic if needed)
//             console.log('Generated UTM URL:', url.toString());
//         },
//     });

//     return (
//         <div className="utm-form">
//             <ToastContainer />
//             <Form onSubmit={formik.handleSubmit}>
//                 <Row>
//                     <Col md={12}>
//                         {/* Destination URL */}
//                         <Form.Group controlId="destinationUrl">
//                             <Form.Label>Destination URL</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 {...formik.getFieldProps('destinationUrl')}
//                                 isInvalid={formik.touched.destinationUrl && !!formik.errors.destinationUrl}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                                 {formik.errors.destinationUrl}
//                             </Form.Control.Feedback>
//                         </Form.Group>
//                     </Col>

//                     <Col md={6}>
//                         {/* UTM Source */}
//                         <Form.Group controlId="utmSource">
//                             <Form.Label>UTM Source</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 {...formik.getFieldProps('utmSource')}
//                                 isInvalid={formik.touched.utmSource && !!formik.errors.utmSource}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                                 {formik.errors.utmSource}
//                             </Form.Control.Feedback>
//                         </Form.Group>
//                     </Col>

//                     <Col md={6}>
//                         {/* UTM Medium */}
//                         <Form.Group controlId="utmMedium">
//                             <Form.Label>UTM Medium</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 {...formik.getFieldProps('utmMedium')}
//                                 isInvalid={formik.touched.utmMedium && !!formik.errors.utmMedium}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                                 {formik.errors.utmMedium}
//                             </Form.Control.Feedback>
//                         </Form.Group>
//                     </Col>

//                     <Col md={6}>
//                         {/* UTM Campaign */}
//                         <Form.Group controlId="utmCampaign">
//                             <Form.Label>UTM Campaign</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 {...formik.getFieldProps('utmCampaign')}
//                                 isInvalid={formik.touched.utmCampaign && !!formik.errors.utmCampaign}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                                 {formik.errors.utmCampaign}
//                             </Form.Control.Feedback>
//                         </Form.Group>
//                     </Col>

//                     <Col md={6}>
//                         {/* UTM Term */}
//                         <Form.Group controlId="utmTerm">
//                             <Form.Label>UTM Term (Optional)</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 {...formik.getFieldProps('utmTerm')}
//                                 isInvalid={formik.touched.utmTerm && !!formik.errors.utmTerm}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                                 {formik.errors.utmTerm}
//                             </Form.Control.Feedback>
//                         </Form.Group>
//                     </Col>

//                     <Col md={6}>
//                         {/* UTM Content */}
//                         <Form.Group controlId="utmContent">
//                             <Form.Label>UTM Content (Optional)</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 {...formik.getFieldProps('utmContent')}
//                                 isInvalid={formik.touched.utmContent && !!formik.errors.utmContent}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                                 {formik.errors.utmContent}
//                             </Form.Control.Feedback>
//                         </Form.Group>
//                     </Col>

//                     <Col md={12} className="text-end">
//                         <Button type="submit" variant="primary" disabled={formik.isSubmitting}>
//                             Generate UTM URL
//                         </Button>
//                     </Col>
//                 </Row>
//             </Form>
//         </div>
//     );
// };

// export default UTMForm;


import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Form, Button, InputGroup } from 'react-bootstrap';

// Validation schema using Yup
const validationSchema = Yup.object({
    destinationUrl: Yup.string()
        .required('Destination URL is required')
        .url('Please enter a valid URL'),
    utmSource: Yup.string()
        .required('UTM Source is required')
        .min(2, 'UTM Source must be at least 2 characters'),
    utmMedium: Yup.string()
        .required('UTM Medium is required')
        .min(2, 'UTM Medium must be at least 2 characters'),
    utmCampaign: Yup.string()
        .required('UTM Campaign is required')
        .min(2, 'UTM Campaign must be at least 2 characters'),
    utmTerm: Yup.string().notRequired(), // Optional field
    utmContent: Yup.string().notRequired(), // Optional field
});

const UTMForm = () => {
    const [generatedUrl, setGeneratedUrl] = useState('');

    const formik = useFormik({
        initialValues: {
            destinationUrl: '',
            utmSource: '',
            utmMedium: '',
            utmCampaign: '',
            utmTerm: '',
            utmContent: '',
        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            const { destinationUrl, utmSource, utmMedium, utmCampaign, utmTerm, utmContent } = values;

            // Construct UTM URL
            const url = new URL(destinationUrl);
            url.searchParams.append('utm_source', utmSource);
            url.searchParams.append('utm_medium', utmMedium);
            url.searchParams.append('utm_campaign', utmCampaign);
            if (utmTerm) url.searchParams.append('utm_term', utmTerm);
            if (utmContent) url.searchParams.append('utm_content', utmContent);

            // Display the generated UTM URL
            setGeneratedUrl(url.toString());

            toast.success('UTM URL generated successfully!', {
                position: 'top-right',
            });
            resetForm();
        },
    });

    const handleCopy = () => {
        navigator.clipboard.writeText(generatedUrl).then(() => {
            toast.info('UTM URL copied to clipboard!', {
                position: 'top-right',
            });
        });
    };

    return (
        <div className="utm-form">
            <ToastContainer />
            <Form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col md={12}>
                        {/* Destination URL */}
                        <Form.Group controlId="destinationUrl">
                            <Form.Label>Destination URL</Form.Label>
                            <Form.Control
                                type="text"
                                {...formik.getFieldProps('destinationUrl')}
                                isInvalid={formik.touched.destinationUrl && !!formik.errors.destinationUrl}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.destinationUrl}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        {/* UTM Source */}
                        <Form.Group controlId="utmSource">
                            <Form.Label>UTM Source</Form.Label>
                            <Form.Control
                                type="text"
                                {...formik.getFieldProps('utmSource')}
                                isInvalid={formik.touched.utmSource && !!formik.errors.utmSource}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.utmSource}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        {/* UTM Medium */}
                        <Form.Group controlId="utmMedium">
                            <Form.Label>UTM Medium</Form.Label>
                            <Form.Control
                                type="text"
                                {...formik.getFieldProps('utmMedium')}
                                isInvalid={formik.touched.utmMedium && !!formik.errors.utmMedium}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.utmMedium}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        {/* UTM Campaign */}
                        <Form.Group controlId="utmCampaign">
                            <Form.Label>UTM Campaign</Form.Label>
                            <Form.Control
                                type="text"
                                {...formik.getFieldProps('utmCampaign')}
                                isInvalid={formik.touched.utmCampaign && !!formik.errors.utmCampaign}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.utmCampaign}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        {/* UTM Term */}
                        <Form.Group controlId="utmTerm">
                            <Form.Label>UTM Term (Optional)</Form.Label>
                            <Form.Control
                                type="text"
                                {...formik.getFieldProps('utmTerm')}
                                isInvalid={formik.touched.utmTerm && !!formik.errors.utmTerm}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.utmTerm}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        {/* UTM Content */}
                        <Form.Group controlId="utmContent">
                            <Form.Label>UTM Content (Optional)</Form.Label>
                            <Form.Control
                                type="text"
                                {...formik.getFieldProps('utmContent')}
                                isInvalid={formik.touched.utmContent && !!formik.errors.utmContent}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.utmContent}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={12} className="text-end">
                        <Button type="submit" variant="primary" disabled={formik.isSubmitting}>
                            Generate UTM URL
                        </Button>
                    </Col>
                </Row>
            </Form>

            {generatedUrl && (
                <div className="generated-url mt-4">
                    <h5>Generated UTM URL:</h5>
                    <InputGroup>
                        <Form.Control type="text" readOnly value={generatedUrl} />
                        <Button variant="outline-secondary" onClick={handleCopy}>
                            Copy
                        </Button>
                    </InputGroup>
                </div>
            )}
        </div>
    );
};

export default UTMForm;
