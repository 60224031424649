import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import BlogForm from '../../../../components/forms/BlogForm/BlogForm';
import { useNavigate } from 'react-router-dom';

const AddNewBlog = () => {
    const navigate = useNavigate();

    const handleBlogSubmit = (values) => {
        console.log('Blog Published:', values);
    };

    return (
        <Container fluid>
            <div className='mt-5 mb-5'>
                <h3>Add New Blog</h3>
            </div>
            <Row>
                <Col md={4}>
                    <h5>Add the latest blog</h5>
                    <p>Post the latest news about Najeeb Auto.</p>
                </Col>

                <Col md={8}>
                <BlogForm onSubmit={handleBlogSubmit} />
                </Col>
            </Row>
            

        </Container>

    );
};

export default AddNewBlog;
