import React from 'react';
import ForgotPasswordForm from '../../components/forms/ForgotPasswordForm/ForgotPasswordForm';
import './ForgotPassword.css';

const ForgotPassword = () => {
    return (
        <div className="forgot-password-page d-flex justify-content-center align-items-center p-3">
            <ForgotPasswordForm />
        </div>
    );
};

export default ForgotPassword;
