import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import VehicleManagementTable from '../../components/tables/VehicleManagementTable/VehicleManagementTable';

const VehicleManagement = () => {
    const navigate = useNavigate();

    const handleAddVehicle = () => {
        navigate('/vehicle-management/add-new');
    };

    return (
        <Container fluid>
            <div className='vehicle-management-page'>
                <div className="d-flex justify-content-between align-items-center mt-5 mb-5">
                    <h3>Vehicle Management</h3>
                    <Button onClick={handleAddVehicle} variant="primary">
                        Add Vehicle
                    </Button>
                </div>
                <VehicleManagementTable />
            </div>
        </Container>
    );
};

export default VehicleManagement;
