import React from 'react';
import VehicleColorTable from '../../../components/tables/VehicleColorTable/VehicleColorTable'; // Import table for management
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './VehicleColors.css';

const VehicleColors = () => {
    const navigate = useNavigate(); // For programmatic navigation

    const handleAddNewColor = () => {
        navigate('/vehicle-colors/add-new'); // Navigate to Add New Color page
    };

    return (
        <Container fluid>
            <div className="vehicle-colors-page">
                <div className="page-header d-flex justify-content-between align-items-center mt-5 mb-5">
                    <h3>Vehicle Colors Management</h3>
                    <Button variant="primary" onClick={handleAddNewColor}>Add New Color</Button>
                </div>
                {/* Render the table in the management page */}
                <VehicleColorTable />
            </div>
        </Container>
    );
};

export default VehicleColors;
