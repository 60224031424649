import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import axios from 'axios';
import { toast } from 'react-toastify';

const UserRoleCharts = () => {
    const [roleData, setRoleData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://beta.api.nhcholding.com/api/users', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });

                if (Array.isArray(response.data)) {
                    const users = response.data;

                    // Role Name Mapping
                    const roleMapping = {
                        admin: 'Admin',
                        'mr-manager': 'Marketing Manager',
                        'mr-user': 'Marketing User',
                        it: 'IT',
                    };

                    // Aggregate Role Data
                    const roles = {};
                    users.forEach((user) => {
                        user.roles.forEach((role) => {
                            const roleName = roleMapping[role.name] || role.name; // Map role or keep original if not in mapping
                            roles[roleName] = (roles[roleName] || 0) + 1;
                        });
                    });
                    setRoleData(
                        Object.entries(roles).map(([name, value]) => ({
                            name,
                            value,
                        }))
                    );

                    // Aggregate Department Data
                    const departments = {};
                    users.forEach((user) => {
                        const departmentName = user.department?.name || 'Unknown';
                        departments[departmentName] = (departments[departmentName] || 0) + 1;
                    });
                    setDepartmentData(
                        Object.entries(departments).map(([name, value]) => ({
                            name,
                            value,
                        }))
                    );
                } else {
                    console.error('Unexpected response format:', response.data);
                    toast.error('Failed to load user role data.');
                }
            } catch (error) {
                console.error('Error fetching user role data:', error);
                toast.error('Error loading user role data.');
            }
        };

        fetchData();
    }, []);

    const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#0088FE', '#00C49F'];

    return (
        <div>
            <h5>User Roles Distribution</h5>
            <PieChart width={400} height={300}>
                <Pie
                    data={roleData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    label
                >
                    {roleData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>

            <h5>Users by Department</h5>
            <PieChart width={400} height={300}>
                <Pie
                    data={departmentData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    label
                >
                    {departmentData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
        </div>
    );
};

export default UserRoleCharts;
