import React from 'react';
import UserRolesForm from '../../../../components/forms/UserRolesForm/UserRolesForm';
import { Container, Col, Row } from 'react-bootstrap';

const AddNewUser = () => {
    return (
        <Container fluid>
            <div className="add-new-useer-page mt-5 mb-5">
                <h3>Add New User</h3>
            </div>

            <Row>
                <Col md={4}>
                    <h5>New User Addition</h5>
                    <p>Please add the new user details here.</p>
                </Col>

                <Col md={8}>
                <UserRolesForm />
                </Col>
            </Row>
           
        </Container>
    );
};

export default AddNewUser;
