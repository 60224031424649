import React from 'react';
import SparePartsPricingForm from '../../../../components/forms/SparePartsPricingForm/SparePartsPricingForm';
import { Container, Row, Col } from 'react-bootstrap';
import './AddNewSparePartsPricing.css';

const AddNewSparePartsPricing = () => {
    return (
        <Container fluid>
            <div className="add-new-spare-parts-pricing mt-5 mb-5">
                <h3>Add New Spare Parts Pricing</h3>
            </div>

            <Row>
                <Col md={4}>
                    <h5>Add Spare Parts Pricing Details</h5>
                    <p>Please add Spare Parts pricing based on variants available here.</p>
                </Col>
                <Col md={8}>
                    <SparePartsPricingForm />
                </Col>
            </Row>


        </Container>

    );
};

export default AddNewSparePartsPricing;
