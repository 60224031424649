import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Form as BootstrapForm } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BannerForm = ({ initialValues, isEditMode = false, onSubmit }) => {
    const defaultValues = {
        bannerImage: null,
        titleEn: '',
        titleAr: '',
        subtitleEn: '',
        subtitleAr: '',
        status: true, // Default to Active
    };

    const validationSchema = Yup.object().shape({
        bannerImage: Yup.mixed()
            .required('Banner image is required')
            .test('fileType', 'Only png, jpeg, or jpg formats are allowed', (value) =>
                value ? ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type) : false
            ),
        titleEn: Yup.string()
            .min(3, 'Banner Title (En) must be at least 3 characters')
            .matches(/^[A-Za-z\s]+$/, 'Banner Title (En) must contain only English letters and spaces')
            .required('Banner Title (En) is required'),
        titleAr: Yup.string()
            .min(3, 'Banner Title (Ar) must be at least 3 characters')
            .matches(/^[\u0600-\u06FF\s]+$/, 'Banner Title (Ar) must contain only Arabic letters and spaces')
            .required('Banner Title (Ar) is required'),
        subtitleEn: Yup.string()
            .min(3, 'Banner Subtitle (En) must be at least 3 characters')
            .matches(/^[A-Za-z\s]+$/, 'Banner Subtitle (En) must contain only English letters and spaces')
            .required('Banner Subtitle (En) is required'),
        subtitleAr: Yup.string()
            .min(3, 'Banner Subtitle (Ar) must be at least 3 characters')
            .matches(/^[\u0600-\u06FF\s]+$/, 'Banner Subtitle (Ar) must contain only Arabic letters and spaces')
            .required('Banner Subtitle (Ar) is required'),
    });

    const handleFormSubmit = (values, { resetForm }) => {
        onSubmit(values);
        toast.success(isEditMode ? 'Banner updated successfully!' : 'Banner added successfully!');
        resetForm();
    };

    return (
        <div>
            <ToastContainer />
            <Formik
                initialValues={initialValues || defaultValues}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
            >
                {({ setFieldValue, values, touched, errors }) => (
                    <Form>
                        <Row className="mb-3">
                            <Col md={6}>
                                <label>Upload Banner</label>
                                <input
                                    type="file"
                                    name="bannerImage"
                                    onChange={(e) => setFieldValue('bannerImage', e.target.files[0])}
                                    className={`form-control ${touched.bannerImage && errors.bannerImage ? 'is-invalid' : ''}`}
                                />
                                {touched.bannerImage && errors.bannerImage && (
                                    <div className="invalid-feedback">{errors.bannerImage}</div>
                                )}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <label>Banner Title (En)</label>
                                <Field
                                    type="text"
                                    name="titleEn"
                                    className={`form-control ${touched.titleEn && errors.titleEn ? 'is-invalid' : ''}`}
                                />
                                <ErrorMessage name="titleEn" component="div" className="invalid-feedback" />
                            </Col>
                            <Col md={6}>
                                <label>Banner Title (Ar)</label>
                                <Field
                                    type="text"
                                    name="titleAr"
                                    className={`form-control ${touched.titleAr && errors.titleAr ? 'is-invalid' : ''}`}
                                />
                                <ErrorMessage name="titleAr" component="div" className="invalid-feedback" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <label>Banner Subtitle (En)</label>
                                <Field
                                    type="text"
                                    name="subtitleEn"
                                    className={`form-control ${touched.subtitleEn && errors.subtitleEn ? 'is-invalid' : ''}`}
                                />
                                <ErrorMessage name="subtitleEn" component="div" className="invalid-feedback" />
                            </Col>
                            <Col md={6}>
                                <label>Banner Subtitle (Ar)</label>
                                <Field
                                    type="text"
                                    name="subtitleAr"
                                    className={`form-control ${touched.subtitleAr && errors.subtitleAr ? 'is-invalid' : ''}`}
                                />
                                <ErrorMessage name="subtitleAr" component="div" className="invalid-feedback" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <BootstrapForm.Group>
                                    <BootstrapForm.Label>Status</BootstrapForm.Label>
                                    <BootstrapForm.Check
                                        type="switch"
                                        id="banner-status-switch"
                                        label={values.status ? 'Active' : 'Inactive'}
                                        checked={values.status}
                                        onChange={(e) => setFieldValue('status', e.target.checked)}
                                    />
                                </BootstrapForm.Group>
                            </Col>
                        </Row>
                        <div className="text-end">
                            <Button type="submit" variant="primary">
                                {isEditMode ? 'Update Banner' : 'Add Banner'}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default BannerForm;

