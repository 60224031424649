import React from 'react';
import { Container, Button } from 'react-bootstrap'; // Assuming you're using React Bootstrap
import LocationManagementTable from '../../../components/tables/LocationManagementTable/LocationManagementTable';
import { useNavigate } from 'react-router-dom';

const LocationManagement = () => {
    const navigate = useNavigate();

    // Function to handle adding a new location
    const handleAddLocation = () => {
        navigate('/cms/location-management/add-new');
    };

    // Function to handle editing a location (could be passed to table as a prop if needed)
    const handleEditLocation = (id) => {
        navigate(`/cms/location-management/edit-location/${id}`);
    };

    return (
        <Container fluid>
            <div className="location-management-page">
                <div className="d-flex justify-content-between align-items-center mt-5 mb-5">
                    <h3>Location Management</h3>
                    <Button onClick={handleAddLocation} variant="primary">
                        Add New Location
                    </Button>
                </div>
                
                {/* Ensure the table is correctly integrated */}
                <LocationManagementTable onEditLocation={handleEditLocation} />
            </div>
        </Container>
    );
};

export default LocationManagement;

